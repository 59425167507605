import React from "react";

import { Modal } from "react-bootstrap";

import {
  Header,
  Title,
  Body,
  ModalContent,
  Footer,
  Buttons,
  CancelButton,
  ConfirmButton,
} from "./styles";

function DefaultModal({
  show,
  hide,
  title,
  children,
  cancelText,
  confirmText,
  onConfirm,
  size,
  hideConfirmButton,
  hideCancelButton,
}) {
  return (
    <Modal size={!size ? "" : size} show={show} onHide={() => hide()} centered>
      <Header closeButton>
        <Title>{title}</Title>
      </Header>
      <Body>
        <ModalContent>{children}</ModalContent>
      </Body>
      {cancelText || confirmText ? (
        <Footer>
          <Buttons>
            {hideCancelButton ? (
              <></>
            ) : (
              <CancelButton onClick={() => hide()}>{cancelText}</CancelButton>
            )}
            {hideConfirmButton ? (
              <> </>
            ) : (
              <ConfirmButton onClick={() => onConfirm()}>
                {confirmText}
              </ConfirmButton>
            )}
          </Buttons>
        </Footer>
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default DefaultModal;
