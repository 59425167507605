import styled from "styled-components";

export const Container = styled.div``;

export const SelectInput = styled.select`
  height: 50px;
  background-color: transparent;
  color: #555864 !important;
  font-size: 14px;
  border: 1px solid #cacddf;
  width: 100%;
  text-transform: uppercase;
  padding-left: 15px;
  border-radius: 5px;

  &:hover,
  &:active {
    border: 1px solid #444651 !important;
  }
  &::-webkit-input-placeholder {
    color: #979bb2;
    font-size: 14px;
  }
`;

export const SelectLabel = styled.label`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555864;
  font-weight: bold;
`;

export const SelectLabelLista = styled.label`
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #555864;
  margin: 2px;
  margin-top: -10px;
`;

export const SelectInputLista = styled.select`
  background-color: #ffffff !important;
  color: var(--corTitulo1) !important;
  font-size: 16px !important;
  border: none;

  &:hover,
  &:active {
    border: 1px solid #cacddf !important;
  }
  &::-webkit-input-placeholder {
    color: #979bb2;
    font-size: 14px;
  }
  @media (max-width: 599px) {
    width: 96% !important;
  }

  @media (min-width: 600px) {
    margin-left: 0% !important;
    margin-right: 0% !important;
    width: 100% !important;
  }
`;
