import styled from "styled-components";

export const Input = styled.input`
  border: none;
  width: 100%;
  height: 50px;
  background-color: #f2f4f7;
  border-radius: 10px;
  padding-left: 15px;
  font-size: 16px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #555864;
  }
  :-ms-input-placeholder {
    color: #555864;
  }
`;
