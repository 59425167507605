import styled from "styled-components";
import { Modal } from "react-bootstrap";

import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

export const Header = styled(Modal.Header)`
  border-bottom: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    color: #3E70E8;
    font-size: 30px;
  }
`;

export const Title = styled(Modal.Title)`
  font-size: 30px;
  color: #5c6377;
  font-weight: bold;
`;

export const Body = styled(Modal.Body)``;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Footer = styled(Modal.Footer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: none;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  height: 40px;
  margin-bottom: 20px;
`;

export const ConfirmButton = styled(PrimaryButton)`
  height: 100%;
  width: 40%;
  margin-right: 7px;
`;

export const CancelButton = styled(SecondaryButton)`
  height: 100%;
  width: 40%;
  margin-left: 7px;
`;
