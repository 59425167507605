import styled from "styled-components";
import { Form } from "@unform/web";

import BackgroundImage from "../../assets/background_login_desktop.jpg";
import InputLogin from "../../components/InputLogin";
import PrimaryButton from "../../components/PrimaryButton";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  @media (max-width: 800px) {
    background-image: url(${BackgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
  }
`;

export const Background = styled.div`
  width: 65%;
  background-image: url(${BackgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const LoginDiv = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const Logo = styled.img`
  margin-top: 25px;
  width: 35%;
  margin-bottom: 30px;
`;

export const Input = styled(InputLogin)`
  margin-top: 10px;
`;

export const ButtonDiv = styled.div`
  width: 50%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
`;

export const Button = styled(PrimaryButton)``;

export const LinkForgotPass = styled.a`
  margin-top: 30px;
  font-size: 14px;
  color: #555864;
  margin-bottom: 25px;
`;
