import React from 'react';
import {LoadIcon} from './styles'

function LoadingButtons({ children, type, loading }) {
    return(<>
        {loading ?
            <LoadIcon type={!type?'ThreeDots':type} color='#3e70e8' height={80} width={80} /> 
        : (<>
            {children}
        </>)}
    </>) ;
}

export default LoadingButtons;