import React from 'react';
import { MdInfo } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default ({sigla, nomeAluno}) => {
    const listaStatus = [
        {sigla: "P,", legenda: "Aprovado"},
        {sigla: "Pr", legenda: "Promovido"},
        {sigla: "R ", legenda: "Retido"},
        {sigla: "Rf", legenda: "Retido Por Falta"},
        {sigla: "Rp", legenda: "Reprovado"},
        {sigla: "Rc", legenda: "Recuperação"},
        {sigla: "Dp", legenda: "Dependência"},
        {sigla: "RCl", legenda: "Reclassificado"},
        {sigla: "Cl", legenda: "Classificado"},
        {sigla: "De", legenda: "Desistente"},
        {sigla: "NC", legenda: "Não Compareceu"},
        {sigla: "Ob", legenda: "Obito"},
        {sigla: "Ex", legenda: "Excluído"},
        {sigla: "Mt", legenda: "Matriculado"},
        {sigla: "Tr", legenda: "Transferido"},
        {sigla: "Ev", legenda: "Evadido"},
        {sigla: "RmS", legenda: "Remanejado Saída"},
        {sigla: "RmE", legenda: "Remanejado Entrada"},
        {sigla: "Cc", legenda: "Cancelado"},
        {sigla: "RMt", legenda: "Rematriculado"},
        {sigla: "PMt", legenda: "Pré Matriculado"},
        {sigla: "Atr", legenda: "Aguardando Transferência"},
        {sigla: "Ni", legenda: "Não Informado"},
        {sigla: "Sup", legenda: "Suplementar"},
        {sigla: "Aut", legenda: "Aguardando Autorização"},
        {sigla: "AGV", legenda: "Aguardando Vaga"},
        {sigla: "NEE", legenda: "Necessidades Educativas Especiais"}
    ];
    
    const status = sigla || "";

    const siglaSelected = listaStatus.filter((item)=> item.sigla.toUpperCase() === status.toUpperCase())
    return (<>
        {siglaSelected.length > 0 ? (<>
            <OverlayTrigger
                key={nomeAluno}
                placement="top"
                overlay={
                <Tooltip id={`tooltip-${nomeAluno}`}>
                    {siglaSelected[0].legenda}
                </Tooltip>
                }
            >
                <MdInfo
                    style={{ cursor: "pointer" }}
                    color="##CACDDF"
                    size={22}
                />
            </OverlayTrigger>
        </>) :(<></>)}
    </>);
}
