import React, { createContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import api from "../services/api";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(() => {
    const CEmail = sessionStorage.getItem("dc:email");
    if (CEmail) {
      return CEmail;
    }
    return "";
  });
  const [name, setName] = useState(() => {
    const CName = sessionStorage.getItem("dc:name");
    if (CName) {
      return CName;
    }
    return "";
  });
  const [username, setUsername] = useState(() => {
    const CUsername = sessionStorage.getItem("dc:username");
    if (CUsername) {
      return CUsername;
    }
    return "";
  });
  const [id, setId] = useState(() => {
    const CId = sessionStorage.getItem("dc:id");
    if (CId) {
      return CId;
    }
    return "";
  });
  const [profile, setProfile] = useState(() => {
    const CProfile = JSON.parse(sessionStorage.getItem("dc:profile"));
    if (CProfile) {
      return CProfile;
    }
    return "";
  });
  const [institution, setInstitution] = useState(() => {
    const CInstituition = sessionStorage.getItem("dc:institution");
    if (CInstituition) {
      return CInstituition;
    }
    return "";
  });
  const [institutions, setInstitutions] = useState(() => {
    const CInstituitions = sessionStorage.getItem("dc:institutions");
    if (CInstituitions) {
      return CInstituitions;
    }
    return [];
  });
  const [institutionName, setInstitutionName] = useState(() => {
    const CInstituitionName = sessionStorage.getItem("dc:institutionName");
    if (CInstituitionName) {
      return CInstituitionName;
    }
    return "";
  });

  const memoizedValidEmail = useCallback((emailToValidate) => {
    const reg = (
      /\S+@\S+\.\S+/
    );
    return reg.test(emailToValidate);
  }, []);

  const memoizedProfileInstitutionLogin = useCallback(
    (idUse, profiles) => {
      const institutionsToUse = [];
      if (profiles.filter(Boolean).length === 1) {
        const profilesToUse = profiles.filter(Boolean)[0];
        setProfile(JSON.stringify(profilesToUse));
        sessionStorage.setItem("dc:profile", JSON.stringify(profilesToUse));
        api
          .get(`/instituicao/diario/${idUse}/${profilesToUse.id}`)
          .then((res) => {
            res.data.map((item) => {
              const idItem = item.id;
              const nameItem = item.nome;
              return (institutionsToUse[idItem] = nameItem);
            });
            if (institutionsToUse.filter(Boolean).length === 1) {
              setInstitution(Object.keys(institutionsToUse)[0]);
              setInstitutionName(institutionsToUse[Object.keys(institutionsToUse)[0]]);
              setInstitutions(institutionsToUse);
              sessionStorage.setItem(
                "dc:institutions",
                JSON.stringify(institutionsToUse)
              );
              sessionStorage.setItem(
                "dc:institution",
                Object.keys(institutionsToUse)[0]
              );
              sessionStorage.setItem(
                "dc:institutionName",
                institutionsToUse[Object.keys(institutionsToUse)[0]]
              );
              history.push("/home");
            } else {
              Swal.fire({
                title: "Agora uma escola para acessar",
                input: "select",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonText: "Entrar",
                reverseButtons: true,
                customClass: {
                  title: "title",
                  content: "text",
                  input: "input",
                },
                inputOptions: institutionsToUse,
                preConfirm: (selectedInstitution) => {
                  setInstitution(selectedInstitution);
                  setInstitutionName(institutionsToUse[selectedInstitution]);
                  setInstitutions(institutionsToUse);
                  sessionStorage.setItem(
                    "dc:institutions",
                    JSON.stringify(institutionsToUse)
                  );
                  sessionStorage.setItem("dc:institution", selectedInstitution);
                  sessionStorage.setItem(
                    "dc:institutionName",
                    institutionsToUse[selectedInstitution]
                  );
                  history.push("/home");
                },
              });
            }
          })
          .catch(() => {
            return Swal.fire({
              title: "Erro ao buscar escolas",
              icon: "error",
            });
          });
      } else {
        const profileClean = profiles.filter(Boolean);
        const localProfiles = [];
        profileClean.map((item) => {
          return (localProfiles[item.id] = item.profileName)
        })

        Swal.fire({
          title: "Por favor, selecione um perfil para continuar",
          input: "select",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Continuar",
          reverseButtons: true,
          customClass: {
            title: "title",
            content: "text",
            input: "input",
          },
          inputOptions: localProfiles,
          // eslint-disable-next-line
          inputValidator: function (selectedProfile) {
            const objProfileSelected = profileClean.filter(item => item.id.toString() === selectedProfile.toString());
            setProfile(objProfileSelected[0]);
            sessionStorage.setItem("dc:profile", JSON.stringify(objProfileSelected[0]));
            // eslint-disable-next-line
            return new Promise(function (resolve) {
              resolve(
                api
                  .get(`/instituicao/diario/${idUse}/${selectedProfile}`)
                  .then((res) => {
                    res.data.map((item) => {
                      const idItem = item.id;
                      const nameItem = item.nome;
                      return (institutionsToUse[idItem] = nameItem);
                    });
                    if (institutionsToUse.filter(Boolean).length === 1) {
                      setInstitution(Object.keys(institutionsToUse)[0]);
                      setInstitutionName(
                        institutionsToUse[Object.keys(institutionsToUse)[0]]
                      );
                      setInstitutions(institutionsToUse);
                      sessionStorage.setItem(
                        "dc:institutions",
                        JSON.stringify(institutionsToUse)
                      );
                      sessionStorage.setItem(
                        "dc:institution",
                        Object.keys(institutionsToUse)[0]
                      );
                      sessionStorage.setItem(
                        "dc:institutionName",
                        institutionsToUse[Object.keys(institutionsToUse)[0]]
                      );
                      history.push("/home");
                    } else {
                      Swal.fire({
                        title: "Agora uma escola para acessar",
                        input: "select",
                        showCancelButton: true,
                        cancelButtonText: "Cancelar",
                        confirmButtonText: "Entrar",
                        reverseButtons: true,
                        customClass: {
                          title: "title",
                          content: "text",
                          input: "input",
                        },
                        inputOptions: institutionsToUse,
                        preConfirm: (selectedInstitution) => {
                          setInstitution(selectedInstitution);
                          setInstitutionName(institutionsToUse[selectedInstitution]);
                          setInstitutions(institutionsToUse);
                          sessionStorage.setItem(
                            "dc:institutions",
                            JSON.stringify(institutionsToUse)
                          );
                          sessionStorage.setItem(
                            "dc:institution",
                            selectedInstitution
                          );
                          sessionStorage.setItem(
                            "dc:institutionName",
                            institutionsToUse[selectedInstitution]
                          );
                          history.push("/home");
                        },
                      });
                    }
                  })
                  .catch(() => {
                    return Swal.fire({
                      title: "Erro ao buscar escolas",
                      icon: "error",
                    });
                  })
              );
            });
          },
        });
      }
    },
    [history]
  );

  const signIn = useCallback(
    ({ login, password }) => {
      let profiles = [];
      setLoading(true);
      api
        .post("/login/diario/logar", {
          login,
          senha: password,
        })
        .then(async (res) => {
          const userData = res.data;
          const localProfiles = [];

          res.data.perfis.map((item) => {
            return (localProfiles[item.id] = {
              id: item.id,
              profileName: item.nome,
              escrita: item.escrita,
              sigla: item.sigla
            });
          });
          profiles = localProfiles;

          setId(userData.idUsuario);
          sessionStorage.setItem("dc:id", userData.idUsuario);
          setName(userData.nomeCompleto);
          sessionStorage.setItem("dc:name", userData.nomeCompleto);
          setUsername(login);
          sessionStorage.setItem("dc:username", login);

          if (!userData.email) {
            setLoading(false);
            const result1 = await Swal.fire({
              title: "Confirme seu e-mail institucional",
              showCancelButton: true,
              cancelButtonText: "Mais tarde",
              showConfirmButton: true,
              confirmButtonText: "Confirmar",
              reverseButtons: true,
              html: '<input id="swal-input1" type="email" class="input" style="margin-top: 5px" placeholder="INSIRA SEU E-MAIL">' +
                '<input id="swal-input2" type="email" class="input" style="margin-bottom: 5px" placeholder="REPITA SEU E-MAIL">',
              preConfirm: () => {
                const emailInput = document.getElementById("swal-input1").value;
                const emailConfirm = document.getElementById("swal-input2").value;

                if (memoizedValidEmail(emailInput) &&
                  memoizedValidEmail(emailConfirm)) {
                  if (emailInput.trim() === emailConfirm.trim()) {
                    api
                      .put(
                        `/usuario/diario/atualizarEmail/${userData.idUsuario}/${emailInput}`
                      )
                      .then(() => {
                        setEmail(emailInput);
                        sessionStorage.setItem("dc:email", emailInput);
                        Swal.fire({
                          text: "E-mail atualizado com sucesso",
                          icon: "success",
                        }).then(() => {
                          memoizedProfileInstitutionLogin(
                            userData.idUsuario,
                            profiles
                          );
                        });
                      })
                      .catch((err) => {
                        Swal.fire({
                          text: err.response.data,
                          icon: "error",
                        });
                      });
                  } else {
                    Swal.showValidationMessage("O e-mails devem ser iguais");
                  }
                } else {
                  Swal.showValidationMessage("Digite o e-mail corretamente");
                }
              },
            });
            if (result1.isDismissed) {
              memoizedProfileInstitutionLogin(userData.idUsuario, profiles);
            }
          } else {
            setEmail(userData.email);
            sessionStorage.setItem("dc:email", userData.email);
            memoizedProfileInstitutionLogin(userData.idUsuario, profiles);
          }
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    },
    [memoizedValidEmail, memoizedProfileInstitutionLogin]
  );

  const changePassword = useCallback(() => {
    Swal.fire({
      title: "Redefina sua senha",
      html:
        '<input id="swal-input1" type="password" class="input" placeholder="Insira a senha atual">' +
        '<input id="swal-input2" type="password" class="input" style="margin-bottom: 5px" placeholder="Insira a nova senha">' +
        '<input id="swal-input3" type="password" class="input" style="margin-bottom: 5px" placeholder="Repita a nova senha">',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Concluir",
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const oldPwd = document.getElementById("swal-input1").value;
        const pwd = document.getElementById("swal-input2").value;
        const pwd2 = document.getElementById("swal-input3").value;
        if (pwd.length > 4 || pwd2.length > 4) {
          if (pwd === pwd2) {
            const data = {
              token: oldPwd,
              loginUsuario: username,
              senhaNova: pwd,
            };

            try {
              const res = await api
                .post(`/usuario/diario/trocar-senha`, data);
              Swal.fire({
                text: res.data,
                icon: "success",
              });
            } catch (err) {
              Swal.fire({
                text: err.response.data,
                icon: "error",
              });
            }
          } else {
            Swal.showValidationMessage("Senhas devem ser iguais");
          }
        } else {
          Swal.showValidationMessage("A senha deve ter no mínimo 5 caracteres");
        }
      },
    });
  }, [username]);

  const changeEmail = useCallback(() => {
    Swal.fire({
      title: "Redefina seu email",
      html:
        '<input id="swal-input1" type="email" class="input" placeholder="Insira o e-mail atual">' +
        '<input id="swal-input2" type="email" class="input" style="margin-bottom: 5px" placeholder="Insira o novo e-mail">' +
        '<input id="swal-input3" type="email" class="input" style="margin-bottom: 5px" placeholder="Repita o novo e-mail">',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      showConfirmButton: true,
      confirmButtonText: "Concluir",
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const oldEmail = document.getElementById("swal-input1").value;
        const newEmail = document.getElementById("swal-input2").value;
        const newEmail2 = document.getElementById("swal-input3").value;
        if (oldEmail === email) {
          if (memoizedValidEmail(newEmail) && memoizedValidEmail(newEmail2)) {
            if (newEmail === newEmail2) {
              try {
                await api
                  .put(`/usuario/diario/atualizarEmail/${id}/${newEmail}`);
                setEmail(newEmail);
                sessionStorage.setItem("dc:email", newEmail);
                Swal.fire({
                  text: "E-mail atualizado com sucesso",
                  icon: "success",
                });
              } catch (err) {
                Swal.fire({
                  text: err.response.data,
                  icon: "error",
                });
              }
            } else {
              Swal.showValidationMessage("Os e-mails devem ser iguais");
            }
          } else {
            Swal.showValidationMessage("Digite o e-mail corretamente");
          }
        } else {
          Swal.showValidationMessage("E-mail atual invalido");
        }
      },
    });
  }, [email, id, memoizedValidEmail]);

  const signOut = useCallback(() => {
    sessionStorage.removeItem("dc:email");
    sessionStorage.removeItem("dc:name");
    sessionStorage.removeItem("dc:username");
    sessionStorage.removeItem("dc:id");
    sessionStorage.removeItem("dc:profile");
    sessionStorage.removeItem("dc:institution");
    sessionStorage.removeItem("dc:institutions");
    sessionStorage.removeItem("dc:institutionName");

    setEmail("");
    setName("");
    setUsername("");
    setId("");
    setProfile("");
    setInstitution("");
    setInstitutions("");
    setInstitutionName("");

    history.push("/");
  }, [history]);

  const changeLoading = (state) =>{
    setLoading(state);
  }

  return (
    <AuthContext.Provider
      value={{
        loggedUser: {
          email,
          name,
          username,
          id,
          profile,
          institutions,
          institution,
          institutionName,
        },
        signIn,
        signOut,
        changePassword,
        changeEmail,
        setInstitution,
        setInstitutionName,
        loading,
        changeLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
