import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #3e70e8;
  background-color: #fff;
  color: #3e70e8;
  font-weight: bold;

  &:hover {
    background-color: #3e70e8;
    color: #fff;
  }
`;
