import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #3e70e8;
  color: #fff;
  font-weight: bold;

  &:hover {
    opacity: 75%;
  }
`;
