import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./global.css";

ReactDOM.render(
  /* <React.StrictMode> */
    <App />
  /* </React.StrictMode> */
  ,document.getElementById("root")
);
