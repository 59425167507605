import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Form, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { Menu, Brand, ImgBrand, Item, Dropdown } from "./styles";

import { AuthContext } from "../../contexts/AuthContext";

import LogoImg from "../../assets/logo_p.png";

function NavBar() {
  const history = useHistory();
  const {
    loggedUser,
    changePassword,
    changeEmail,
    signOut,
    setInstitution,
    setInstitutionName,
  } = useContext(AuthContext);
  const username = loggedUser.name;
  const institutions = JSON.parse(sessionStorage.getItem("dc:institutions"));
  return (
    <Menu collapseOnSelect expand="lg" bg="dark" variant="dark">
      <div style={{ cursor: 'pointer' }}>
        <Brand onClick={() => history.push("/home")}>
          <ImgBrand src={LogoImg} alt="logo" />
        </Brand>
      </div>
      <Menu.Toggle aria-controls="responsive-navbar-nav" />
      <Menu.Collapse id="responsive-navbar-nav">
        <Item className="mr-auto">
          <Item.Link onClick={() => history.push("/home")}>Home</Item.Link>
          <Dropdown title="Meus alunos" id="collasible-nav-dropdown">
            <Dropdown.Item onClick={() => history.push("/ocorrencias")}>
              Ocorrências
            </Dropdown.Item>
          </Dropdown>
          <Dropdown title="Minhas aulas" id="collasible-nav-dropdown">
            <Dropdown.Item onClick={() => history.push("/listaPresenca")}>
              Lista de presença
            </Dropdown.Item>
            <Dropdown.Item onClick={() => history.push("/avaliacaoFundamental")}>
              Avaliação fundamental
            </Dropdown.Item>
            <Dropdown.Item onClick={() => history.push("/avaliacaoInfantil")}>
              Avaliação infantil
            </Dropdown.Item>
          </Dropdown>
          <Item.Link onClick={() => history.push("/relatorios")}>Relatórios</Item.Link>
        </Item>
        <Item className="ml-auto">
          {institutions ? (
            <Form className="instituitionSelectContainer">
              <FormControl
                as="select"
                onChange={(e) => {
                  setInstitution(e.target.value);
                  setInstitutionName(institutions[e.target.value]);
                  sessionStorage.setItem("dc:institution", e.target.value);
                  sessionStorage.setItem(
                    "dc:institutionName",
                    institutions[e.target.value]
                  );
                  toast.info("Escola alterada com sucesso!");
                }}
                className="institutionSelect"
              >
                {institutions.map((item, index) =>
                  item !== null ? (
                    <option
                      key={index.toString()}
                      defaultValue={
                        index === parseInt(loggedUser.institution,10)
                      }
                      value={index}
                    >
                      {item}
                    </option>
                  ) : ('')
                )}
              </FormControl>
            </Form>
          ) : (
            <></>
          )}

          <Dropdown title={username} id="collasible-nav-dropdown">
            <Dropdown.Item onClick={() => changePassword()}>
              Redefinir senha
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeEmail()}>
              Alterar e-mail
            </Dropdown.Item>
            <Dropdown.Item onClick={() => signOut()}>Sair</Dropdown.Item>
          </Dropdown>
        </Item>
      </Menu.Collapse>
    </Menu>
  );
}

export default NavBar;
