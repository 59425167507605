import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

LoadingOverlay.propTypes = undefined

export function LoadingScreen({ children, activeLoad }) {
    
    return (<>
        <LoadingOverlay
            active={activeLoad}
            spinner
            text="Carregando dados"
            styles={{
                overlay:{
                    overflow: 'hidden',
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    top: '0px',
                    left: '0px',
                    display: 'flex',
                    textAlign: 'center',
                    fontSize: '1.2em',
                    color: '#FFF',
                    background: 'rgba(0,0,0,0.7)',
                    zIndex: '800',
                    transition: 'opacity 500ms ease-in',
                    opacity: '1'
                },
                wrapper: { 
                    position:'relative', 
                    width:'100%'
                },
                content: { 
                    height: '100%',
                    width:'100%', 
                    marginTop:'50px'
                }
            }}
        >
            {children}
        </LoadingOverlay>
    </>);
}