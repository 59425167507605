import React, { useRef, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";

import api from "../../services/api";
import "../../global.css";

import { AuthContext } from "../../contexts/AuthContext";

import {
  Container,
  LoginDiv,
  Background,
  LoginForm,
  Logo,
  Input,
  ButtonDiv,
  Button,
  LinkForgotPass,
} from "./styles";

import LogoImg from "../../assets/logo_g.png";
import LoadingButtons from "../../components/Loading/LoadingButtons";

function Login({ match }) {
  const { signIn, loading, changeLoading } = useContext(AuthContext);
  const formRef = useRef(null);

  useEffect(() => {
    const { usr, token } = match.params;
    if (usr && token) {
      Swal.fire({
        title: "Redefina sua senha",
        html:
          '<input id="swal-input1" type="password" class="input" placeholder="Insira a nova senha">' +
          '<input id="swal-input2" type="password" class="input" style="margin-bottom: 5px" placeholder="Repita a nova senha">',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        showConfirmButton: true,
        confirmButtonText: "Concluir",
        reverseButtons: true,
        showLoaderOnConfirm: true,

        preConfirm: () => {
          const pwd = document.getElementById("swal-input1").value;
          const pwd2 = document.getElementById("swal-input2").value;
          if (pwd === pwd2) {
            if (pwd.length > 0) {
              const data = {
                token,
                loginUsuario: usr,
                senhaNova: pwd,
              };

              return api
                .post(`/usuario/diario/trocar-senha`, data)
                .then((res) => {
                  Swal.fire({
                    text: res.data,
                    icon: "success",
                  });
                })
                .catch((err) => {
                  Swal.fire({
                    text: err.response.data,
                    icon: "error",
                  });
                });
            } return Swal.showValidationMessage("Digite uma senha");
          } return Swal.showValidationMessage("Senhas devem ser iguais");
        },
      });
    }
  }, [match.params]);

  function forgotPassword() {
    Swal.fire({
      customClass: {
        title: "title",
        content: "text",
        input: "input",
      },
      title: "Esqueceu sua senha?",
      text:
        "Não se preocupe, te ajudamos a redefini-la te enviando um e-mail. É bem simples.",
      input: "text",
      inputPlaceholder: "INSIRA SEU USUÁRIO",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Enviar",
      reverseButtons: true,
      preConfirm: (value) => {
        api
          .get(`/usuario/diario/recuperacao-senha/${value}`)
          .then((res) => {
            Swal.fire({
              text: res.data,
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              text: err.response.data,
              icon: "error",
            });
          });
      },
    });
  }

  async function handleLogin(data) {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        login: Yup.string().required("O Login é obrigatório"),
        password: Yup.string().required("A senha é obrigatória"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      changeLoading(true);
      signIn(data);
    } catch (err) {
      const validationErrors = {};
      changeLoading(false);
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }
  
  useEffect(()=>{
    changeLoading(false);
  },[]);

  return (
    <Container>
      <Background />
      <LoginDiv>
        <LoginForm ref={formRef} onSubmit={handleLogin}>
          <Logo src={LogoImg} />
          <Input name="login" placeholder="Usuário" />
          <Input name="password" placeholder="Senha" type="password" />
          <ButtonDiv>
            {loading ? 
              <LoadingButtons loading={loading}/>
            : 
              <Button type="submit"> Entrar </Button>
            }
          </ButtonDiv>
          <LinkForgotPass onClick={() => forgotPassword()}>Esqueci a senha</LinkForgotPass>
        </LoginForm>
      </LoginDiv>
    </Container>
  );
}

export default Login;
