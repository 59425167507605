import React from 'react';

import {
    Document,
    Page,
    View,
    Text,
    Image,
    Font
} from "@react-pdf/renderer";

import ReportHeader from "../../components/ReportHeader";
import Icon from "../../assets/icon_acessibilidade.png";

Font.register({
    family: "Montserrat",
    src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
});

Font.register({
    family: "MontserratBold",
    src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
});
function PdfNotas({
    widthCol,
    selectedSubject,
    subjectOptions,
    details,
    classesOptions,
    selectedClass,
    dataTable,
    tableHeader,
    loggedUser,
    institution
}) {
    const institutions = JSON.parse(sessionStorage.getItem("dc:institutions"));
    return (
    <Document>
        <Page
        style={{
            padding: 35,
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
        }}
        wrap
        size="A4"
        orientation="landscape"
        >
        <ReportHeader name={loggedUser.name} title="Notas e faltas" />
        <View
            style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 15,
            }}
        >
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
            ESCOLA:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
                {institutions[parseInt(institution, 10)]}
            </Text>
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
            BIMESTRE:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
                {subjectOptions.find((item) => item.value === selectedSubject) &&
                subjectOptions.find((item) => item.value === selectedSubject)
                    .label}
            </Text>
            </Text>
        </View>
        <View
            style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 5,
            }}
        >
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
            AULAS DADAS NO ANO:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
                {details.length > 0 && details[0].qtdAulasDadasNoAno}
            </Text>
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
            AULAS DADAS NO BIMESTRE:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
                {details.length > 0 && details[0].qtdAulasDadasNoBimestre}
            </Text>
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
            TURMA:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
                {classesOptions.find((item) => item.value === selectedClass) &&
                classesOptions.find((item) => item.value === selectedClass)
                    .label}
            </Text>
            </Text>
        </View>
        <View
            style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 20,
            borderTopColor: "#CACDDF",
            borderTopWidth: 1,
            borderBottomColor: "#CACDDF",
            borderBottomWidth: 1,
            fontFamily: "MontserratBold",
            }}
        >
            <View
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
            }}
            >
            <View style={{ width: "40%" }}>
                <Text
                style={{
                    color: "#5C6377",
                    fontSize: 10,
                    textAlign: "left",
                }}
                >
                ALUNO(A)
                </Text>
            </View>
            {tableHeader &&
                tableHeader.map((i,indx) => (
                <View
                    key={indx.toString()}
                    style={{
                    width: widthCol,
                    textAlign: "center",
                    }}
                >
                    <Text
                    style={{
                        color: "#5C6377",
                        fontSize: 10,
                    }}
                    >
                    {i.abreviacao}
                    </Text>
                </View>
                ))}

            <View style={{ width: widthCol, textAlign: "center" }}>
                <Text
                style={{
                    color: "#5C6377",
                    fontSize: 10,
                }}
                >
                FALTAS
                </Text>
            </View>
            <View style={{ width: widthCol, textAlign: "center" }}>
                <Text
                style={{
                    color: "#5C6377",
                    fontSize: 10,
                }}
                >
                AC
                </Text>
            </View>
            </View>
        </View>
        {dataTable.map((item, index) => (
            <View
            key={index.toString()}
            wrap={false}
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: index % 2 === 0 ? "#F2F4F7" : "",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomColor: "#CACDDF",
                borderBottomWidth: 1,
            }}
            >
            <View
                style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
                }}
            >
                <View style={{ width: "40%" }}>
                <Text
                    style={{
                    color: "#5C6377",
                    fontSize: 10,
                    textAlign: "left",
                    fontFamily: "MontserratBold",
                    }}
                >
                    {item.possuiNee ? (
                    <Image
                        src={Icon}
                        style={{ height: 11, width: 11, marginRight: 2 }}
                    />
                    ) : (
                    <></>
                    )}
                    {item.nome}
                </Text>
                </View>
                {tableHeader.map((i,idx) => (
                <View
                    key={idx.toString()}
                    style={{
                    width: widthCol,
                    textAlign: "center",
                    }}
                >
                    <Text
                    style={{
                        color: "#5C6377",
                        fontSize: 10,
                    }}
                    >
                    {item[i.abreviacao]}
                    </Text>
                </View>
                ))}
                <View style={{ width: widthCol, textAlign: "center" }}>
                <Text
                    style={{
                    color: "#5C6377",
                    fontSize: 10,
                    }}
                >
                    {item.faltas}
                </Text>
                </View>
                <View style={{ width: widthCol, textAlign: "center" }}>
                <Text
                    style={{
                    color: "#5C6377",
                    fontSize: 10,
                    }}
                >
                    {item.ausencias}
                </Text>
                </View>
            </View>
            </View>
        ))}
        </Page>
    </Document>
  );
}

export default PdfNotas;