import React, { useState, useEffect, useContext } from "react";
import {
  MdDescription,
  MdWarning,
  MdArrowUpward,
  MdAccessible,
} from "react-icons/md";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";

import Title from "../../components/Title";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import Modal from "../../components/Modal";

import DefaultImage from "../../assets/aluno_default.jpg";
import api from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import "jspdf-autotable";


import {
  Container,
  Content,
  TitleContainer,
  FilterContainer,
  FilterDiv,
  Subtitle,
  FilterDataDiv,
  FilterData,
  PeriodAlert,
  TableLegend,
  DataTable,
  StudentImage,
  TableInput,
  ActionButtonsDiv,
  ActionUPDiv,
  FixedFooter,
  CounterDiv,
  ModalTextArea,
  ScrollBarArea,
  SubTitleAnnotationModal,
  NEETypesDiv,
} from "./styles";

import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import LoadingButtons from "../../components/Loading/LoadingButtons";
import LegendasStatus from "../../components/LegendasStatus";
import { LogContext } from "../../contexts/LogContext";
import PdfNotas from "../pdf/pdfNotas";

Font.register({
  family: "Montserrat",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
});

Font.register({
  family: "MontserratBold",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
});

function AvaliacaoFundamental() {
  const history = useHistory();
  const { loggedUser } = useContext(AuthContext);
  const { sendLog } = useContext(LogContext);
  const { institution, id } = loggedUser;
  const idUser = sessionStorage.getItem("dc:id");
  const profile = JSON.parse(sessionStorage.getItem("dc:profile"));
  const [activeLoad, setActiveLoad] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [profOptions, setProfOptions] = useState([]);
  const [selectedProf, setSelectedProf] = useState("");
  const [classesOptions, setClassesOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [detailsOptions, setDetailsOptions] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [tableHeader, setTableHeader] = useState([]);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [details, setDetails] = useState({});
  const [listaLancamentoAlunos, setListaLancamentoAlunos] = useState([]);
  const [listToSend, setListToSend] = useState([]);
  const [showNEE, setShowNEE] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [newNEE, setNewNEE] = useState("");
  
  const [counterAnnotation, setCounterAnnotation] = useState();
  const [textAreaHeight, setTextAreaHeight] = useState(150);
  const [modalTitle, setModalTitle] = useState("");
  const [NEEType, setNEETYype] = useState("");
  const [CNEE, setCNEE] = useState({});
  
  const [objAval, setObjAval] = useState({});

  const [selectedNEE, setSelectedNEE] = useState("");
  const [hasNEE, setHasNEE] = useState(false);
  const [numeric, setNumeric] = useState(true);
  const [strings, setStrings] = useState([]);
  const [notasSiglas, setNotasSiglas] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [ready, setReady] = useState(false);
  const [PDFNotas, setPDFNotas] = useState();
  const idUserProf = profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? selectedProf : id;
  const isEditable = profile.sigla !== "DIR" && profile.sigla !== "CRD" && profile.sigla !== "ADMIN" && profile.sigla !== "SADM" && profile.sigla !== "VDIR";
  const [isForaDataLimite, setIsForaDataLimite] = useState(false);
  
  function generatePDFNotas(widthCol, listaAlunos) {
    setPDFNotas(
      <PdfNotas 
        widthCol={widthCol}
        selectedSubject = {selectedSubject}
        subjectOptions = {subjectOptions}
        details = {details}
        classesOptions = {classesOptions}
        selectedClass = {selectedClass}
        dataTable = {listaAlunos}
        tableHeader = {tableHeader}
        loggedUser={loggedUser}
        institution={institution}
      />
    );
  }
  async function getAvaliacao(idMatricula){
    const response = await api.get(`/programas-especiais/diario/lista-desempenho/${idMatricula}/${selectedClass}/${selectedSubject}`)
    return response;
  }

  async function getAvaliacaoProfEspecialista(idMatricula){
    const response = await api.get(`/programas-especiais/diario/lista-desempenho-especialista/${idMatricula}/${selectedClass}/${selectedSubject}/${idUserProf}`)
    return response;
  }

  async function getProfessores(){
    const response = await api.get(`/lista-presenca/diario/filtro-professores/${id}/${institution}`)
    return response;
  }

  useEffect(()=>{
    sendLog("/avaliacaoFundamental");
  },[]);

  useEffect(async () => {
    setSubjectOptions([]);
    setClassesOptions([]);
    setSelectedSubject("");
    setList([]);
    setFilteredList([]);
    setListToSend([]);
    setReady(false);
    setProfOptions([]);
    setSelectedProf("");
    setActiveLoad(true);

    if(profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR"){
      try{
        const resProf = await getProfessores();
        resProf.data.map((item)=> {
          const json = {
            value: String(item.usrId),
            label: item.nome,
          };
          return setProfOptions((oldListProf) => [
            ...oldListProf, 
            json])
        })
        setActiveLoad(false);
      } catch(err){
        setActiveLoad(false);
        if (err.response) {
          toast.error(err.response.data);
        }
      }
    } else {
      api
        .get(`/lista-presenca/diario/filtro-turma/${id}/${institution}/2`)
        .then((res) => {
          res.data.map((item) => {
            const OBJ = {
              value: String(item.turmaId),
              label: item.descricao,
            };
            return setClassesOptions((oldClassesOptions) => [
              ...oldClassesOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    }
  }, [id, institution]);

  useEffect(() => {
    setSubjectOptions([]);
    setClassesOptions([]);
    setSelectedSubject("");
    setList([]);
    setFilteredList([]);
    setListToSend([]);
    setReady(false);
    setActiveLoad(true);

    if(selectedProf){

      api
        .get(`/lista-presenca/diario/filtro-turma/${idUserProf}/${institution}/2`)
        .then((res) => {
          res.data.map((item) => {
            const OBJ = {
              value: String(item.turmaId),
              label: item.descricao,
            };
            return setClassesOptions((oldClassesOptions) => [
              ...oldClassesOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    }


  },[selectedProf])

  useEffect(() => {
    setSubjectOptions([]);
    setList([]);
    setFilteredList([]);
    setListToSend([]);
    setSelectedSubject("");
    setReady(false);
    setActiveLoad(true);

    if(selectedClass){
      api.get(
          `/notas-faltas/diario/filtro-bimestre/${selectedClass}/${idUserProf}/${institution}`
        )
        .then((res) => {
          setDetailsOptions(res.data);
          res.data.map((item) => {
            const OBJ = {
              value: String(item.idCao),
              label: item.descricao,
            };
            return setSubjectOptions((oldSubjectOptions) => [
              ...oldSubjectOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch(() => {
          setActiveLoad(false);
        });
    }

  }, [id, institution, selectedClass]);

  useEffect(() => {
    if (details.length > 0) {
      setNumeric(details[0].notasNumericas);
    }
  }, [details]);

  useEffect(() => {
    setSubjectOptions([]);
    setList([]);
    setFilteredList([]);
    setListToSend([]);
    setDetails({});
    setReady(false);
    Array.from(document.getElementsByClassName("input-table")).forEach(
      (el) => (el.defaultValue = "0")
    );

    if(selectedClass){
      api.get(
          `/notas-faltas/diario/cabecalho-turmas/${selectedClass}/${institution}/${idUserProf}`
        )
        .then((res) => {
          setTableHeader(res.data);
        })
        .catch(() => {
        });
    }

    api
      .get("/notas-faltas/diario/lista-simbologias-legendas")
      .then((res) => {
    
        const siglas = [];
        res.data.forEach(d => {
          siglas.push(d.sigla)
        })
        setNotasSiglas(res.data);
        setStrings(siglas);
      })
      .catch(() => {
      });
  }, [id, institution, selectedClass]);

  useEffect(() => {
    setList([]);
    setListToSend([]);
    setReady(false);
    let filtredListChild = [...filteredList];
    filtredListChild = []
    setFilteredList([]);
    if (reloadList || filtredListChild.length === 0) {
      setActiveLoad(true);
      if(selectedClass && selectedSubject){
        api
          .get(
            `/notas-faltas/diario/busca-alunos/${selectedClass}/${selectedSubject}/${institution}/${idUserProf}`
          )
          .then((res) => {
            if(res.data[0].numeroChamada > 0){
              res.data.sort( (a, b ) => {return (a.numeroChamada > b.numeroChamada) ? 1 : -1});
            } else {
              res.data.sort( (a, b ) => {return (a.nomeAluno > b.nomeAluno) ? 1 : -1});
            }
            
            Array.from(document.getElementsByClassName("input-table")).forEach(
              (el) => (el.defaultValue = "0")
            );
            setList(res.data);
            setFilteredList(res.data);
            
            res.data.map((alunos)=>{
              setListToSend((theList) => [...theList, {
                matriculaAluno: alunos.matriculaAluno,
                idCao: parseInt(selectedSubject, 10),
                ausenciasCompensadas: alunos.faltasCompensadas,
                alunoPossuiDeficiencia: alunos.possuiNee,
                permiteEditar: alunos.permiteEditar,
                possuiObs: alunos.possuiObs,
                qtdFaltas: alunos.qtdFaltas,
                notas: alunos.notas,
              }])
            });

            const OBJComplete = [];
            res.data.map((item) => {
              OBJComplete.push({
                nome: item.nomeAluno,
                faltas: item.qtdFaltas,
                ausencias: item.faltasCompensadas,
                possuiNee: item.possuiNee,
              });
              tableHeader.map((i) => {
                const indexNome = OBJComplete.findIndex(
                  (obj) => obj.nome === item.nomeAluno
                );
                if (indexNome) {
                  OBJComplete[indexNome][i.abreviacao] = item.notas.find(
                    (itens) => itens.disciplinaId === i.disId
                  )
                    ? item.notas.find((itens) => itens.disciplinaId === i.disId).nota
                    : "";
                } else {
                  OBJComplete[0][i.abreviacao] = item.notas.find(
                    (itens) => itens.disciplinaId === i.disId
                  )
                    ? item.notas.find((itens) => itens.disciplinaId === i.disId).nota
                    : "";
                }
              });
            });

            if(details.length > 0){
              const dataAtual = new Date();
              const dataLancamento = new Date(details[0].dataLancamento.substring(6),details[0].dataLancamento.substring(3,5)-1,details[0].dataLancamento.substring(0,2),23,59,59);

              if(dataLancamento < dataAtual){
                setIsForaDataLimite(true);
              }
              else{
                setIsForaDataLimite(false);
              }

            }

            let wc = parseInt(60 / (tableHeader.length + 2), 10);
            wc = `${wc}%`;
            setActiveLoad(false);
            generatePDFNotas(wc,OBJComplete);
            setReady(true);
          })
          .catch(() => {
            setActiveLoad(false);
          });
        setReloadList(false);
      }
    }
  }, [selectedSubject, reloadList]);
  
  function handleShowNee(open){
    setShowNEE(open);
    if (open && details.length > 0) {
      const period = details[0].dataInicio.substr(-4, 4);
      api
        .get(`/programas-especiais/diario/lista/${period}/${selectedClass}`)
        .then((res) => {
          setNEETYype(res.data);
        })
        .catch(() => {});
  
      api
        .get(
          `/programas-especiais/diario/lista-aluno/${selectedNEE}/${selectedSubject}/${period}`
        )
        .then((res) => {
          setCNEE(res.data);
        })
        .catch(() => {});
    }
  }

  function handleSubmitNEE() {
    if (newNEE.length < 1 && !CNEE.descricao) {
      toast.error("Digite uma descrição para salvar");
      return;
    }
    const types = [];
    NEEType.map((item) => {
      if (document.getElementById(`nee-${item.id}`).checked) {
        types.push(item.id);
      }
    });

    const json = {
      matriculaAluno: selectedNEE,
      descricao: newNEE.length > 0 ? newNEE : CNEE.descricao,
      bimestreId: selectedSubject,
      tiposDeDiagnosticos: types,
    };

    api
      .post(`/programas-especiais/diario/inserir-nee`, json)
      .then((res) => {
        toast.success(res.data);
        setShowNEE(false);
        setReloadList(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setShowNEE(false);
      });
  }

  async function openModalAnnotation(idMatricula){
    const obj = {...objAval};
    obj.matriculaAluno = idMatricula;
    obj.professorId = idUser;
    obj.idTurma = selectedClass;
    obj.bimestreId = selectedSubject;
    try {
      let res;
      if(tableHeader.length > 1) {
        res = await getAvaliacao(idMatricula);
      } else {
        res = await getAvaliacaoProfEspecialista(idMatricula);
      }
      if(res.status === 200){
        obj.id = res.data.id;
        obj.comentarios = res.data.comentarios;
      } else {
        obj.comentarios = "";
      }
    }catch(error){
      toast.error(error.response);
    }
    setObjAval(obj);
    setShowTest(true);
  }

  function handleSubmitTestText() {
    if (objAval.comentarios.length < 1 ) {
      toast.warn("Digite uma descrição para salvar");
      return;
    }
    const exists = listToSend.find((item) => item.matriculaAluno.toString() === objAval.matriculaAluno.toString());
    const index = listToSend.indexOf(exists);
    const newListToSend = [...listToSend];
    if(exists){
      newListToSend[index].possuiObs = true;
    }

    const existsFilteredList = filteredList.find((item) => item.matriculaAluno.toString() === objAval.matriculaAluno.toString());
    const indexFilteredList = listToSend.indexOf(exists);
    const newFilteredList = [...filteredList];
    if(existsFilteredList){
      newFilteredList[indexFilteredList].possuiObs = true;
    }
    
    api.post(`/programas-especiais/diario/inserir-desempenho`, objAval)
      .then((res) => {
        toast.success(res.data);
        setShowTest(false);
        setListToSend(newListToSend);
        setFilteredList(newFilteredList);
      })
      .catch((err) => {
        if(err.response){
          toast.error(err.response.data);
        }
        setShowTest(false);
      });
  }

  function validaNotaNumber(nota){
    const notaParsed = parseInt(nota, 10);
    if(notaParsed >= 0){
        return parseInt(nota, 10);
    }
    return nota.toUpperCase();
  }

  function addGradeToStudent(e, sId, nee, qtdFaltas) {
    const listaIdMatriculaAluno = [...listaLancamentoAlunos];
    const inList = listaIdMatriculaAluno.find((matriculas)=> matriculas === sId)
    if(!inList){
      listaIdMatriculaAluno.push(sId)
    }
    setListaLancamentoAlunos(listaIdMatriculaAluno);
    if(e.target.id === "faltasCompensadas"){
      if (e.target.value > 10 || e.target.value < 0 || e.target.value > qtdFaltas) {
        e.target.classList.add("input-table-error");
      } else {
        const str = e.target.className;
        if (str.includes(" input-table-error")) {
          e.target.classList.remove("input-table-error");
        }
      }
    } else if (numeric && !nee) {
      if (e.target.value.includes("-")) {
        e.target.value = "";
        return;
      }
      if (e.target.value > 10 || e.target.value < 0) {
        e.target.classList.add("input-table-error");
        
      } else {
        const str = e.target.className;
        if (str.includes(" input-table-error")) {
          e.target.classList.remove("input-table-error");
        }
      }
    } else {
      const val = e.target.value.toUpperCase();
      if (!strings.includes(val)) {
        e.target.classList.add("input-table-error");
      } else {
        const str = e.target.className;
        if (str.includes(" input-table-error") || str.length === 0) {
          e.target.classList.remove("input-table-error");
        }
      }
    }

    const exists = listToSend.find((item) => item.matriculaAluno === sId);
    const index = listToSend.indexOf(exists);
    if (exists) {
      if (e.target.id === "faltasCompensadas") {
        listToSend[index].alunoPossuiDeficiencia = nee;
        listToSend[index].ausenciasCompensadas = parseInt(e.target.value, 10);
      } else {
        listToSend[index].alunoPossuiDeficiencia = nee;
        const existsDis = listToSend[index].notas.find(
          (item) => item.disciplinaId.toString() === e.target.id.toString()
        );
        const indexDis = listToSend[index].notas.indexOf(existsDis);
        
        if (existsDis) {
          listToSend[index].notas.splice(indexDis, 1);
        }
        listToSend[index].notas.push({
          disciplinaId: parseInt(e.target.id, 10),
          nota: validaNotaNumber(e.target.value),
        });
      }
    } else if (!exists && e.target.id === "faltasCompensadas"){
        listToSend.push({
          alunoPossuiDeficiencia: nee,
          matriculaAluno: parseInt(sId, 10),
          ausenciasCompensadas: parseInt(e.target.value, 10),
        });
    } else {
        listToSend.push({
          alunoPossuiDeficiencia: nee,
          matriculaAluno: parseInt(sId, 10),
          notas: [
            {
              disciplinaId: parseInt(e.target.id, 10),
              nota: e.target.value.toUpperCase(),
            },
          ],
        });
      }
  }

  function handleChangeAnnotation(event) {
    const json = {...objAval};
    json[event.target.name] = event.target.value;
    setObjAval(json);
  }

  function validateJsonToSend(jsonToValidate){
    let errorConceitos = false;
    let errorSemAvaliacao = false; 
    let errorAusencias = false;

    jsonToValidate.alunosNotas.map((aluno) =>{
      aluno.notas.map((notas)=> {
        if (numeric && !aluno.alunoPossuiDeficiencia) {
          if( notas.nota > 10 || notas.nota < 0){
            errorConceitos = true;
          } else {
            notas.nota = parseInt(notas.nota, 10);
          }

        } else if(!strings.includes(notas.nota)){
          errorConceitos = true;
        }

        if(!aluno.possuiObs){
          errorSemAvaliacao = true;
        }

        if(aluno.qtdFaltas >= aluno.ausenciasCompensadas || 
          aluno.ausenciasCompensadas >= 0){
            if(aluno.notas.length > 1 ){
              if(notas.professorEspecialista || notas.disciplinaId.toString() === "109"){
                notas.ausenciasCompensadas = aluno.ausenciasCompensadas;
                notas.faltas = aluno.qtdFaltas;
              } else {
                notas.ausenciasCompensadas = 0;
                notas.faltas = 0;
              }
            } else{
              notas.ausenciasCompensadas = aluno.ausenciasCompensadas;
              notas.faltas = aluno.qtdFaltas;
            }
        } else {
          errorAusencias = true;
        }

      });
    });

    if(errorConceitos){
      toast.warn("Verifique os conceitos inseridos");
      setLoadingBtn(false);
      return false;
    } 
    if(errorSemAvaliacao){
      toast.warn("Atenção, é obrigatório o preenchimento do campo Avaliação para alunos com conceitos");
      setLoadingBtn(false);
      return false;
    }

    if(errorAusencias){
      toast.warn("Ausências compensadas não podem ser maiores que o número de faltas");
      setLoadingBtn(false);
      return false;
    }
    return true;
  }

  function handleSubmit(fechamento) {
    setLoadingBtn(true);
    const listCleanToSend = [];

    listToSend.map((alunos) => {
      const inList = listaLancamentoAlunos.find((alunosMov)=> alunosMov === alunos.matriculaAluno);
      if(inList){
        if(alunos.permiteEditar && alunos.notas.length > 0){
          listCleanToSend.push(alunos);
        }
      }
    })
    const jsonToSend = {
      idCao: parseInt(selectedSubject, 10),
      alunosNotas: listCleanToSend,
      isFechamento: fechamento
    };

    const jsonValid = validateJsonToSend(jsonToSend)

    if(jsonValid) {
      if(jsonToSend.alunosNotas.length > 0 ){
        api
          .post(`/notas-faltas/diario/inserir-notas`, jsonToSend)
          .then((res) => {
            toast.success(res.data);
            setLoadingBtn(false);
          })
          .catch((err) => {
            setLoadingBtn(false);
            if (err.response) {
              toast.error(err.response.data);
            }
          });
      } else {
        setLoadingBtn(false);
        toast.success("Notas salvas com sucesso!");
      }
    }
  }

  return (
    <LoadingScreen activeLoad={activeLoad} >
      <Container>
        <Content>
          <TitleContainer>
            <Title>Avaliação do Fundamental</Title>
          </TitleContainer>
          <FilterContainer>
            {profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? 
              (<>
                <FilterDiv>
                  <SelectInput
                    name="prof"
                    label="Professor"
                    type="list"
                    placeholder="SELECIONAR PROFESSOR"
                    options={profOptions}
                    func={(e) => setSelectedProf(e)}
                    />
                </FilterDiv>
              </>) : 
              (<></>)}
            <FilterDiv>
              <SelectInput
                name="class"
                label="Turma"
                type="list"
                placeholder="SELECIONAR TURMA"
                options={classesOptions}
                func={(e) => setSelectedClass(e)}
              />
            </FilterDiv>
            <FilterDiv>
              <SelectInput
                name="class"
                label="Bimestre"
                type="list"
                placeholder="SELECIONAR BIMESTRE"
                options={subjectOptions}
                func={(e) => {
                  setSelectedSubject(e);
                  setDetails(detailsOptions.filter((item) => item.idCao.toString() === e));
                }}
              />
            </FilterDiv>
            <FilterDiv>
              <TextInput
                name="nameFilter"
                label="Buscar um aluno"
                placeholder="DIGITE O NOME"
                filter
                onChange={(e) => {
                  setFilteredList(
                    list.filter((name) =>
                      name.nomeAluno.includes(e.target.value.toUpperCase())
                    )
                  );
                }}
              />
            </FilterDiv>
          </FilterContainer>
          <Subtitle>{details.length > 0 ? details[0].descricao : ""}</Subtitle>
          <FilterDataDiv>
            <FilterData>
              {details.length > 0
                ? `${details[0].dataInicio} - ${details[0].dataFim}`
                : ""}
            </FilterData>
            <FilterData>
              {details.length > 0 ? (
                <>
                  Aulas dadas no ano: <b> {details[0].qtdAulasDadasNoAno} </b>
                </>
              ) : (
                ""
              )}
            </FilterData>
            <FilterData>
              {details.length > 0 ? (
                <>
                  Aulas dadas no bimestre:{" "}
                  <b> {details[0].qtdAulasDadasNoBimestre} </b>
                </>
              ) : (
                ""
              )}
            </FilterData>
            {ready ? (<>
              <PDFDownloadLink document={PDFNotas} fileName="NotasFaltas.pdf">
                {({ loading }) =>
                  loading ? (
                    "Carregando..."
                  ) : (
                    <PrimaryButton style={{ height: 30, width: 85 }}>
                      Exportar
                    </PrimaryButton>
                  )
                }
              </PDFDownloadLink> 
            </>) : (
              <>
                <PrimaryButton
                  style={{ height: 30, width: 85, opacity: 0.6 }}
                  disabled
                >
                  Exportar
                </PrimaryButton>
              </>
            )}
          </FilterDataDiv>
          <hr style={{ color: "#CACDDF" }} />
          <PeriodAlert>
            {details.length > 0 ? (
              <>
                <MdWarning color="#f85241" size={16} style={{ marginRight: 5 }} />
                Período de lançamento de notas:{"  "}
                <b style={{ marginLeft: 3 }}>até: {details[0].dataLancamento} </b>
              </>
            ) : (
              ""
            )}
          </PeriodAlert>
          <PeriodAlert>
            {details.length > 0 ? (
                <b>Atenção, é obrigatório o preenchimento do campo Avaliação</b>
            ) : (
              ""
            )}
          </PeriodAlert>
          <TableLegend>
            Legendas: <b>NEE </b> (Necessidades Educativas Especiais) / <b>AC</b> (Ausências compensadas)
          </TableLegend>
          <TableLegend>
            {numeric ? (
              <>Modo de avaliação numérico: 0 a 10</>
            ) : (
              <>
                Modo de avaliação conceitual: notas válidas{" "}
                {strings.map((item, index) => (
                  <div key={index.toString()}>
                    {index + 1 !== strings.length ? (
                      <b>{item}, </b>
                    ) : (
                      <b>{item}.</b>
                    )}
                  </div>
                  
                ))}
              </>
            )}
          </TableLegend>
          <TableLegend style={{display: "flex"}}>
            <>
              Para alunos com NEE, utilizar avaliação conceitual: notas válidas{" "}
              {strings.map((item, index) => (
                <div key={index.toString()}
                style={{marginLeft: "2px"}}>
                  {index + 1 !== strings.length ? (
                    item !== "NS" ? <span><b>{item} </b>({notasSiglas[index].legenda}),</span> : ""
                  ) : (
                    item !== "NS" ? <span><b>{item} </b>({notasSiglas[index].legenda}).</span> : ""
                  )}
                </div>
              ))}
            </>
          </TableLegend>
          {tableHeader.length > 0 ? (
            <DataTable striped hover responsive>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>ALUNO(A)</th>
                  <th> </th>
                  <th>STATUS</th>
                  <th> </th>
                  {tableHeader.map((item,index) => (
                    <th key={index.toString()}>{item.abreviacao}</th>
                    ))}
                  <th>FALTAS</th>
                  <th>AC</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((item, idx) => (
                  <tr
                    key={idx.toString()}
                    style={{
                      backgroundColor: item.possuiNee ? "#CEEDFE" : "none",
                    }}
                  >
                    <td>{item.numeroChamada}</td>
                    <td>
                      {item.fotoAluno ? (
                        <StudentImage
                          src={`data:image/png;base64,${item.fotoAluno}`}
                        />
                      ) : (
                        <StudentImage src={DefaultImage} />
                      )}
                    </td>
                    <td>
                      {item.possuiNee ? (
                        <>
                          {item.nomeAluno}{" "}
                          <OverlayTrigger
                            key={item.nomeAluno}
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${item.nomeAluno}`}>
                                Aluno com NEE
                              </Tooltip>
                            }
                          >
                            <MdAccessible color="#5c6377" size={22} />
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>{item.nomeAluno}</>
                      )}
                    </td>
                    <td>
                      {item.statusAluno}
                      <LegendasStatus nomeAluno={item.nomeAluno} sigla={item.statusAluno}/>
                    </td>
                    <td>
                      {item.qtdAda > 0 ? (
                        <MdDescription
                          color="#5c6377"
                          style={{ cursor: "pointer", display: "none"}}
                          size={22}
                          onClick={() => {
                            handleShowNee(true);
                            setModalTitle(item.nomeAluno);
                            setSelectedNEE(item.matriculaAluno);
                            setHasNEE(item.possuiNee);
                          }}
                        />
                      ) : (
                        <MdDescription
                          color="#CACDDF"
                          style={{ cursor: "pointer", display: "none" }}
                          size={22}
                          onClick={() => {
                            handleShowNee(true);
                            setModalTitle(item.nomeAluno);
                            setSelectedNEE(item.matriculaAluno);
                            setHasNEE(item.possuiNee);
                          }}
                        />
                      )}
                    </td>
                    {tableHeader.map((sub, index) => (
                      <td key={index.toString()}>
                        <TableInput
                          id={sub.disId}
                          onChange={(e) =>
                            addGradeToStudent(
                              e,
                              item.matriculaAluno,
                              item.possuiNee
                            )
                          }
                          type={
                            item.possuiNee ? "text" : numeric ? "number" : "text"
                          }
                          min={item.possuiNee ? "" : numeric ? "0" : ""}
                          max={item.possuiNee ? "" : numeric ? "10" : ""}
                          defaultValue={
                            item.notas.find(
                              (itens) => itens.disciplinaId === sub.disId
                            )
                              ? `${item.notas.find(
                                (itens) => itens.disciplinaId === sub.disId
                              ).nota}`
                              : ""
                          }
                          disabled={!item.permiteEditar || !isEditable || isForaDataLimite}
                          style={!isEditable || isForaDataLimite ? {cursor: "not-allowed"} : {}}
                          className="input-table"
                          permiteEditar={!item.permiteEditar}
                        />
                      </td>
                    ))}
                    <td>
                      <TableInput
                        style={{ cursor: "not-allowed", color: "#979BB2" }}
                        disabled
                        defaultValue={item.qtdFaltas}
                      />
                    </td>
                    <td>
                      <TableInput
                        id="faltasCompensadas"
                        onChange={(e) =>
                          addGradeToStudent(
                            e, 
                            item.matriculaAluno, 
                            item.possuiNee,
                            item.qtdFaltas
                          )
                        }
                        disabled={!item.permiteEditar || !isEditable || isForaDataLimite}
                        style={!isEditable || isForaDataLimite ? {cursor: "not-allowed"} : {}}
                        permiteEditar={!item.permiteEditar}
                        defaultValue={item.faltasCompensadas}
                        type="number"
                        min={0}
                        max={item.qtdFaltas}
                        className="input-table"
                      />
                    </td>
                    <td>
                      {(!item.permiteEditar && (!isEditable || isForaDataLimite) ) ? (<>
                        <SecondaryButton
                          disabled
                          style={{ height: 30, width: 85, cursor: 'not-allowed' }}
                        >
                          Avaliação
                        </SecondaryButton>
                      </>):(<>
                        <div style={{ display: 'inline-grid', justifyItems: 'center'}}>
                          <SecondaryButton
                            onClick={() => {
                              openModalAnnotation(item.matriculaAluno);
                              setModalTitle(item.nomeAluno);
                            }}
                            disabled={!item.permiteEditar || !isEditable || isForaDataLimite}
                            style={!isEditable || isForaDataLimite ? {cursor: "not-allowed", height: 30, width: 85 } : { height: 30, width: 85 }}
                          >
                            Avaliação
                          </SecondaryButton>
                          {!item.possuiObs ? (<>
                            <span style={{ color: '#f85241', fontSize: '10px' }}>
                              Avaliação não preenchida
                            </span>
                          </>) : (<></>)}
                        </div>
                      </>)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DataTable>
          ) : (
            <></>
          )}
        </Content>
        {filteredList.length > 0 ? (<>
          <FixedFooter>
            <ActionUPDiv
              style={{ backgroundColor: "transparent", border: "none" }}
            />
            <ActionButtonsDiv>
              {loadingBtn ? 
                (<>
                  <div style={{ display: "flex", width: "100%", justifyContent: "center"}}>
                    <LoadingButtons loading={loadingBtn}/>
                  </div>
                </>)
                :
                (<>
                  <SecondaryButton 
                    style={{ marginRight: 10 }}
                    onClick={() => history.push("/home")}
                    >
                    Voltar
                  </SecondaryButton>
                  <PrimaryButton
                    style={!isEditable || isForaDataLimite ? {cursor: "not-allowed", marginLeft: 10 } : {}}
                    onClick={() => handleSubmit(false)}
                    
                    disabled={!isEditable || isForaDataLimite}
                  >
                    Salvar notas
                  </PrimaryButton>
                  <PrimaryButton
                      style={!isEditable || isForaDataLimite ? {cursor: "not-allowed", marginLeft: 10 } : {marginLeft: 10 }}
                      onClick={() => handleSubmit(true)}
                      disabled={!isEditable || isForaDataLimite}
                    >
                      Encerrar Faltas
                    </PrimaryButton>
                </>) 
              }
            </ActionButtonsDiv>
            <ActionUPDiv onClick={() => window.scrollTo(0, 0)}>
              <MdArrowUpward color="#3E70E8" size={20} />
            </ActionUPDiv>
          </FixedFooter>
        </>) : (<></>)}
        <Modal
          show={showNEE}
          hide={() => {
            setShowNEE(false);
            setNewNEE("");
            setModalTitle("");
            setCounterAnnotation(0);
            setCNEE({});
          }}
          size="lg"
          title="Necessidades Educativas Especiais"
          cancelText="Cancelar"
          confirmText="Salvar"
          onConfirm={() => handleSubmitNEE()}
        >
          <SubTitleAnnotationModal>
            {hasNEE ? (
              <>
                <MdAccessible color="#5c6377" size={22} />{" "}
              </>
            ) : (
              <></>
            )}
            {modalTitle}
          </SubTitleAnnotationModal>
          <hr />
          <NEETypesDiv>
            {NEEType &&
              NEEType.map((item) => (
                <>
                  <label
                    style={{
                      color: "#5C6377",
                      fontSize: 18,
                      marginRight: 40,
                      marginLeft: 3,
                    }}
                    htmlFor={`nee-${item.id}`}
                  >
                    <input
                      type="checkbox"
                      id={`nee-${item.id}`}
                      name={`nee-${item.id}`}
                      defaultChecked={
                        CNEE.tiposDiagnosticos &&
                        CNEE.tiposDiagnosticos.some((nee) => nee.id === item.id)
                      }
                    />
                    {item.abreviatura.toUpperCase()}
                  </label>
                </>
              ))}
          </NEETypesDiv>
          <ScrollBarArea>
            <PerfectScrollbar>
              <ModalTextArea
                maxLength={4000}
                style={{ height: textAreaHeight }}
                defaultValue={CNEE.descricao ? CNEE.descricao : ""}
                onChange={(e) => {
                  setTextAreaHeight(e.target.scrollHeight);
                  setCounterAnnotation(e.target.value.length);
                  setNewNEE(e.target.value);
                }}
              />
            </PerfectScrollbar>
          </ScrollBarArea>
          <CounterDiv>
            {counterAnnotation > 0
              ? counterAnnotation
              : CNEE.descricao
              ? CNEE.descricao.length
              : 0}
            /4000
          </CounterDiv>
        </Modal>
        
        <Modal
          show={showTest}
          hide={() => {
            setShowTest(false);
            setModalTitle("");
            setCounterAnnotation(0);
            setObjAval({});
          }}
          size="lg"
          title="Desempenho por Aluno"
          cancelText="Cancelar"
          confirmText="Salvar"
          onConfirm={() => handleSubmitTestText()}
        >
          <SubTitleAnnotationModal>
            {modalTitle}
          </SubTitleAnnotationModal>
          <hr />
          <ScrollBarArea>
            <PerfectScrollbar>
              <ModalTextArea
                name="comentarios"
                maxLength={4000}
                style={{ height: textAreaHeight }}
                defaultValue={objAval.comentarios}
                onChange={(e) => {
                  setTextAreaHeight(e.target.scrollHeight);
                  setCounterAnnotation(e.target.value.length);
                  handleChangeAnnotation(e);
                }}
              />
            </PerfectScrollbar>
          </ScrollBarArea>
          <CounterDiv>
            {counterAnnotation > 0
              ? counterAnnotation
              : objAval.comentarios
              ? objAval.comentarios.length
              : 0}
            /4000
          </CounterDiv>
        </Modal>
      </Container>
    </LoadingScreen>
  );
}

export default AvaliacaoFundamental;
