import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./global";
import Routes from "./routes/routes";

import { AuthProvider } from "./contexts/AuthContext";
import { LogProviders } from "./contexts/LogContext";

function App() {
  return (
    <>
      <LogProviders>
        <Routes />
        <ToastContainer />
        <GlobalStyle />
        <AuthProvider />
      </LogProviders>
    </>
  );
}

export default App;
