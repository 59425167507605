import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";

import NavBar from "../components/NavBar";

function Router({ isPrivate = false, component: Component, ...rest }) {
  const { loggedUser } = useContext(AuthContext);
  const logged = loggedUser.username !== "";

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {!isPrivate ? (
              <Component {...props} />
            ) : isPrivate === logged ? (
              <>
                <NavBar />
                <Component {...props} />
              </>
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )}
          </>
        );
      }}
    />
  );
}

export default Router;
