import styled from "styled-components";
import { Form } from "@unform/web";
import { ListGroup, Table } from "react-bootstrap";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";

export const Container = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  background-color: #fff;
`;

export const Content = styled.div`
  width: 1100px;
  margin-bottom: 110px;

  @media (max-width: 800px) {
    width: 98%;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export const FilterContainer = styled(Form)`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
`;

export const FilterDiv = styled.div`
  width: 350px;
  margin-right: 10px;
`;

export const DataTable = styled(Table)`
  color: #5c6377;
`;

export const DataTableHeader = styled.th`
  text-align: left !important;
`;

export const DataTableBody = styled.td`
  text-align: left !important;
`;

export const StudentImage = styled.img`
  height: 75px;
  width: 65px;
  border-radius: 10px;
  object-fit: cover;
`;

export const CounterDiv = styled.div`
  align-self: flex-end;
  margin-top: 5px;
  margin-right: 5px;
  color: #5c6377;
`;

export const TextAreaDiv = styled.div`
  border: 1px solid #cacddf;
  border-radius: 10px !important;
  overflow: hidden;
  height: 293px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ModalTextArea = styled.textarea`
  padding: 10px;
  border: none;
  width: 100%;
  height: 293px;
  resize: none;
  border-radius: 10px;
  padding: 10px;
`;

export const Name = styled.div`
  font-size: 18px;
  color: #5c6377;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: -25px;
`;

export const NewButton = styled(PrimaryButton)`
  height: 90%;
  width: 45%;
  border-radius: 10px;
`;

export const HistoryButton = styled(SecondaryButton)`
  height: 90%;
  width: 45%;
  margin-right: 15px;
  border-radius: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 45%;
  height: 40px;
`;

export const ScrollBarArea = styled.div`
  border: 1px solid #ccc;
  height: 235px;
  overflow-x: hidden;
  border: 1px solid #cacddf;
  border-radius: 10px;
  margin-top: 10px;
  padding: 2px;
`;

export const ModalText = styled.text`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow-wrap: break-word;
  width: 100%;
  height: 150px;
  resize: none;
  border-radius: 10px;
`;

export const ModalListDiv = styled.div`
  border: 1px solid #cacddf;
  border-radius: 10px !important;
  overflow: hidden;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const ModalTextDiv = styled.div`
  border: 1px solid #cacddf;
  border-radius: 10px !important;
  overflow: hidden;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px 10px 20px;
`;

export const SubTitleAnnotationModal = styled.div`
  color: #5c6377;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  margin-top: -23px;
  margin-bottom: 20px;
`;

export const ModalList = styled(ListGroup)`
  color: #5c6377;
  max-height: 100px;
  border-radius: 10px !important;
  .list-group-item:nth-of-type(odd) {
    background: #f2f4f7;
  }
  .list-group-item {
    border: none;
    &:hover {
      text-align: left;
      color: #5c6377;
      background-color: #ceedfe;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  height: 40px;
  margin-bottom: 20px;
  align-self: center;
`;
