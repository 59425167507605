import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import Error from "../ErrorInput";

import {
  Container,
  SelectInput,
  SelectLabel,
  SelectLabelLista,
} from "./styles";

function InputSelect({
  label,
  name,
  required,
  options,
  type,
  func,
  placeholder,
  hasValue,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.value) {
            return [];
          }
          return ref.value.map((option) => option.value);
        }
        if(!ref.value) {
          return "";
        } 
        return ref.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  switch (type) {
    case "insert":
      return (
        <Container {...rest}>
          {label !== "" && 
          <SelectLabel htmlFor={`input-${name}`}>
            {label} {required && `*`}
          </SelectLabel>
          }
          <SelectInput
            id={`input-${name}`}
            name={name}
            ref={selectRef}
            defaultValue={hasValue}
            onChange={func ? (e) => func(e.target.options.selectedIndex) : ""}
          >
            <option value="SELECIONE">
              {!placeholder ? "SELECIONE" : placeholder}
            </option>
            {hasValue ? (
              <>
                {options.map((item, index) => (
                  <option key={index.toString()} 
                    /* selected={item.value === hasValue} */
                    value={item.value}>
                    {item.label}
                  </option>
                ))}
              </>
            ) : (
              <>
                {options.map((item,index) => (
                  <option
                    key={index.toString()}
                    /* selected={item.value === defaultValue} */
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))}
              </>
            )}
          </SelectInput>
          <Error>{error}</Error>
        </Container>
      );
    case "list":
      return (
        <Container>
          {label !== "" && 
          <SelectLabelLista htmlFor={`input-${name}`}>
            {label} {required && `*`}
          </SelectLabelLista>
          }
          <SelectInput
            id={`input-${name}`}
            name={name}
            ref={selectRef}
            defaultValue={hasValue}
            onChange={func ? (e) => func(e.target.value) : ""}
            {...rest}
          >
            <option value="SELECIONE">
              {!placeholder ? "SELECIONE" : placeholder }
            </option>
            {hasValue ? (
              <>
                {options.map((item,index) => (
                  <option key={index.toString()} 
                    /* selected={item.value === hasValue} */ 
                    value={item.value}>
                    {item.label}
                  </option>
                ))}
              </>
            ) : (
              <>
                {options.map((item,index) => (
                  <option
                    key={index.toString()}
                    /* selected={item.value === defaultValue} */
                    value={item.value} >
                    {item.label}
                  </option>
                ))}
              </>
            )}
          </SelectInput>
          <Error>{error}</Error>
        </Container>
      );
    default:
      return null;
    case "":
      return null;
  }
}

export default InputSelect;
