import styled from "styled-components";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

export const Menu = styled(Navbar)`
  width: 100% !important;
  background-color: #3e70e8 !important;
  border-radius: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  a {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8) !important;
    font-weight: 700;
  }
  a:hover {
    color: rgba(255, 255, 255, 1) !important;
  }
`;

export const Brand = styled(Navbar.Brand)`
  padding-left: 12px;
  padding-right: 8px;
  background-color: #fff !important;
`;

export const ImgBrand = styled.img`
  height: 55px;
  width: 55px;
`;

export const Item = styled(Nav)`
  a {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.8) !important;
    font-weight: 700;
  }
  a:hover {
    color: rgba(255, 255, 255, 1) !important;
  }
`;

export const Dropdown = styled(NavDropdown)`
  div {
    background-color: #fff;
    a {
      color: #5c6377 !important;
      background-color: #fff;
    }
    a:hover {
      color: #5c6377 !important;
      background-color: #f2f4f7;
    }
  }
`;
