import React, { createContext, useCallback } from "react";

import api from "../services/api";

export const LogContext = createContext();

export function LogProviders({ children }) {

    const sendLog = useCallback((path) => {
        const jsonToLog = {
            usuario: sessionStorage.getItem("dc:username"),
            funcionalidade: path
        };
        api.post('/log-diario/diario/inserir-log-acesso', jsonToLog)
    });

    return (
        <LogContext.Provider value={{sendLog}}>
            {children}
        </LogContext.Provider>
    );
}