import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Input } from "./styles";

import Error from "../ErrorInput";

function InputLogin({ placeholder, name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Input placeholder={placeholder} ref={inputRef} {...rest} />
      <Error> {error} </Error>
    </>
  );
}

export default InputLogin;
