import React, { useState, useEffect, useContext, useCallback } from "react";
import { MdCheck, MdComment, MdArrowUpward, MdWarning, MdAccessible } from "react-icons/md";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import ptBR from "date-fns/locale/pt-BR";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PDFDownloadLink,
  BlobProvider,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
} from "@react-pdf/renderer";

import Title from "../../components/Title";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import ReportHeader from "../../components/ReportHeader";

import DefaultImage from "../../assets/aluno_default.jpg";
import Icon from "../../assets/icon_acessibilidade.png";

import api from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import "jspdf-autotable";

import {
  Container,
  Content,
  TitleContainer,
  FilterContainer,
  FilterDiv,
  DateTimeDiv,
  DateDiv,
  DateFilterPicker,
  Picker,
  IconCalendarFilter,
  TopTableDiv,
  CurriculumLink,
  TopTableButtonsDiv,
  DataTable,
  StudentImage,
  NotificationIndicator,
  PresenceTrue,
  PresenceFalse,
  FixedFooter,
  ActionButtonsDiv,
  ActionUPDiv,
  SubTitleAnnotationModal,
  ScrollBarArea,
  ModalTextArea,
  CounterDiv,
  Buttons,
  HistoryButton,
  NewButton,
  ModalList,
  ModalListDiv,
  ScrollBarAreaBncc,
  BnccText,
  BnccTitle,
  ButtonsDiv,
  ListEvents,
  ContentListEvent,
} from "./styles";
import { TableLegend } from "../AvaliacaoFundamental/styles";
import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import LoadingButtons from "../../components/Loading/LoadingButtons";
import LegendasStatus from "../../components/LegendasStatus";
import { LogContext } from "../../contexts/LogContext";



Font.register({
  family: "Montserrat",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
});

Font.register({
  family: "MontserratBold",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
});

function ListaPresenca() {
  const history = useHistory();
  const { loggedUser } = useContext(AuthContext);
  const { sendLog } = useContext(LogContext);
  const { institution, id } = loggedUser;
  const institutions = JSON.parse(sessionStorage.getItem("dc:institutions"));
  const profile = JSON.parse(sessionStorage.getItem("dc:profile"));
  const [activeLoad, setActiveLoad] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const [classesOptions, setClassesOptions] = useState([]);
  const [profOptions, setProfOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedProf, setSelectedProf] = useState("");
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  const [isProfessorEspecialista, setIsProfessorEspecialista] =  useState("");
  const [horaAulaOptions, setHoraAulaOptions] = useState([]);
  const [selectedHoraAula, setSelectedHoraAula] = useState("");
  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [pureDate, setPureDate] = useState(new Date());
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [foulList, setFoulList] = useState([]);
  const [remoteList, setRemoteList] = useState([]);
  const [idAud, setIdAud] = useState("");
  const [editAnnotation, setEditAnnotation] = useState(false);
  const [newAnnotation, setNewAnnotation] = useState("");
  const [currentAnnotation, setCurrentAnnotation] = useState({});
  const [editOccurence, setEditOccurence] = useState(false);
  const [showModalObs, setShowModalObs] = useState(false);
  const [objObsAluno, setObjObsAluno] = useState({matriculaAlunoId:undefined, obs: undefined});
  const [editOccurenceHistory, setEditOccurenceHistory] = useState(false);
  const [studentToOccurence, setStudentToOccurence] = useState({});
  const [ocurrences, setOcurrences] = useState({});
  const [currentOcurrence, setCurrentOcurrence] = useState("");
  const [newOcurrence, setNewOcurrence] = useState("");
  const [modalAnnotationTitle, setModalAnnotationTitle] = useState("");
  const [textAreaHeight, setTextAreaHeight] = useState(150);
  const [counterAnnotation, setCounterAnnotation] = useState(0);
  const [showBncc, setShowBncc] = useState(false);
  const [bncc, setBncc] = useState([]);
  const [canSave, setCanSave] = useState(true);
  const [cannotSaveMessage, setCannotSaveMessage] = useState("");
  const [showAtt, setShowAtt] = useState(true);
  const [ready, setReady] = useState(false);
  const [readySingle, setReadySingle] = useState(false);
  const [readyOccurences, setReadyOccurences] = useState(false);
  const [PDFPresenca, setPDFPresenca] = useState();
  const [PDFOcorrenciaUnica, setPDFOcorrenciaUnica] = useState();
  const [PDFOcorrencias, setPDFOcorrencias] = useState();
  const [listDatesWithReleases, setListDatesWithReleases] = useState([]);
  const [listEvent, setListEvents] = useState([]);
  const idUser = profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? selectedProf : id;
  
  const currentYear = format(new Date(), "yyyy");
  const [listDiasLetivos, setListDiasLetivos] = useState([]);
  const [listDiasNaoLetivos, setListDiasNaoLetivos] = useState([]);
  const [dataInicioAno, setDataInicioAno] = useState("");
  const [dataFimAno, setDataFimAno] = useState("");

  const isEditable = profile.sigla !== "DIR" && profile.sigla !== "CRD" && profile.sigla !== "ADMIN" && profile.sigla !== "SADM" && profile.sigla !== "VDIR";

  async function getTurmas(){
    const response = await api.get(`/lista-presenca/diario/filtro-turma-geral/${idUser}/${institution}`)
    return response;
  }

  async function getProfessores(){
    const response = await api.get(`/lista-presenca/diario/filtro-professores/${id}/${institution}`)
    return response;
  }
  
  async function getListDatesWithReleases(){
    const response = await api.get(`/lista-presenca/diario/datas-registradas/${institution}/${selectedClass}/${selectedSubject}`)
    return response;
  }

  async function getAlunos(idAudAux){
    const response = await api.get(
      `/lista-presenca/diario/busca-alunos/${institution}/${selectedClass}/${selectedSubject}/${idUser}/${idAudAux}?dataAula=${selectedDate}`)
      return response;
  }
  async function getEventsInDay(){
    const response = await api.get(
      `/calendario/diario/listar-eventos/${institution}?dataAula=${selectedDate}`)
      return response;
  }

  async function getListDates(yearToSearch){
    const response = await api.get(`/lista-presenca/diario/dias-letivos/${institution}/${yearToSearch}`)
    return response;
  }

  function generatePDFOcorrenciasGerais(ocorrencias){
    setPDFOcorrencias(
      <Document>
        <Page
          style={{
            padding: 35,
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
          }}
          wrap
          size="A4"
        >
          <ReportHeader name={loggedUser.name} title="Ocorrências" />
          <Text
            style={{
              color: "#5C6377",
              fontSize: 10,
              marginTop: 11,
              fontFamily: "MontserratBold",
            }}
          >
            {studentToOccurence && studentToOccurence.nomeAluno}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: 15,
            }}
          >
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              ESCOLA:{" "}
              <Text style={{ fontFamily: "MontserratBold" }}>
                {institutions[parseInt(institution, 10)]}
              </Text>
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              TURMA:{" "}
              <Text style={{ fontFamily: "MontserratBold" }}>
                {classesOptions.find((item) => item.value === selectedClass) &&
                  classesOptions.find((item) => item.value === selectedClass)
                    .label}
              </Text>
            </Text>
          </View>
          <View wrap style={{ marginTop: 15 }}>
            {ocorrencias.length > 0 &&
              ocorrencias.map((item, index) => (
                <View
                  key={index.toString()}
                  wrap={false}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: index % 2 === 0 ? "#F2F4F7" : "",
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginTop: index === 0 ? 20 : 0,
                    borderTopColor: index === 0 ? "#CACDDF" : "",
                    borderTopWidth: index === 0 ? 1 : 0,
                    borderBottomColor: "#CACDDF",
                    borderBottomWidth: 1,
                  }}
                >
                  <Text
                    style={{
                      color: "#5C6377",
                      fontSize: 12,
                      fontFamily: "MontserratBold",
                    }}
                  >
                    {item.dataOcorrencia} - {item.nomeProfessor}
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: 5,
                    }}
                  >
                    <Text style={{ color: "#5C6377", fontSize: 8 }}>
                      {item.descricao}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        </Page>
      </Document>
    );
  }

  function generatePDFPresenca(data, annotation){
    setPDFPresenca(
      <Document>
        <Page
          style={{
            padding: 35,
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
          }}
          wrap
          size="A4"
        >
          <ReportHeader name={loggedUser.name} title="Lista de presença" />
          <Text
            style={{
              color: "#5C6377",
              fontSize: 10,
              marginTop: 11,
              fontFamily: "MontserratBold",
            }}
          >
            {data && data.bimestre}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: 15,
            }}
          >
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              ESCOLA:{" "}
              <Text style={{ fontFamily: "MontserratBold" }}>
                {institutions[parseInt(institution, 10)]}
              </Text>
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              DISCIPLINA:{" "}
              <Text style={{ fontFamily: "MontserratBold" }}>
                {subjectOptions.find((item) => item.value === selectedSubject) &&
                  subjectOptions.find((item) => item.value === selectedSubject)
                    .label}
              </Text>
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "50%",
              }}
            >
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                TURMA:{" "}
                <Text style={{ fontFamily: "MontserratBold" }}>
                  {classesOptions.find((item) => item.value === selectedClass) &&
                    classesOptions.find((item) => item.value === selectedClass)
                      .label}
                </Text>
              </Text>
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                AULAS DADAS NO ANO:{" "}
                <Text style={{ fontFamily: "MontserratBold" }}>
                  {data && data.qtdAulaAno}
                </Text>
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "50%",
              }}
            >
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                DATA DA AULA:{" "}
                <Text style={{ fontFamily: "MontserratBold" }}>
                  {filterDate && format(filterDate, "dd/MM/yyyy")}
                </Text>
              </Text>
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                AULAS DADAS NO BIMESTRE:{" "}
                <Text style={{ fontFamily: "MontserratBold" }}>
                  {data && data.qtdAulaBimestre}
                </Text>
              </Text>
            </View>
          </View>
          {annotation?
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 5,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Text style={{ fontSize: 10, color: "#5C6377" }}>
                  OBJETO DE CONHECIMENTO: {" "}
                  <Text style={{ fontFamily: "MontserratBold" }}>
                    {annotation && annotation.atvDescricaoAtividade }
                  </Text>
                </Text>
              </View>
            </View>
          : (<></>)}
          <View wrap style={{ marginTop: 15 }}>
            {data.listaAlunos.map((item, index) => (
              <View
                key={index.toString()}
                wrap={false}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: index % 2 === 0 ? "#F2F4F7" : "",
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: index === 0 ? 20 : 0,
                  borderTopColor: index === 0 ? "#CACDDF" : "",
                  borderTopWidth: index === 0 ? 1 : 0,
                  borderBottomColor: "#CACDDF",
                  borderBottomWidth: 1,
                }}
              >
                <Text
                  style={{
                    color: "#5C6377",
                    fontSize: 12,
                    fontFamily: "MontserratBold",
                  }}
                >
                  {item.possuiNee ? (
                    <Image
                      src={Icon}
                      style={{ height: 11, width: 11, marginRight: 2 }}
                    />
                  ) : (
                    <></>
                  )}
                  {item.nomeAluno}
                </Text>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  <Text
                    style={{ color: "#5C6377", fontSize: 8, fontWeight: "bold" }}
                  >
                    FREQUÊNCIA:{" "}
                    <Text style={{ fontFamily: "MontserratBold" }}>
                      {" "}
                      {(item.frequencia < 0 || item.frequencia > 100) ? "-": `${item.frequencia}%`}
                    </Text>
                  </Text>
                  <Text style={{ color: "#5C6377", fontSize: 8 }}>
                    TOTAL DE PRESENÇAS:{" "}
                    <Text style={{ fontFamily: "MontserratBold" }}>
                      {item.totalDePresencas}{" "}
                    </Text>
                  </Text>
                  <Text style={{ color: "#5C6377", fontSize: 8 }}>
                    FALTAS:{" "}
                    <Text style={{ fontFamily: "MontserratBold" }}>
                      {" "}
                      {item.totalDeFaltas}
                    </Text>
                  </Text>
                </View>
                { item.observacao ? (
                  <>
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  <Text style={{ color: "#5C6377", fontSize: 8 }}>
                    OBSERVAÇÃO DE FALTA DO DIA:{" "}
                    <Text style={{ fontFamily: "MontserratBold" }}>
                      {" "}
                      {item.observacao}
                    </Text>
                  </Text>
                </View>
                </>) : (<></>)}
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 5,
                  }}
                >
                  <Text style={{ color: "#5C6377", fontSize: 8 }}>
                    {item.tipoDiagnostico ? (
                      <>
                      PESSOA COM DEFICIÊNCIA:{" "}
                        {" "}
                        {item.tipoDiagnostico.map((nee, i) => (
                          <>
                            {i + 1 !== item.tipoDiagnostico.length ? (
                              <Text style={{ fontFamily: "MontserratBold" }}>
                                {nee.toUpperCase()},{" "}
                              </Text>
                            ) : (
                              <Text style={{ fontFamily: "MontserratBold" }}>
                                {nee.toUpperCase()}
                              </Text>
                            )}
                          </>
                        ))}
                        {" "}
                      </>
                    ) : (<>
                      {" "}
                    </>)}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  }

  function generatePDFOcorrencia(ocorrenciaSelect){
    setPDFOcorrenciaUnica(
      <Document>
        <Page
          style={{
            padding: 35,
            display: "flex",
            flexDirection: "column",
            fontFamily: "Montserrat",
          }}
          wrap
          size="A4"
        >
          <ReportHeader name={loggedUser.name} title="Ocorrências" />
          <Text
            style={{
              color: "#5C6377",
              fontSize: 10,
              marginTop: 11,
              fontFamily: "MontserratBold",
            }}
          >
            {studentToOccurence && studentToOccurence.nomeAluno}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: 15,
            }}
          >
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              ESCOLA:{" "}
              <Text style={{ fontFamily: "MontserratBold" }}>
                {institutions[parseInt(institution, 10)]}
              </Text>
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              TURMA:{" "}
              <Text style={{ fontFamily: "MontserratBold" }}>
                {classesOptions.find((item) => item.value === selectedClass) &&
                  classesOptions.find((item) => item.value === selectedClass)
                    .label}
              </Text>
            </Text>
          </View>
          <View wrap style={{ marginTop: 15 }}>
            <View
              wrap={false}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#F2F4F7",
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 20,
                borderTopColor: "#CACDDF",
                borderTopWidth: 1,
                borderBottomColor: "#CACDDF",
                borderBottomWidth: 1,
              }}
            >
              <Text
                style={{
                  color: "#5C6377",
                  fontSize: 12,
                  fontFamily: "MontserratBold",
                }}
              >
                {ocorrenciaSelect.date} - {ocorrenciaSelect.professor}
              </Text>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: 5,
                }}
              >
                <Text style={{ color: "#5C6377", fontSize: 8 }}>
                  {ocorrenciaSelect.text}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  }

  const getList = useCallback(
    
    async (idAudAux) => {
      setActiveLoad(true);
      try{
   
        setIdAud(idAudAux);
        setFoulList([]);
        let annotation = {};
        api.get(
          `/anotacoesAulas/diario/buscar/${institution}/${selectedClass}/${selectedSubject}/${idUser}/${idAudAux}?dataAtv=${selectedDate}`
        ).then((res)=>{
          annotation = res;
          setCurrentAnnotation(res.data);
        }).catch(()=>{})
        const buscaAlunos = await getAlunos(idAudAux);
        if(buscaAlunos.data.listaAlunos[0].numeroChamada > 0){
          buscaAlunos.data.listaAlunos.sort( (a, b ) => {return (a.numeroChamada > b.numeroChamada) ? 1 : -1});
        } else {
          buscaAlunos.data.listaAlunos.sort( (a, b ) => {return (a.nomeAluno > b.nomeAluno) ? 1 : -1});
        }
        
        const buscaEventos = await getEventsInDay();
        setActiveLoad(false);
        setList(buscaAlunos.data.listaAlunos);          
        setFilteredList(buscaAlunos.data.listaAlunos);
        setCanSave(buscaAlunos.data.podeFazerChamada);
        setListEvents(buscaEventos.data);
        if (buscaAlunos.data.motivoNaoPodeFazerChamada) {
          setCannotSaveMessage(buscaAlunos.data.motivoNaoPodeFazerChamada);
        }
        if (!showAtt) {
          toast.info("Troca de aula: Agora você está em outra aula.");
        }
        setShowAtt(true);
        setReady(true);
        generatePDFPresenca(buscaAlunos.data, annotation.data);
      } catch(error){
          setActiveLoad(false);
          if (error.response) {
            toast.error(error.response.data);
          }
      }
      
    },
    [institution, selectedClass, selectedSubject, selectedDate]
  );

  useEffect(()=>{
    sendLog("/listaPresenca");
  },[]);

  useEffect(async () => {
    
    setSubjectOptions([]);
    setClassesOptions([]);
    setSelectedSchedule([]);
    setListEvents([]);
    setProfOptions([]);
    setSelectedClass("");
    setSelectedProf("");
    setSelectedSubject("");
    setIdAud("");
    setList([]);
    setFilteredList([]);
    setActiveLoad(true);

    if(profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR"){
      try{
        const resProf = await getProfessores();
        resProf.data.map((item)=> {
          const json = {
            value: String(item.usrId),
            label: item.nome,
          };
          return setProfOptions((oldListProf) => [
            ...oldListProf, 
            json])
        })
        setActiveLoad(false);
      } catch(err){
        setActiveLoad(false);
        if (err.response) {
          toast.error(err.response.data);
        }
      }
    } else {
      try{
        const resTurmas = await getTurmas();
        resTurmas.data.map((item) => {
          const OBJ = {
            value: String(item.turmaId),
            label: item.descricao,
          };
          return setClassesOptions((oldClassesOptions) => [
            ...oldClassesOptions,
            OBJ,
          ]);
        });
        setActiveLoad(false);
      } catch (err) {
        setActiveLoad(false);
        if (err.response) {
          toast.error(err.response.data);
        }
      }
    }


  }, [id, institution]);

   useEffect(async () => {
    setSubjectOptions([]);
    setClassesOptions([]);
    setSelectedSchedule([]);
    setListEvents([]);
    setSelectedClass("");
    setSelectedSubject("");
    setIdAud("");
    setList([]);
    setFilteredList([]);
    setActiveLoad(true);

    if(selectedProf){
  
      try{
        const resTurmas = await getTurmas();
        resTurmas.data.map((item) => {
          const OBJ = {
            value: String(item.turmaId),
            label: item.descricao,
          };
          return setClassesOptions((oldClassesOptions) => [
            ...oldClassesOptions,
            OBJ,
          ]);
        });
        setActiveLoad(false);
      } catch (err) {
        setActiveLoad(false);
        if (err.response) {
          toast.error(err.response.data);
        }
      }
    }
  }, [selectedProf]);

  useEffect(() => {
    if(selectedClass && id && institution){
      setSubjectOptions([]);
      setSelectedSchedule([]);
      setHoraAulaOptions([]);
      setListEvents([]);
      setSelectedSubject("");
      setIdAud("");
      setList([]);
      setFilteredList([]);
      setActiveLoad(true);
      
      api
        .get(
          `/lista-presenca/diario/filtro-disciplina/${idUser}/${institution}/${selectedClass}`
        )
        .then((res) => {
          res.data.map((item) => {
            const OBJ = {
              value: String(item.disId),
              label: item.descricao,
            };
            return setSubjectOptions((oldSubjectOptions) => [
              ...oldSubjectOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    }
  }, [id, institution, selectedClass]);

  useEffect(async () => {
    setScheduleOptions([]);
    setDateOptions([]);
    setSelectedSchedule([]);
    setListEvents([]);
    setFilterDate(null);
    setHoraAulaOptions([]);
    setIdAud("");
    setActiveLoad(true);
    if (selectedSubject !== "") {
      api
      .get(
      `/lista-presenca/diario/professor-especialista/${selectedClass}/${selectedSubject}`
      )
      .then((res) => {
        setIsProfessorEspecialista(res.data)
        setActiveLoad(false);
      })
      .catch((err) => {
        setActiveLoad(false);
        if (err.response) {
          toast.error(err.response.data);
        }
      });

      api
        .get(
          `/lista-presenca/diario/filtro-datas/${selectedClass}/${selectedSubject}`
        )
        .then(async (res) => {
          res.data.sort((a, b) => {
            if(a.audInicioAula < b.audInicioAula){
              return -1
            }
            if(a.audInicioAula > b.audInicioAula){
              return 1
            }
            return 0
          });
          res.data.map((item) => {
            const OBJ = {
              value: String(item.idAud),
              label: `${item.audInicioAula} - ${item.audFimAula}`,
              day: item.posicaoDia,
            };
            setScheduleOptions((oldScheduleOptions) => [
              ...oldScheduleOptions,
              OBJ,
            ]);

            return setDateOptions((oldDateOptions) => [
              ...oldDateOptions,
              item.posicaoDia,
            ]);
          });
          setActiveLoad(false);
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });

        const searchDatesWithReleases = await getListDatesWithReleases();
        if(searchDatesWithReleases.data){
          setListDatesWithReleases(searchDatesWithReleases.data);
        }
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedSchedule.length === 1) {
      setHoraAulaOptions([]);
      setReady(false);
      setPDFPresenca();
      setSelectedHoraAula("");
      getList(selectedSchedule[0].value);
    } else if (selectedSchedule.length > 1 && isProfessorEspecialista === true){
      selectedSchedule.sort()
      selectedSchedule.map((item) => {
        return setHoraAulaOptions((horaAulaOptionsList) => [
          ...horaAulaOptionsList,
          item,
        ]);
      });    
    }
  }, [selectedSchedule]);

  useEffect(() => {
    if(selectedHoraAula !== ""){
      setReady(false);
      setPDFPresenca();
      getList(selectedHoraAula);
    }
  },[selectedHoraAula])

  useEffect(() => {
    if (studentToOccurence.pssId) {
      api
        .get(
          `ocorrencia-diario/diario/lista/${institution}/${selectedClass}/${studentToOccurence.pssId}`
        )
        .then((res) => {
          setOcurrences(res.data);
          setReadyOccurences(true);
          generatePDFOcorrenciasGerais(res.data);
        })
        .catch(() => {
        });
    }
  }, [studentToOccurence]);

  useEffect(() => {
    if (showBncc) {
      api
        .get(`/lista-presenca/diario/bncc`)
        .then((res) => {
          setBncc(res.data);
        })
        .catch((err) => {
          toast.error(err.response.data);
        });
    }
  }, [showBncc]);
  
  async function gerarListaDiasNaoLetivos(listaDatas){
    const listToAdd = []
    listaDatas.map((itens)=>{
      if(!itens.diaLetivo){
        listToAdd.push(parseISO(itens.dataInicio));
      }
    });
    setListDiasNaoLetivos(listToAdd);
  }
  async function gerarListaDiasLetivos(listaDatas){
    const listToAdd = []
    listaDatas.map((itens)=>{
      if(itens.diaLetivo){
        listToAdd.push(itens.dataInicio);
      }
    });
    setListDiasLetivos(listToAdd);
  }
  async function atualizarListaDiasLetivos(yearToSearch){
    const respListDataLetivo = await getListDates(yearToSearch);
    const dataAtual = new Date();
    dataAtual.setHours(0,0,0,0)
    const fimAnoLetivo = parseISO(respListDataLetivo.data.dataFimPel);
    setDataInicioAno(parseISO(respListDataLetivo.data.dataInicioPel));
    setDataFimAno(dataAtual >= fimAnoLetivo ? fimAnoLetivo : dataAtual);
    gerarListaDiasNaoLetivos(respListDataLetivo.data.diasEspeciais);
    gerarListaDiasLetivos(respListDataLetivo.data.diasEspeciais);
  }

  useEffect(async ()=> {
    await atualizarListaDiasLetivos(currentYear);
  },[institution])


  const isWeekday = (date) => {
    const day = date.getDay();
    const dayFormated = format(date,"yyyy-MM-dd");
    const weekDays = [0, 1, 2, 3, 4, 5, 6];
    const excludedDays = [];
    let valueToReturn = null;
    weekDays.map((item) => {
      if (!listDiasNaoLetivos.includes(dayFormated)) {
        if (!dateOptions.includes(item)) {
            if (!excludedDays.includes(item)) {
              excludedDays.push(item);
            }
        }
      }
      return true;
    });
     excludedDays.map((i, index) => {
        if (index === 0) {
          valueToReturn = valueToReturn + day !== i;
        } else {
          valueToReturn = valueToReturn && day !== i;
        }
      return true;
    }); 

    if (listDiasLetivos.includes(dayFormated)) {
      if(dateOptions.includes(day))
        valueToReturn = true;
    }

    return valueToReturn;
  };

  function returnSelectedSchedule(date) {
    const selectedDay = date.getDay();
    setPureDate(date);
    setFilterDate(date);
    setSelectedDate(format(date, "dd-MM-yyyy"));
    setSelectedSchedule(
      scheduleOptions.filter((el) => {
        return el.day === selectedDay;
      })
    );
  }

  function setFoul(item) {
    const newData = list.map((obj) => {
      if (obj.matriculaAlunoId === item)
        return {
          ...obj,
          faltaId: 1,
          novaFalta: true,
          alunoRemoto: false
        };
      return obj;
    });

    list.map((obj) => {
      if (obj.matriculaAlunoId === item) {
        if (!obj.faltaId || obj.editarObservacao) {
          const addFoul = {
            idMal: obj.matriculaAlunoId,
            faltou: true,
            editarObservacao: obj.editarObservacao,
            observacao: obj.observacao
          };
          if (
            foulList.filter((e) => e.idMal === obj.matriculaAlunoId).length < 1
          ) {
            setFoulList((oldFoulList) => [...oldFoulList, addFoul]);
          }else {
              foulList.filter((e) => {
                if(e.idMal === obj.matriculaAlunoId){
                  e.editarObservacao = obj.editarObservacao;
                  e.observacao = obj.observacao;
                }
            })
           
          }
        }

        const editRemote = [...remoteList];
        editRemote.map((itens) => {
          if(itens.idMatricula === obj.matriculaAlunoId){
            itens.presente = false;
          }
        })
        setRemoteList(editRemote);
      }
      return true;
    });

    setFilteredList(newData);
    setList(newData);
  }

  function setPresence(item) {
    const newData = list.map((obj) => {
      if (obj.matriculaAlunoId === item)
        return {
          ...obj,
          faltaId: null,
        };
      return obj;
    });

    list.map((obj) => {
      if (obj.matriculaAlunoId === item) {
        if (obj.novaFalta) {
          setFoulList(foulList.filter((e) => e.idMal !== obj.matriculaAlunoId));
        } else {
          const removeFoul = {
            idFalta: obj.faltaId,
            idMal: obj.matriculaAlunoId,
            faltou: false,
          };
          setFoulList((oldFoulList) => [...oldFoulList, removeFoul]);
        }
      }
      return true;
    });
setObjObsAluno({});
    setFilteredList(newData);
    setList(newData);
  }

  function setRemotePresence(item) {
    const newData = list.map((obj) => {
      if (obj.matriculaAlunoId === item)
        return {
          ...obj,
          aulaRemota: true
        };
      return obj;
    });

    newData.map((obj) => {
      if (obj.matriculaAlunoId === item) {
        obj.alunoRemoto = !obj.alunoRemoto;
        if (obj.aulaRemota) {
          if (remoteList.filter((e) => e.idMatricula === obj.matriculaAlunoId).length < 1) {
            const addRemoteList = [...remoteList];
            const addRemote = {
              idMatricula: obj.matriculaAlunoId,
              presente: obj.alunoRemoto,
            };
            addRemoteList.push(addRemote);
            setRemoteList(addRemoteList);
          } else {
            const editRemote = [...remoteList];
            editRemote.map((itens) => {
              if(itens.idMatricula === obj.matriculaAlunoId){
                itens.presente = !itens.presente;
              }
            })
            setRemoteList(editRemote);
          }
        }
      }
      return true;
    });

    setFilteredList(newData);
    setList(newData);
  }

  function showModalAnnotation() {
    if (selectedClass.length > 1 && pureDate > 0 && idAud.length > 1) {
      setCurrentAnnotation({});
      setEditAnnotation(true);
      const titleClass = classesOptions.filter((e) => e.value === selectedClass);
      const titleDate = format(pureDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });
      
      const finalTitle = `${titleClass[0].label} - ${titleDate}`;

      setModalAnnotationTitle(finalTitle.toUpperCase());

      api.get(
        `/anotacoesAulas/diario/buscar/${institution}/${selectedClass}/${selectedSubject}/${idUser}/${idAud}?dataAtv=${selectedDate}`
      )
      .then((res) => {
        setCurrentAnnotation(res.data);
      })
      .catch(() => {
      });

    } else {
      toast.error("Selecione uma aula corretamente.");
    }
  }

  function handleSubmitOcurrence() {
    if (newOcurrence.length > 1) {
      const json = {
        descricao: newOcurrence,
        tipoOcorrencia: 1,
        pss: studentToOccurence.pssId,
        user: id,
        tui: selectedClass,
        ins: institution,
        data_ocorrencia: format(filterDate, "dd/MM/yyyy '00:00:00'")
      };
      api
        .post(`/ocorrencia-diario/diario/inserir`, json)
        .then((res) => {
          setShowAtt(false);
          getList(idAud);
          setPDFPresenca();
          setReady(false);
          toast.success(res.data);
          setNewOcurrence("");
          setEditOccurence(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setEditOccurence(false);
        });
    } else if(!editOccurenceHistory) {
        toast.error("Digite uma ocorrência");
    }
    setCounterAnnotation(0);
    setReadySingle(false);
    setReadyOccurences(false);
  }

  function handleSubmitAnnotation() {
    if (newAnnotation.length > 1) {
      const json = {
        descricao: newAnnotation,
        usrId: id,
        tuiId: selectedClass,
        disId: selectedSubject,
        insId: institution,
        dataAnotacao: format(filterDate, "dd/MM/yyyy '00:00:00'"),
        audId: idAud
      };
      if (currentAnnotation.atvDescricaoAtividade) {
        api
          .put(
            `/anotacoesAulas/diario/alterar/${currentAnnotation.atvId}`,
            json
          )
          .then((res) => {
            toast.success(res.data);
            setNewAnnotation("");
            getList(idAud);
            setEditAnnotation(false);
          })
          .catch((err) => {
            toast.error(err.response.data);
            setEditAnnotation(false);
          });
      } else {
        api
          .post(`/anotacoesAulas/diario/inserir`, json)
          .then((res) => {
            toast.success(res.data);
            setNewAnnotation("");
            setEditAnnotation(false);
            getList(idAud);
          })
          .catch((err) => {
            toast.success(err.response.data);
            setEditAnnotation(false);
          });
      }
    } else {
      toast.error("Digite uma anotação");
    }
  }

  function handleSubmit() {
    const json = {
      idTurma: selectedClass,
      idDis: selectedSubject,
      idAud: idAud,
      idIns: institution,
      idProfessor: id,
      dataFalta: format(filterDate, "dd/MM/yyyy '00:00:00'"),
      faltas: foulList,
      alunosRemotos: {
        matriculas: remoteList
      },
    };
    setLoadingBtn(true);

    if (!canSave) {
      toast.warn(cannotSaveMessage);
      setLoadingBtn(false);
      return;
    }

    if(!currentAnnotation.atvDescricaoAtividade){
      toast.warn("Objeto de conhecimento não preenchido");
      setLoadingBtn(false);
      return;
    }

    api
      .post(`lista-presenca/diario/faltas`, json)
      .then((res) => {
        toast.success(res.data);
        setFoulList([]);
        setRemoteList([]);
        setListDatesWithReleases((oldListDates)=> 
          [...oldListDates, format(filterDate, "yyyy-MM-dd")])
        setLoadingBtn(false);
        getList(selectedSchedule[0].value);

      })
      .catch((err) => {
        setLoadingBtn(false);
        toast.error(err.response.data);
      });
  }

  function handleSubmitObs() {
    setShowModalObs(false);
    if(objObsAluno.editarObservacao){
      if(foulList.length > 0){
        foulList.filter(aluno => {
          if(aluno.matriculaAlunoId === objObsAluno.matriculaAlunoId){
            aluno.observacao = objObsAluno.obs;
            aluno.editarObservacao = objObsAluno.editarObservacao;
          }
        });
      }

      list.filter(aluno => {
        if(aluno.matriculaAlunoId === objObsAluno.matriculaAlunoId){
          aluno.observacao = objObsAluno.obs;
          aluno.editarObservacao = objObsAluno.editarObservacao;
          setFoul(aluno.matriculaAlunoId);
        }
      });
      
      setObjObsAluno({});
    }
  }

  const setDaysWithReleases = (date) => {
    let className = ''
    if(listDatesWithReleases.length > 0 ){

      listDatesWithReleases.map((itens)=>{
        if(itens === format(date,"yyyy-MM-dd")){
          className = 'text-success';
        }
      })
    }
    return className
  };

  const openPDF = (url) => {
    window.open(url, '_blank');
  };

  return (
    <LoadingScreen activeLoad={activeLoad} >
      <Container>
        <Content>
          <TitleContainer>
            <Title>Lista de presença</Title>
          </TitleContainer>
          <FilterContainer>
            {profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? 
              (<>
                <FilterDiv>
                  <SelectInput
                    name="prof"
                    label="Professor"
                    type="list"
                    placeholder="SELECIONAR PROFESSOR"
                    options={profOptions}
                    func={(e) => setSelectedProf(e)}
                    />
                </FilterDiv>
              </>) : 
              (<></>)}
            <FilterDiv>            
              <SelectInput
                name="class"
                label="Turma"
                type="list"
                placeholder="SELECIONAR TURMA"
                options={classesOptions}
                func={(e) => setSelectedClass(e)}
              />
            </FilterDiv>
            <FilterDiv>
              <SelectInput
                name="class"
                label="Disciplina"
                type="list"
                placeholder="SELECIONAR DISCIPLINA"
                options={subjectOptions}
                func={(e) => setSelectedSubject(e)}
              />
            </FilterDiv>
            <FilterDiv>
              <TextInput
                name="nameFilter"
                label="Nome do aluno"
                placeholder="DIGITE O NOME"
                filter
                onChange={(e) => {
                  setFilteredList(
                    list.filter((name) =>
                      name.nomeAluno.includes(e.target.value.toUpperCase())
                    )
                  );
                }}
              />
            </FilterDiv>
            </FilterContainer>
          <DateTimeDiv>
            <DateDiv>
              <DateFilterPicker>
                <Picker
                  id="dateSelect"
                  selected={filterDate}
                  locale="pt-BR"
                  onChange={(date) => setFilterDate(date)}
                  shouldCloseOnSelect
                  filterDate={isWeekday}
                  maxDate={dataFimAno}
                  minDate={dataInicioAno}
                  excludeDates={listDiasNaoLetivos}
                  dayClassName={setDaysWithReleases}
                  placeholderText="Selecione o dia"
                  dateFormat="dd MMMM yyyy"
                  onSelect={(value) => {
                    returnSelectedSchedule(value);
                  }}
                  onFocus={() => {
                    setSelectedSchedule([]);
                    setReady(false);
                    setPDFPresenca();
                    setCurrentAnnotation({});
                    setHoraAulaOptions([]);
                  }}
                />
                <label style={{ cursor: "pointer", marginTop: 5 }}>
                  <IconCalendarFilter
                    size={24}
                    color="#CACDDF"
                    style={{ marginRight: 5 }}
                  />
                </label>
                <> {horaAulaOptions.length > 1 &&
                <SelectInput
                  name="hourFilter"
                  label=""
                  type="list"
                  placeholder="SELECIONAR HORARIO"
                  options={horaAulaOptions}
                  func={(e) => setSelectedHoraAula(e)}
                />
              }
            </>
              </DateFilterPicker>
            </DateDiv>
            
          </DateTimeDiv>
          
          <TopTableDiv>
            <CurriculumLink onClick={() => setShowBncc(true)}>
              Currículo Municipal
            </CurriculumLink>
            {listEvent.length > 0 ? (<>
              <ContentListEvent>
                Eventos do dia: 
                  {listEvent?.map((events, index)=> (
                    <ListEvents key={`${index}`}>
                      {events}
                    </ListEvents>
                  ))}
              </ContentListEvent>
            </>) : (<></>)}
            <TopTableButtonsDiv>
              <PrimaryButton
                style={{ height: 30, marginRight: 5 }}
                onClick={() => showModalAnnotation()}
              >
                Objeto de Conhecimento
              </PrimaryButton>
              {ready ? (
              <>
                <BlobProvider
                  document={PDFPresenca}
                >
                  {({ url,loading }) => (
                    loading ? (
                      "Carregando..."
                    ) : (
                      <PrimaryButton style={{ height: 30 }} onClick={()=>openPDF(url)}>
                        Relatório
                      </PrimaryButton>
                    )
                  )}
                </BlobProvider>
              </>
              ) : (
                <>
                  <PrimaryButton style={{ height: 30, opacity: 0.6 }} disabled>
                    Relatório
                  </PrimaryButton>
                </>
              )}
            </TopTableButtonsDiv>
          </TopTableDiv>
          <hr style={{ color: "#CACDDF" }} />
          <TableLegend style={{marginBottom: 8}}>
            <span>Legendas: <b><MdCheck size={20} /></b>: Presença / <b>F</b>: Falta / <b>R</b>: Presença Remota</span>
          </TableLegend>
          <TableLegend style={{marginBottom: 8}}>
            {filteredList.length > 0 ? (<>
              <MdWarning color="#f85241" size={16} style={{ marginRight: 5 }} />
              <b>Atenção, é obrigatório o preenchimento do objeto de conhecimento</b>
            </>) : (<></>)}
          </TableLegend>
          <DataTable striped hover responsive>
            <thead>
              <tr>
                <th>Nº</th>
                <th>ALUNO(A)</th>
                <th> </th>
                <th> </th>
                <th>STATUS</th>
                <th>TOTAL DE FALTAS</th>
                <th>FREQUÊNCIA</th>
                <th>PRESENÇA</th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((item,index) => (
                <tr
                  key={index.toString()}
                  style={{ backgroundColor: item.possuiNee ? "#CEEDFE" : "none" }}
                >
                  <td>{item.numeroChamada}</td>
                  <td>
                    {item.fotoAluno ? (
                      <StudentImage
                        src={`data:image/png;base64,${item.fotoAluno}`}
                      />
                    ) : (
                      <StudentImage src={DefaultImage} />
                    )}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {item.possuiNee ? (<>
                      {item.nomeAluno}{" "}
                      <OverlayTrigger
                        key={item.nomeAluno}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${item.nomeAluno}`}>
                            Aluno com NEE
                          </Tooltip>
                        }
                      >
                        <MdAccessible color="#5c6377" size={22} />
                      </OverlayTrigger>
                    </>) : (<>
                      {item.nomeAluno}
                    </>)}
                  </td>
                  <td>
                    {item.possuiOcorrencia ? (
                      <>
                        <NotificationIndicator />
                        <OverlayTrigger
                          key={item.nomeAluno}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${item.nomeAluno}`}>
                              Ocorrências
                            </Tooltip>
                          }
                        >
                          <MdComment
                            onClick={() => {
                              setEditOccurence(true);
                              setStudentToOccurence(item);
                            }}
                            style={{ cursor: "pointer" }}
                            color="##CACDDF"
                            size={22}
                          />
                        </OverlayTrigger>
                      </>
                    ) : (
                      <MdComment
                        onClick={() => {
                          setEditOccurence(true);
                          setStudentToOccurence(item);
                        }}
                        style={{ cursor: "pointer" }}
                        color="#CACDDF"
                        size={22}
                      />
                    )}
                  </td>
                  <td>
                    {item.slnDescricao}
                    <LegendasStatus sigla={item.slnDescricao} nomeAluno={item.nomeAluno} />
                  </td>
                  <td>{item.totalDeFaltas}</td>
                  <td>{(item.frequencia < 0 || item.frequencia > 100) ? "-": `${item.frequencia}%`}</td>
                  <td  style={{width: "20%"}}>
                    {item.permiteEditar ? (<>
                      {item.faltaId ? (
                      <>
                        <PresenceTrue style={!isEditable ? {cursor: 'not-allowed'}: {}} disabled={!isEditable}
                          onClick={() => setPresence(item.matriculaAlunoId)}
                        >
                          <MdCheck size={18} color="#fff" />
                        </PresenceTrue>
                        <PresenceFalse active  style={!isEditable ? {cursor: 'not-allowed'}: {}} disabled={!isEditable}>
                          <b style={{ color: "#fff" }}>F</b>
                        </PresenceFalse>
                        <OverlayTrigger
                          key={item.nomeAluno}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${item.nomeAluno}`}>
                              Observação
                            </Tooltip>
                          }
                        >
                          <PresenceTrue  style={!isEditable ? {cursor: 'not-allowed'}: {}} disabled={!isEditable}
                            onClick={() => {
                              setShowModalObs(true);
                              const objAtt = {...objObsAluno};
                              objAtt.matriculaAlunoId = item.matriculaAlunoId;
                              objAtt.obs = item.observacao;
                              setObjObsAluno(objAtt);
                            }}
                          >
                            <MdComment
                              style={{ cursor: "pointer" }}
                              color="##CACDDF"
                              size={22}
                            />
                          </PresenceTrue>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <>
                        <PresenceTrue active  style={!isEditable ? {cursor: 'not-allowed'}: {}} disabled={!isEditable}>
                          <MdCheck size={18} color="#fff" />
                        </PresenceTrue>
                        <PresenceFalse  style={!isEditable ? {cursor: 'not-allowed'}: {}}  disabled={!isEditable}
                          onClick={() => setFoul(item.matriculaAlunoId)}
                        >
                          <b style={{ color: "#fff" }}>F</b>
                        </PresenceFalse>

                        <PresenceTrue active={item.alunoRemoto}  style={!isEditable ? {cursor: 'not-allowed'}: {}}  disabled={!isEditable}
                          onClick={() => setRemotePresence(item.matriculaAlunoId)}
                        >
                          <b style={{ color: "#fff" }}>R</b>
                        </PresenceTrue>
                        
                      </>
                    )}
                    </>):(<>
                      <>
                        <PresenceTrue disabled
                          style={{cursor: 'not-allowed'}}
                        >
                          <MdCheck size={18} color="#fff" />
                        </PresenceTrue>
                        <PresenceFalse disabled
                          style={{cursor: 'not-allowed'}}
                        >
                          <b style={{ color: "#fff" }}>F</b>
                        </PresenceFalse>
                        <PresenceTrue disabled
                          style={{cursor: 'not-allowed'}}
                        >
                          <b style={{ color: "#fff" }}>R</b>
                        </PresenceTrue>
                      </>
                    </>)}
                  </td>
                </tr>
              ))}
            </tbody>
          </DataTable>
        </Content>
        {filteredList.length > 0 ? (<>
          <FixedFooter>
            <ActionUPDiv
              style={{ backgroundColor: "transparent", border: "none" }}
            />
            <ActionButtonsDiv>
              {loadingBtn ? 
              (<>
                <div style={{ display: "flex", width: "100%", justifyContent: "center"}}>
                  <LoadingButtons loading={loadingBtn}/>
                </div>
              </>)
              :
              (<>
                <SecondaryButton 
                  style={{ marginRight: 4 }}
                  onClick={() => history.push("/home")} >
                  Voltar
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => handleSubmit()}
                  style={!isEditable ? {cursor: "not-allowed", marginLeft: 4 } : {}}
                  disabled={!isEditable}
                >
                  Salvar
                </PrimaryButton>
              </>) 
              }
            </ActionButtonsDiv>
            <ActionUPDiv onClick={() => window.scrollTo(0, 0)}>
              <MdArrowUpward color="#3E70E8" size={20} />
            </ActionUPDiv>
          </FixedFooter>
        </>) : (<></>) }
        <Modal
          show={editAnnotation}
          hide={() => {
            setEditAnnotation(false);
          }}
          title="Objeto de Conhecimento"
          cancelText="Cancelar"
          confirmText="Salvar"
          hideConfirmButton={!isEditable}
          hideCancelButton={!isEditable}
          onConfirm={() => handleSubmitAnnotation()}
        >
          <SubTitleAnnotationModal>
            {modalAnnotationTitle}
          </SubTitleAnnotationModal>
          <ScrollBarArea>
            <PerfectScrollbar>
              <ModalTextArea
                maxLength={4000}
                defaultValue={
                  currentAnnotation ? currentAnnotation.atvDescricaoAtividade : ""
                }
                style={{ height: textAreaHeight }}
                onChange={(e) => {
                  setTextAreaHeight(e.target.scrollHeight);
                  setCounterAnnotation(e.target.value.length);
                  setNewAnnotation(e.target.value);
                }}
                disabled={!isEditable}
              />
            </PerfectScrollbar>
          </ScrollBarArea>
          <CounterDiv>
            {counterAnnotation > 0
              ? counterAnnotation
              : currentAnnotation.atvDescricaoAtividade
              ? currentAnnotation.atvDescricaoAtividade.length
              : 0}
            /4000
          </CounterDiv>
        </Modal>
        <Modal
          show={editOccurence}
          hide={() => {
            setEditOccurence(false);
            setStudentToOccurence({});
            setCurrentOcurrence("");
            setOcurrences([]);
            setEditOccurenceHistory(false);
            setCounterAnnotation(0);
            setReadySingle(false);
            setReadyOccurences(false);
          }}
          title="Ocorrências"
          cancelText={!editOccurenceHistory ? "Cancelar" : ""}
          confirmText={!editOccurenceHistory ? "Salvar" : ""}
          onConfirm={() => handleSubmitOcurrence()}
          hideCancelButton={editOccurenceHistory || !isEditable}
          hideConfirmButton={editOccurenceHistory || !isEditable}
        >
          <SubTitleAnnotationModal>
            {studentToOccurence.nomeAluno}
          </SubTitleAnnotationModal>
          {isEditable ? (<Buttons>
            {!editOccurenceHistory ? (
              <>
                <NewButton onClick={() => setEditOccurenceHistory(false)}>
                  Novo
                </NewButton>
                <HistoryButton onClick={() => setEditOccurenceHistory(true)}>
                  Histórico
                </HistoryButton>
              </>
            ) : (
              <>
                <HistoryButton onClick={() => setEditOccurenceHistory(false)}>
                  Novo
                </HistoryButton>
                <NewButton onClick={() => setEditOccurenceHistory(true)}>
                  Histórico
                </NewButton>
              </>
            )}
          </Buttons>):('')}
          {!editOccurenceHistory ? (
            <>
              <ScrollBarArea>
                <PerfectScrollbar>
                  <ModalTextArea
                    maxLength={4000}
                    style={{ height: textAreaHeight }}
                    onChange={(e) => {
                      setTextAreaHeight(e.target.scrollHeight);
                      setCounterAnnotation(e.target.value.length);
                      setNewOcurrence(e.target.value);
                    }}
                    disabled={!isEditable}
                  />
                </PerfectScrollbar>
              </ScrollBarArea>
              <CounterDiv>{counterAnnotation}/4000</CounterDiv>
            </>
          ) : (
            <>
              <ModalListDiv>
                <PerfectScrollbar>
                  <ModalList variant="flush">
                    {ocurrences.length > 0 &&
                      ocurrences.map((item,index) => (
                        <ListGroup.Item
                          key={index.toString()}
                          onClick={() => {
                            setCurrentOcurrence(item.descricao);
                            generatePDFOcorrencia({
                              date: item.dataOcorrencia,
                              professor: item.nomeProfessor,
                              text:item.descricao
                            })
                            setReadySingle(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item.dataOcorrencia}
                        </ListGroup.Item>
                      ))}
                  </ModalList>
                </PerfectScrollbar>
              </ModalListDiv>
              <ScrollBarArea style={{ height: 120 }}>
                <PerfectScrollbar>
                  <ModalTextArea
                    value={currentOcurrence.length > 1 ? currentOcurrence : ""}
                    disabled
                    maxLength={4000}
                    style={{ height: textAreaHeight }}
                    onChange={(e) => {
                      setTextAreaHeight(e.target.scrollHeight);
                      setCounterAnnotation(e.target.value.length);
                    }}
                  />
                </PerfectScrollbar>
              </ScrollBarArea>
              <CounterDiv>{counterAnnotation}/4000</CounterDiv>
              <ButtonsDiv>
                {readySingle ? (
                  <PDFDownloadLink
                    document={PDFOcorrenciaUnica}
                    fileName="ocorrencia.pdf"
                    style={{ width: "40%" }}
                  >
                    {({ loading }) =>
                      loading ? (
                        "Carregando..."
                      ) : (
                        <HistoryButton
                          style={{
                            height: 40,
                            width: "100%",
                            borderRadius: 25,
                          }}
                        >
                          Gerar PDF da atual
                        </HistoryButton>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <>
                    <HistoryButton
                      style={{
                        height: 40,
                        width: "40%",
                        opacity: 0.6,
                        borderRadius: 25,
                      }}
                      disabled
                    >
                      Gerar PDF da atual
                    </HistoryButton>
                  </>
                )}
                {readyOccurences ? (
                  <PDFDownloadLink
                    style={{ width: "40%" }}
                    document={PDFOcorrencias}
                    fileName="ocorrencias.pdf"
                  >
                    {({ loading }) =>
                      loading ? (
                        "Carregando..."
                      ) : (
                        <NewButton
                          style={{
                            height: 40,
                            width: "100%",
                            borderRadius: 25,
                          }}
                        >
                          Exportar todas
                        </NewButton>
                      )
                    }
                  </PDFDownloadLink>
                ) : (
                  <>
                    <NewButton
                      style={{
                        height: 40,
                        width: "40%",
                        opacity: 0.6,
                        borderRadius: 25,
                      }}
                      disabled
                    >
                      Exportar todas
                    </NewButton>
                  </>
                )}
              </ButtonsDiv>
            </>
          )}
        </Modal>
        <Modal
          show={showBncc}
          hide={() => {
            setShowBncc(false);
          }}
          size="lg"
          title="Habilidades do Currículo Municipal"
        >
          <div>
            <ScrollBarAreaBncc>
              {bncc.map((item, index) => (
                <div key={index.toString()}>
                  <BnccTitle>{item.codigo}</BnccTitle>
                  <BnccText>{item.descricao}</BnccText>
                </div>
              ))}
            </ScrollBarAreaBncc>
          </div>
        </Modal>
        <Modal
          show={showModalObs}
          hide={() => {
            setShowModalObs(false);
          }}
          title="Observações"
          cancelText="Cancelar"
          confirmText="Salvar"
          onConfirm={() => handleSubmitObs()}
        >
          <ScrollBarArea>
            <PerfectScrollbar>
              <ModalTextArea
                maxLength={255}
                defaultValue={
                  objObsAluno ? objObsAluno.obs : ""
                }
                style={{ height: '100%'}}
                onChange={(e) => {
                  const objAtt = {...objObsAluno};
                  objAtt.obs = e.target.value;
                  objAtt.editarObservacao = true;
                  setObjObsAluno(objAtt);
                }}
              />
            </PerfectScrollbar>
          </ScrollBarArea>
        </Modal>
      </Container>
    </LoadingScreen>
  );
}

export default ListaPresenca;
