import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

import {
    BlobProvider,
    Document,
    Page,
    View,
    Text,
    Font,
  } from "@react-pdf/renderer";

  import ReportHeader from "../../components/ReportHeader";

import {
    Container,
    Content,
    TitleContainer,
    FilterContainer,
    FilterDiv,
    TopTableDiv,
    TopTableButtonsDiv
  } from "./styles";

import Title from "../../components/Title";
import SelectInput from "../../components/SelectInput";
import PrimaryButton from "../../components/PrimaryButton";
import api from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { LogContext } from "../../contexts/LogContext";
import { LoadingScreen } from "../../components/Loading/LoadingScreen";


Font.register({
    family: "Montserrat",
    src:
      "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
  });
  
  Font.register({
    family: "MontserratBold",
    src:
      "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
  });

function Relatorios() {

    const { loggedUser } = useContext(AuthContext);
    const { sendLog } = useContext(LogContext);
    const { institution, id } = loggedUser;
    const institutions = JSON.parse(sessionStorage.getItem("dc:institutions"));
    const profile = JSON.parse(sessionStorage.getItem("dc:profile"));

    const [bimestreOptions, setBimestreOptions] = useState([]);
    const [classesOptions, setClassesOptions] = useState([]);
    const [selectedBimestre, setSelectedBimestre] = useState("");
    const [disciplinaOptions, setDisciplinaOptions] = useState([]);
    const [selectedDisciplina, setSelectedDisciplina] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [PDFPresenca, setPDFPresenca] = useState();
    const [profOptions, setProfOptions] = useState([]);
    const [selectedProf, setSelectedProf] = useState("");
    const [activeLoad, setActiveLoad] = useState(false);
    const [ready, setReady] = useState(false);
    const [usrToGet, setUsrToGet] = useState(id);
    const idUserProf = profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? selectedProf : id

    async function getProfessores(){
      const response = await api.get(`/lista-presenca/diario/filtro-professores/${id}/${institution}`)
      return response;
    }

    async function getAlunosRelatorio(){
      const response = await api.get(
        `/lista-presenca/diario/busca-alunos-relatorio/${institution}/${selectedClass}/${selectedDisciplina}/${selectedBimestre}/${usrToGet}`)
        return response;
    }

    function verificaPresenca(dia,listaRegistroAluno) {
      const hoje =  new Date();
      const hojeFormatado = `${hoje.getFullYear()}-${hoje.getMonth()}-${hoje.getDate()}`
      if(dia <= hojeFormatado){
        if(listaRegistroAluno.length > 0){
          const registro = listaRegistroAluno.filter(registroAluno => (registroAluno.data === dia))[0]
      
          if(registro === undefined || registro === null){
            return 'P';
          }
          if(registro.falta){
            return 'F';
          } 
          if(registro.remoto){
            return 'R'
          }
        }
        return 'P'
      }
      return '';
    }

    function generatePDFRelatorioPresenca(data){
      
      const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ];
  
      const dataInicio = new Date(data.bimestreDataInicio);
      const dataFim = new Date(data.bimestreDataFim);
      const contaMeses = dataInicio;

      const diasLetivosBimestreSeparadosPorMes = [];
      const mesesBimestre = [];
      do{
          const diasLetivosMes = [];

          data.diasLetivos.forEach(diaLetivo => {
            const mesLetivo = diaLetivo.substring(5,7);
            const mes = (contaMeses.getMonth() + 1).toString().padStart(2, "0");

            if(mes === mesLetivo){
              diasLetivosMes.push(diaLetivo);
            } 
          })
          mesesBimestre.push(contaMeses.getMonth());
          diasLetivosBimestreSeparadosPorMes.push(diasLetivosMes);
          contaMeses.setMonth(contaMeses.getMonth()+1)
      }while(contaMeses.getMonth() <= dataFim.getMonth());

      function calculaTotalFaltas(mes, diaLetivoAlunoRelatorioDtoList){
        mes = (mes).toString().padStart(2, "0");
        let faltas = 0;
        diaLetivoAlunoRelatorioDtoList.forEach(registro => {
          const mesLetivo = registro.data.substring(5,7);
           if(mes === mesLetivo){
             if(registro.falta === true){
              faltas = faltas + 1
             }
          } 
         
        })
        return faltas;
      }
      
      setPDFPresenca(
        <Document>
          {mesesBimestre.map((mes, mesIndex) => (
                
          <Page
            style={{
              padding: 35,
              display: "flex",
              flexDirection: "column",
              fontFamily: "Montserrat",
            }}
            orientation="landscape"
            wrap
            size="A4"
          >
            <ReportHeader name={loggedUser.name} title="Relatório de Lista de Presença Bimestral" />
            
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginTop: 15,
              }}
            >
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                ESCOLA:{" "}
                <Text style={{ fontFamily: "MontserratBold" }}>
                  {institutions[parseInt(institution, 10)]}
                </Text>
              </Text>
              
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                  TURMA:{" "}
                  <Text style={{ fontFamily: "MontserratBold" }}>
                      {classesOptions.find((item) => item.value === selectedClass) &&
                      classesOptions.find((item) => item.value === selectedClass)
                          .label}
                  </Text>
              </Text>
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                  DOCENTE:{" "}
                  <Text style={{ fontFamily: "MontserratBold" }}>
                      {data.nomeDocente}
                  </Text>
              </Text>
              <Text style={{ fontSize: 10, color: "#5C6377" }}>
                DISCIPLINA:{" "}
                <Text style={{ fontFamily: "MontserratBold" }}>
                  {disciplinaOptions.find((item) => item.value === selectedDisciplina) &&
                    disciplinaOptions.find((item) => item.value === selectedDisciplina)
                      .label}
                </Text>
              </Text>
            </View>
            <Text
              style={{
                color: "#5C6377",
                fontSize: 10,
                marginTop: 11,
                fontFamily: "MontserratBold",
              }}
            >
              {data && data.bimestre}
            </Text>
            <Text style={{ fontSize: 10, color: "#5C6377" }}>
              <Text style={{ fontFamily: "MontserratBold" }}>
                  {data && data.diasLetivos.length}
              </Text>
              {" "}dias letivos
            </Text>

            <View
              style={{
              height: 1,
              width: "100%",
              backgroundColor: "#CACDDF",
              marginTop: 10,
              }}
          />
          <Text
              style={{
              color: "#5C6377",
              fontSize: 20,
              marginTop: 10,
              textTransform: "uppercase",
              fontFamily: "MontserratBold"
              }}
          >
              {monthNames[mes]}
          </Text>
          <Text
                style={{
                color: "#5C6377",
                fontSize: 8,
                marginTop: 10
                }}
            >
               Legendas: P: Presença / F: Falta / R: Presença Remota
            </Text>

            <View
              style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 5,
              borderTopColor: "#CACDDF",
              borderTopWidth: 1,
              borderBottomColor: "#CACDDF",
              borderBottomWidth: 1,
              fontFamily: "MontserratBold",
              }}
              >
                  <View
                      style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-around",
                      }}
                      > 
                      <View style={{ width: "2%"}}>
                          <Text
                              style={{
                              color: "#5C6377",
                              fontSize: 8,
                              textAlign: "left",
                              fontFamily: "MontserratBold",
                              }}
                          >
                          Nº
                          </Text>
                      </View>
                      <View style={{ width: "30%"}}>
                          <Text
                              style={{
                              color: "#5C6377",
                              fontSize: 8,
                              textAlign: "left",
                              fontFamily: "MontserratBold",
                              }}
                          >
                          NOME
                          </Text>
                      </View>
                      <View style={{ width: "5%"}}>
                          <Text
                              style={{
                              color: "#5C6377",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "MontserratBold",
                              }}
                          >
                          Status
                          </Text>
                      </View>
                      {diasLetivosBimestreSeparadosPorMes[mesIndex].map((item)=> (
                        
                          <View style={{ width: (60 / (diasLetivosBimestreSeparadosPorMes[mesIndex].length + 2))}}>
                              <Text
                                  style={{
                                  color: "#5C6377",
                                  fontSize: 8,
                                  textAlign: "center",
                                  fontFamily: "MontserratBold",
                                  }}
                              >
                              {item.substring(8,10)}
                              </Text>
                          </View>
                      ))}
                      <View style={{ width: "3%"}}>
                          <Text
                              style={{
                              color: "#5C6377",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "MontserratBold",
                              }}
                          >
                          Total
                          </Text>
                      </View>
                  </View>
            </View>
            
            {data.presencaAlunoRelatorioDtoList.map((presenca)=> (
                      <View
                      style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderBottomColor: "#CACDDF",
                      borderBottomWidth: 1,
                      fontFamily: "MontserratBold",
                      }}
                      >
                          <View
                              style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  justifyContent: "space-around",
                                  
                              }}
                              > 
                              <View style={{ width: "2%"}}>
                                  <Text
                                      style={{
                                      color: "#5C6377",
                                      fontSize: 8,
                                      textAlign: "left",
                                      fontFamily: "MontserratBold",
                                      }}
                                  >
                                  {presenca.numeroChamada}
                                  </Text>
                              </View>
                              <View style={{ width: "30%"}}>
                                  <Text
                                      style={{
                                      color: "#5C6377",
                                      fontSize: 8,
                                      textAlign: "left",
                                      fontFamily: "MontserratBold",
                                      }}
                                  >
                                  {presenca.nomeAluno}
                                  </Text>
                              </View>
                              <View style={{ width: "5%"}}>
                                  <Text
                                      style={{
                                      color: "#5C6377",
                                      fontSize: 8,
                                      textAlign: "center",
                                      fontFamily: "MontserratBold",
                                      }}
                                  >
                                  {presenca.slnDescricao}
                                  </Text>
                              </View>
                              {diasLetivosBimestreSeparadosPorMes[mesIndex].map((dia)=> (
                                  <View style={{width: (60 / (diasLetivosBimestreSeparadosPorMes[mesIndex].length + 2)), textAlign: "center"}}>
                                      <Text
                                          style={{
                                          color: "#5C6377",
                                          fontSize: 8,
                                          textAlign: "center",
                                          fontFamily: "MontserratBold"
                                          }}
                                      >
                                      {verificaPresenca(dia, presenca.diaLetivoAlunoRelatorioDtoList)}
                                      </Text>
                                  </View>
                              ))}
                              <View style={{ width: "3%"}}>
                                    <Text
                                        style={{
                                        color: "#5C6377",
                                        fontSize: 8,
                                        textAlign: "center",
                                        }}
                                    >

                                      {calculaTotalFaltas(mes+1, presenca.diaLetivoAlunoRelatorioDtoList)}
                                    </Text>
                                </View>
                          </View>
                      </View>
                  ))}
              
                      <Text style={{fontSize: 7, color: "#555864", textAlign: "right", position: "absolute" , left: 0, bottom: 20, padding: 0}} render={({ pageNumber, totalPages }) => (
                          `${pageNumber} / ${totalPages}`
                      )} fixed/>
            
          </Page>
      
          ))}
        </Document>
      );
    }
    
    useEffect(()=>{
      sendLog("/relatorios");
    },[]);

    useEffect(async () => {
      if(institution && selectedClass && selectedDisciplina && selectedBimestre){
        setActiveLoad(true);
        try{
          const buscaAlunos = await getAlunosRelatorio(usrToGet);
          if(buscaAlunos.data.presencaAlunoRelatorioDtoList[0].numeroChamada > 0){
            buscaAlunos.data.presencaAlunoRelatorioDtoList.sort( 
              (a, b ) => {return (a.numeroChamada > b.numeroChamada) ? 1 : -1});
          } else {
            buscaAlunos.data.presencaAlunoRelatorioDtoList.sort( 
              (a, b ) => {return (a.nomeAluno > b.nomeAluno) ? 1 : -1});
          }     
          setActiveLoad(false);
          
          generatePDFRelatorioPresenca(buscaAlunos.data);
          
          setReady(true);
        } catch(error){
          setActiveLoad(false);
          if (error.response) {
            toast.error(error.response.data);
          }
        }
      }
    },[institution, selectedClass, selectedDisciplina, selectedBimestre] );

    useEffect(async () => {
        setBimestreOptions([]);
        setClassesOptions([]);
        setSelectedBimestre("");
        setProfOptions([]);
        setSelectedProf("");
        setActiveLoad(true);
    
        if(profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR"){
          try{
            const resProf = await getProfessores();
            resProf.data.map((item)=> {
              const json = {
                value: String(item.usrId),
                label: item.nome,
              };
              return setProfOptions((oldListProf) => [
                ...oldListProf, 
                json])
            })
            setActiveLoad(false);
          } catch(err){
            setActiveLoad(false);
            if (err.response) {
              toast.error(err.response.data);
            }
          }
        } else {
          api
            .get(`/lista-presenca/diario/filtro-turma-geral/${id}/${institution}`)
            .then((res) => {
              res.data.map((item) => {
                const OBJ = {
                  value: String(item.turmaId),
                  label: item.descricao,
                };
                return setClassesOptions((oldClassesOptions) => [
                  ...oldClassesOptions,
                  OBJ,
                ]);
              });
              setActiveLoad(false);
            })
            .catch((err) => {
              setActiveLoad(false);
              if (err.response) {
                toast.error(err.response.data);
              }
            });
        }
      }, [id, institution]);

      useEffect(async () => {
        setBimestreOptions([]);
        setClassesOptions([]);
        setSelectedBimestre("");
        setActiveLoad(true);
        
        if(id && institution && selectedProf){
            setUsrToGet(selectedProf)
          api
            .get(`/lista-presenca/diario/filtro-turma-geral/${selectedProf}/${institution}`)
            .then((res) => {
              res.data.map((item) => {
                const OBJ = {
                  value: String(item.turmaId),
                  label: item.descricao,
                };
                return setClassesOptions((oldClassesOptions) => [
                  ...oldClassesOptions,
                  OBJ,
                ]);
              });
              setActiveLoad(false);
            })
            .catch((err) => {
              setActiveLoad(false);
              if (err.response) {
                toast.error(err.response.data);
              }
            });
        }
      }, [id, institution, selectedProf]);



      useEffect(() => {
        if(selectedClass && id && institution){
            setBimestreOptions([]);
            setSelectedBimestre("");
            setReady(false);
            setPDFPresenca();
            setActiveLoad(true);
        
            api.get(
                `/lista-presenca/diario/filtro-bimestre-relatorio/${selectedClass}/${idUserProf}/${institution}`
                )
                .then((res) => {
          
                res.data.map((item) => {
                    const OBJ = {
                    value: String(item.idCao),
                    label: item.descricao,
                    };
                    return setBimestreOptions((oldBimestreOptions) => [
                    ...oldBimestreOptions,
                    OBJ,
                    ]);
                });
                setActiveLoad(false);
                })
                .catch(() => {
                setActiveLoad(false);
                });
            
                api
                .get(
                  `/lista-presenca/diario/filtro-disciplina/${idUserProf}/${institution}/${selectedClass}`
                )
                .then((res) => {
                  res.data.map((item) => {
                    const OBJ = {
                      value: String(item.disId),
                      label: item.descricao,
                    };
                    return setDisciplinaOptions((oldDisciplinaOptions) => [
                      ...oldDisciplinaOptions,
                      OBJ,
                    ]);
                  });
                  setActiveLoad(false);
                })
                .catch((err) => {
                  setActiveLoad(false);
                  if (err.response) {
                    toast.error(err.response.data);
                  }
                });
            
        }
      }, [id, institution, selectedClass]);

  const openPDF = (url) => {
    window.open(url, '_blank');
  };


    return (
        <LoadingScreen activeLoad={activeLoad} >
          <Container>
            <Content>
              <TitleContainer>
                <Title>Relatórios</Title>
              </TitleContainer>
              <FilterContainer>
                    {profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? 
                    (<>
                        <FilterDiv>
                        <SelectInput
                            name="prof"
                            label="Professor"
                            type="list"
                            placeholder="SELECIONAR PROFESSOR"
                            options={profOptions}
                            func={(e) => setSelectedProf(e)}
                            />
                        </FilterDiv>
                    </>) : 
                    (<></>)}
                    <FilterDiv>
                    <SelectInput
                        name="class"
                        label="Turma"
                        type="list"
                        placeholder="SELECIONAR TURMA"
                        options={classesOptions}
                        func={(e) => setSelectedClass(e)}
                    />
                    </FilterDiv>
                    <FilterDiv>
                        <SelectInput
                            name="class"
                            label="Disciplina"
                            type="list"
                            placeholder="SELECIONAR DISCIPLINA"
                            options={disciplinaOptions}
                            func={(e) => setSelectedDisciplina(e)}
                        />
                    </FilterDiv>
                    <FilterDiv>
                        <SelectInput
                            name="class"
                            label="Bimestre"
                            type="list"
                            placeholder="SELECIONAR BIMESTRE"
                            options={bimestreOptions}
                            func={(e) => {
                            setSelectedBimestre(e);
                            }}
                        />
                    </FilterDiv>
              </FilterContainer>
              <TopTableDiv>
                <TopTableButtonsDiv>
                    {ready ? (
                    <>
                        <BlobProvider
                        document={PDFPresenca}
                        >
                        {({ url,loading }) => (
                            loading ? (
                            "Carregando..."
                            ) : (
                            <PrimaryButton style={{ height: 30, marginRight: 5  }} onClick={()=>openPDF(url)}>
                                Gerar Relatório
                            </PrimaryButton>
                            )
                        )}
                        </BlobProvider>
                    </>
                    ) : (
                        <>
                        <PrimaryButton style={{ height: 30, opacity: 0.6, marginRight: 5   }} disabled>
                                Gerar Relatório
                            </PrimaryButton>


                        </>
                    )}
                </TopTableButtonsDiv>
              </TopTableDiv>
            </Content>
                
          </Container>
    </LoadingScreen>
    );

}
export default Relatorios;