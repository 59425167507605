import React from "react";
import { View, Text, Image, Font } from "@react-pdf/renderer";
import { format } from "date-fns";

import LogoImg from "../../assets/logo_horizontal.png";

Font.register({
  family: "Montserrat",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
});

Font.register({
  family: "MontserratBold",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
});

function ReportHeader({ name, title }) {
  return (
    <>
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Montserrat",
        }}
      >
        <Image src={LogoImg} style={{ height: 55, width: 135 }} />
        <View
          style={{
            height: "100%",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "center",
            color: "#555864",
          }}
        >
          <Text style={{ fontSize: 7 }}>Relatório gerado por:</Text>
          <Text style={{ fontSize: 9, marginTop: 6, marginBottom: 6 }}>
            {name}
          </Text>
          <Text style={{ fontSize: 9 }}>
            {format(new Date(), "dd/MM/yyyy - hh:mm")}
          </Text>
        </View>
      </View>
      <View
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#CACDDF",
          marginTop: 10,
        }}
      />
      <Text
        style={{
          color: "#5C6377",
          fontSize: 20,
          marginTop: 10,
          fontFamily: "MontserratBold",
        }}
      >
        {title}
      </Text>
    </>
  );
}

export default ReportHeader;
