import React, { useCallback, useContext, useEffect, useState } from "react";
import { MdWarning, MdArrowUpward, MdAccessible } from "react-icons/md";
import { PDFDownloadLink, Font } from "@react-pdf/renderer";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Title from "../../components/Title";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";

import DefaultImage from "../../assets/aluno_default.jpg";
import api from "../../services/api";

import {
  Container,
  Content,
  TitleContainer,
  FilterContainer,
  FilterDiv,
  Subtitle,
  FilterDataDiv,
  FilterData,
  PeriodAlert,
  TableLegend,
  DataTable,
  StudentImage,
  ActionButtonsDiv,
  ActionUPDiv,
  FixedFooter,
  CounterDiv,
  ModalTextArea,
  ScrollBarArea,
  SubTitleAnnotationModal,
  ScrollBarAreaBncc,
  LabelTable,
  TableInput
} from "./styles";

import { AuthContext } from "../../contexts/AuthContext";
import { LogContext } from "../../contexts/LogContext";
import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import LoadingButtons from "../../components/Loading/LoadingButtons";
import LegendasStatus from "../../components/LegendasStatus";
import PdfNotas from "../pdf/pdfNotas";

Font.register({
  family: "Montserrat",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
});

Font.register({
  family: "MontserratBold",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
});

function AvaliacaoInfantil() {
  const history = useHistory();
  const { loggedUser } = useContext(AuthContext);
  const { sendLog } = useContext(LogContext);
  const { institution, id } = loggedUser;
  const profile = JSON.parse(sessionStorage.getItem("dc:profile"));
  const [activeLoad, setActiveLoad] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [profOptions, setProfOptions] = useState([]);
  const [listToSend, setListToSend] = useState([]);
  const [listaLancamentoAlunos, setListaLancamentoAlunos] = useState([]);
  const [selectedProf, setSelectedProf] = useState("");
  const [showBncc, setShowBncc] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [list, setList] = useState([]);
  const [listaConceitos, setListaConceitos] = useState([])
  const [textAreaHeight, setTextAreaHeight] = useState(240);
  const [counterAnnotation, setCounterAnnotation] = useState(0);
  const [classesOptions, setClassesOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [bimestreOptions, setBimestreOptions] = useState([]);
  const [selectedBimestre, setSelectedBimestre] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [detailsOptions, setDetailsOptions] = useState([]);
  const [details, setDetails] = useState({});
  const [objAvaliacao, setObjAvaliacao] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [ready, setReady] = useState(false);
  const [PDFNotas, setPDFNotas] = useState();
  const idUserProf = profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? selectedProf : id;
  const isEditable = profile.sigla !== "DIR" && profile.sigla !== "CRD" && profile.sigla !== "ADMIN" && profile.sigla !== "SADM" && profile.sigla !== "VDIR";
  const [isForaDataLimite, setIsForaDataLimite] = useState(false);
  
  function generatePDFNotas(widthCol, listaAlunos) {
    setPDFNotas(
      <PdfNotas 
        widthCol={widthCol}
        selectedSubject = {selectedBimestre}
        subjectOptions = {bimestreOptions}
        details = {details}
        classesOptions = {classesOptions}
        selectedClass = {selectedClass}
        dataTable = {listaAlunos}
        tableHeader = {tableHeader}
        loggedUser={loggedUser}
        institution={institution}
      />
    );
  }

  async function getAvaliacao(idMatricula){
    const response = await api.get(`/programas-especiais/diario/lista-desempenho-especialista/${idMatricula}/${selectedClass}/${selectedBimestre}/${idUserProf}`)
    return response;
  }

  async function getProfessores(){
    const response = await api.get(`/lista-presenca/diario/filtro-professores/${id}/${institution}`)
    return response;
  }


  const getList = useCallback(
    (e) => {
      setActiveLoad(true);
      setListToSend([]);
      setSelectedBimestre(e);
      api
        .get(
          `/avaliacao-crianca/diario/busca-alunos-infantil/${selectedClass}/${e}/${institution}/${idUserProf}`
        )
        .then((res) => {
          if(res.data.length > 1){
            if(res.data[0].numeroChamada > 0){
              res.data.sort( (a, b ) => {return (a.numeroChamada > b.numeroChamada) ? 1 : -1});
            } else {
              res.data.sort( (a, b ) => {return (a.nomeAluno > b.nomeAluno) ? 1 : -1});
            }
            res.data.map((alunos)=>{
              setListToSend((theList) => [...theList, {
                matriculaAluno: alunos.matriculaAluno,
                idCao: e,
                ausenciasCompensadas: alunos.faltasCompensadas,
                alunoPossuiDeficiencia: alunos.possuiNee,
                permiteEditar: alunos.permiteEditar,
                possuiObs: alunos.possuiObs,
                qtdFaltas: alunos.qtdFaltas,
                notas: alunos.notas,
              }])
            })
            setList(res.data);
            setFilteredList(res.data);
            setActiveLoad(false);
          }
         
          
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    },
    [institution, selectedClass, selectedBimestre]
  );

  useEffect(()=>{
    const OBJComplete = [];
      list.map((item) => {
        OBJComplete.push({
          nome: item.nomeAluno,
          faltas: item.qtdFaltas,
          ausencias: item.faltasCompensadas,
          possuiNee: item.possuiNee,
        });
        tableHeader.map((i) => {
          const indexNome = OBJComplete.findIndex(
            (obj) => obj.nome === item.nomeAluno
          );
          if (indexNome) {
            OBJComplete[indexNome][i.abreviacao] = item.notas.find(
              (itens) => itens.disciplinaId === i.disId
            )
              ? item.notas.find((itens) => itens.disciplinaId === i.disId).nota
              : "";
          } else {
            OBJComplete[0][i.abreviacao] = item.notas.find(
              (itens) => itens.disciplinaId === i.disId
            )
              ? item.notas.find((itens) => itens.disciplinaId === i.disId).nota
              : "";
          }
        });
      });

      if(details.length > 0){
        const dataAtual = new Date();
        const dataLancamento = new Date(details[0].dataLancamento.substring(6),details[0].dataLancamento.substring(3,5)-1,details[0].dataLancamento.substring(0,2),23,59,59);

        if(dataLancamento < dataAtual){
          setIsForaDataLimite(true);
        }
        else{
          setIsForaDataLimite(false);
        }

      }

      let wc = parseInt(60 / (tableHeader.length + 2), 10);
      wc = `${wc}%`;
      generatePDFNotas(wc,OBJComplete);
      setReady(true);
  },[filteredList]);

  useEffect(() => {
    api
      .get("/notas-faltas/diario/lista-simbologias-legendas")
      .then((res) => {
        const siglas = [];
        res.data.forEach(itens => {
          if(itens.sigla !== "NS"){
            siglas.push(itens.sigla)
          }
        })
        setListaConceitos(siglas);
      })
      .catch(() => {
      });
  },[]);

  useEffect(()=>{
    sendLog("/avaliacaoInfantil");
  },[]);

  useEffect(async () => {
    setSelectedClass("");
    setClassesOptions([]);
    setBimestreOptions([]);
    setSelectedBimestre("");
    setProfOptions([]);
    setSelectedProf("");
    setListToSend([]);
    setActiveLoad(true);
    setReady(false);

    if(profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR"){
      try{
        const resProf = await getProfessores();
        resProf.data.map((item)=> {
          const json = {
            value: String(item.usrId),
            label: item.nome,
          };
          return setProfOptions((oldListProf) => [
            ...oldListProf, 
            json])
        })
        setActiveLoad(false);
      } catch(err){
        setActiveLoad(false);
        if (err.response) {
          toast.error(err.response.data);
        }
      }
    } else {
      api
        .get(`/lista-presenca/diario/filtro-turma/${id}/${institution}/1`)
        .then((res) => {
          res.data.map((item) => {
            const OBJ = {
              value: String(item.turmaId),
              label: item.descricao,
            };
            return setClassesOptions((oldClassesOptions) => [
              ...oldClassesOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    }

  }, [id, institution]);

  useEffect(() => {
    setSelectedClass("");
    setClassesOptions([]);
    setBimestreOptions([]);
    setSelectedBimestre("");
    setListToSend([]);
    setActiveLoad(true);
    setReady(false);

    if(selectedProf){
      api
        .get(`/lista-presenca/diario/filtro-turma/${idUserProf}/${institution}/1`)
        .then((res) => {
          res.data.map((item) => {
            const OBJ = {
              value: String(item.turmaId),
              label: item.descricao,
            };
            return setClassesOptions((oldClassesOptions) => [
              ...oldClassesOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch((err) => {
          setActiveLoad(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
    }
  },[selectedProf])

  useEffect(() => {
    if(selectedClass && id && institution) {
      setBimestreOptions([]);
      setList([]);
      setFilteredList([]);
      setDetails({});
      setListToSend([]);
      setActiveLoad(true);
      setReady(false);
      api.get(
          `/notas-faltas/diario/filtro-bimestre/${selectedClass}/${idUserProf}/${institution}`
        ).then((res) => {
          setDetailsOptions(res.data);
          res.data.map((item) => {
            const OBJ = {
              value: String(item.idCao),
              label: item.descricao,
            };
            return setBimestreOptions((oldBimestreOptions) => [
              ...oldBimestreOptions,
              OBJ,
            ]);
          });
          setActiveLoad(false);
        })
        .catch(() => {
          setActiveLoad(false);
        });
    }

    if(selectedClass){
      api.get(
          `/notas-faltas/diario/cabecalho-turmas/${selectedClass}/${institution}/${idUserProf}`
        )
        .then((res) => {
          setTableHeader(res.data);
        })
        .catch(() => {
        });
    }
  }, [id, selectedClass, institution]);

  async function showAssessment(idMatricula) {
    const objAval = {
      matriculaAluno: idMatricula,
      idTurma: selectedClass,
      professorId: idUserProf,
      bimestreId: selectedBimestre
    };
    setActiveLoad(true);
    try {
      const res = await getAvaliacao(idMatricula);
      if(res.status === 200){
        objAval.id = res.data.id;
        objAval.comentarios = res.data.comentarios;
      } else {
        objAval.comentarios = "";
      }
      setActiveLoad(false);
    } catch(error){
      setActiveLoad(false);
      toast.error(error.response);
    }
    setObjAvaliacao(objAval);
    setShowBncc(true);
  }

  function handleSubmit() {
    if (objAvaliacao.comentarios.length <= 0) {
      toast.warn("Favor fazer o devido preenchimento");
      return;
    }
    const exists = listToSend.find((item) => item.matriculaAluno.toString() === objAvaliacao.matriculaAluno.toString());
    const index = listToSend.indexOf(exists);
    const newListToSend = [...listToSend];
    if(exists){
      newListToSend[index].possuiObs = true;
    }

    const existsFilteredList = filteredList.find((item) => item.matriculaAluno.toString() === objAvaliacao.matriculaAluno.toString());
    const indexFilteredList = listToSend.indexOf(exists);
    const newFilteredList = [...filteredList];
    if(existsFilteredList){
      newFilteredList[indexFilteredList].possuiObs = true;
    }
 
    api.post(`/programas-especiais/diario/inserir-desempenho`, objAvaliacao)
      .then((res) => {
        toast.success(res.data);
        setCounterAnnotation(0);
        setListToSend(newListToSend);
        setFilteredList(newFilteredList);
      })
      .catch((err) => {
        if(err.response){
          toast.error(err.response.data);
        }
      });
    setShowBncc(false);
  }


  function validateJsonToSend(jsonToValidate){
    let errorConceitos = false;
    let errorSemAvaliacao = false; 
    let errorAusencias = false;

    jsonToValidate.alunosNotas.map((aluno) =>{
      aluno.notas.map((notas)=> {
        if(!listaConceitos.includes(notas.nota)){
          errorConceitos = true
        }

        if(!aluno.possuiObs){
          errorSemAvaliacao = true
        }
        if(aluno.qtdFaltas >= aluno.ausenciasCompensadas && 
          aluno.ausenciasCompensadas >= 0){
            if(aluno.notas.length > 1) {
              if(notas.professorEspecialista || notas.disciplinaId.toString() === "109"){
                notas.ausenciasCompensadas = aluno.ausenciasCompensadas;
                notas.faltas = aluno.qtdFaltas;
              }
            } else {
              notas.ausenciasCompensadas = aluno.ausenciasCompensadas;
              notas.faltas = aluno.qtdFaltas;
            }
        } else {
          errorAusencias = true;
        }
      });
    });

    if(errorConceitos){
      toast.warn("Verifique os conceitos inseridos");
      setLoadingBtn(false);
      return false;
    } 
    if(errorSemAvaliacao){
      toast.warn("Atenção, é obrigatório o preenchimento do campo Avaliação para alunos com conceitos");
      setLoadingBtn(false);
      return false;
    }
    if(errorAusencias){
      toast.warn("O número de ausências compensadas não podem ser maiores que o número de faltas");
      setLoadingBtn(false);
      return false;
    }
    return true;
  }

  function handleSubmitList(fechamento){
    setLoadingBtn(true);
    const listCleanToSend = [];
    
    listToSend.map((alunos) =>{
      const inList = listaLancamentoAlunos.find((alunosMov)=> alunosMov === alunos.matriculaAluno);
      if(inList){
        if(alunos.permiteEditar && alunos.notas.length > 0){
          listCleanToSend.push(alunos);
        }
      }
    });
    
    const jsonToSend = {
      idCao: selectedBimestre,
      alunosNotas: listCleanToSend,
      isFechamento: fechamento
    };

    const jsonValid = validateJsonToSend(jsonToSend)
    if(jsonValid) {
      if(jsonToSend.alunosNotas.length > 0 ){
        api.post(`/avaliacao-crianca/diario/inserir-notas-infantil`, jsonToSend)
        .then((res) => {
          toast.success(res.data);
          setLoadingBtn(false);
        })
        .catch((err) => {
          setLoadingBtn(false);
          if (err.response) {
            toast.error(err.response.data);
          }
        });
      }else{
        setLoadingBtn(false);
        toast.success("Notas salvas com sucesso!");
      }
    }

  }

  function handleChangeExp(event) {
    const json = {...objAvaliacao};
    json[event.target.name] = event.target.value;
    setObjAvaliacao(json);
  }

  function validaNotaNumber(nota){
    const notaParsed = parseInt(nota, 10);
    if(notaParsed >= 0){
        return parseInt(nota, 10);
    }
    return nota.toUpperCase();
  }

  const handleChangeNote = (e, sId, nee, qtdFaltas) => { 
    const listaIdMatriculaAluno = [...listaLancamentoAlunos];
    const inList = listaIdMatriculaAluno.find((matriculas)=> matriculas === sId)
    if(!inList){
      listaIdMatriculaAluno.push(sId)
    }
    setListaLancamentoAlunos(listaIdMatriculaAluno);   
    const val = e.target.value.toUpperCase();
    if(e.target.id === "faltasCompensadas"){
      if (e.target.value > 10 || e.target.value < 0 || e.target.value > qtdFaltas) {
        e.target.classList.add("input-table-error");
      } else {
        const str = e.target.className;
        if (str.includes(" input-table-error")) {
          e.target.classList.remove("input-table-error");
        }
      }
    } else if (e.target.id !== "faltasCompensadas") {
      if (!listaConceitos.includes(val)) {
        e.target.classList.add("input-table-error");
      } else {
        const str = e.target.className;
        if (str.includes(" input-table-error") || str.length === 0) {
          e.target.classList.remove("input-table-error");
        }
      }
    }

    const exists = listToSend.find((item) => item.matriculaAluno === sId);
    const index = listToSend.indexOf(exists);
    if (exists) {
      if (e.target.id === "faltasCompensadas") {
        if(qtdFaltas >= e.target.value || e.target.value >= 0){
          listToSend[index].alunoPossuiDeficiencia = nee;
          listToSend[index].ausenciasCompensadas = parseInt(e.target.value, 10);
        }
      } else {
        listToSend[index].alunoPossuiDeficiencia = nee;
        const existsDis = listToSend[index].notas.find(
          (item) => item.disciplinaId.toString() === e.target.id.toString()
        );
        const indexDis = listToSend[index].notas.indexOf(existsDis);
        
        if (existsDis) {
          listToSend[index].notas.splice(indexDis, 1);
        }
        listToSend[index].notas.push({
          disciplinaId: parseInt(e.target.id, 10),
          nota: validaNotaNumber(e.target.value),
        });
      }
    } else if (!exists && e.target.id === "faltasCompensadas"){
      if(qtdFaltas >= e.target.value || e.target.value >= 0){
        listToSend.push({
          alunoPossuiDeficiencia: nee,
          matriculaAluno: parseInt(sId, 10),
          ausenciasCompensadas: parseInt(e.target.value, 10),
        });
      }
    } else {
      listToSend.push({
        alunoPossuiDeficiencia: nee,
        matriculaAluno: parseInt(sId, 10),
        notas: [
          {
            disciplinaId: parseInt(e.target.id, 10),
            nota: e.target.value.toUpperCase(),
          },
        ],
      });
    }
  }

  return (
    <LoadingScreen activeLoad={activeLoad} >
      <Container>
        <Content>
          <TitleContainer>
            <Title>Avaliação do Desenvolvimento Infantil</Title>
          </TitleContainer>
          <FilterContainer>
            {profile.sigla === "DIR" || profile.sigla === "CRD" || profile.sigla === "ADMIN" || profile.sigla === "SADM" || profile.sigla === "VDIR" ? 
              (<>
                <FilterDiv>
                  <SelectInput
                    name="prof"
                    label="Professor"
                    type="list"
                    placeholder="SELECIONAR PROFESSOR"
                    options={profOptions}
                    func={(e) => setSelectedProf(e)}
                    />
                </FilterDiv>
              </>) : 
              (<></>)}
            <FilterDiv>
              <SelectInput
                name="class"
                label="Turma"
                type="list"
                placeholder="SELECIONAR TURMA"
                options={classesOptions}
                func={(e) => setSelectedClass(e)}
              />
            </FilterDiv>
            <FilterDiv>
              <SelectInput
                name="bimestre"
                label="Bimestre"
                type="list"
                placeholder="SELECIONAR BIMESTRE"
                options={bimestreOptions}
                func={(e) => {
                  setFilteredList([]);
                  setListToSend([]);
                  getList(e);
                  setDetails(detailsOptions.filter((item) => item.idCao.toString() === e));
                }}
              />
            </FilterDiv>
            <FilterDiv>
              <TextInput
                name="nameFilter"
                label="Buscar um aluno"
                placeholder="DIGITE O NOME"
                filter
                onChange={(e) => {
                  setFilteredList(
                    list.filter((name) =>
                      name.nomeAluno.includes(e.target.value.toUpperCase())
                    )
                  );
                }}
              />
            </FilterDiv>
          </FilterContainer>
          <Subtitle>{details.length > 0 ? details[0].descricao : ""}</Subtitle>
          <FilterDataDiv>
            <FilterData>
              {details.length > 0
                ? `${details[0].dataInicio} - ${details[0].dataFim}`
                : ""}
            </FilterData>
            <FilterData>
              {details.length > 0 ? (
                <>
                  Aulas dadas no ano: <b> {details[0].qtdAulasDadasNoAno} </b>
                </>
              ) : (
                ""
              )}
            </FilterData>
            <FilterData>
              {details.length > 0 ? (
                <>
                  Aulas dadas no bimestre:{" "}
                  <b> {details[0].qtdAulasDadasNoBimestre} </b>
                </>
              ) : (
                ""
              )}
            </FilterData>
            {ready ? (<>
              <PDFDownloadLink document={PDFNotas} fileName="NotasFaltas.pdf">
                {({ loading }) =>
                  loading ? (
                    "Carregando..."
                  ) : (
                    <PrimaryButton style={{ height: 30, width: 85 }}>
                      Exportar
                    </PrimaryButton>
                  )
                }
              </PDFDownloadLink> 
            </>) : (
              <>
                <PrimaryButton
                  style={{ height: 30, width: 85, opacity: 0.6 }}
                  disabled
                >
                  Exportar
                </PrimaryButton>
              </>
            )}
          </FilterDataDiv>
          <hr style={{ color: "#CACDDF" }} />
          <PeriodAlert>
            {details.length > 0 ? (
              <>
                <MdWarning color="#f85241" size={16} style={{ marginRight: 5 }} />
                Período de lançamento de notas:{"  "}
                <b style={{ marginLeft: 3 }}>até: {details[0].dataLancamento} </b>
              </>
            ) : (
              ""
            )}
          </PeriodAlert>
          <PeriodAlert>
            {details.length > 0 ? (
                <b>Atenção, é obrigatório o preenchimento do campo Avaliação</b>
            ) : (
              ""
            )}
          </PeriodAlert>
          <TableLegend>
            Legendas: <b>NEE </b> (Necessidades Educativas Especiais) / <b>AC</b> (Ausências compensadas)
          </TableLegend>
          <TableLegend>
            <div style={{ display: 'flex' }}>
              {listaConceitos.length > 0 ? 
                (<>
                  <div style={{marginRight: '5px'}}>Modo de avaliação conceitual: notas válidas</div>
                  {listaConceitos.map((item, index) => (
                    <div key={index.toString()}>
                      {index + 1 !== listaConceitos.length ? (
                        <b>{item}, </b>
                      ) : (
                        <b>{item}.</b>
                      )}
                    </div>
                  ))}
                </>) 
              : (<></>)}
            </div>
          </TableLegend>
          {tableHeader.length > 0 ? (
            <DataTable striped hover responsive>
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>ALUNO(A)</th>
                  <th> </th>
                  <th>STATUS</th>
                  {tableHeader.map((item,index) => (
                    <th key={index.toString()}>{item.abreviacao}</th>
                  ))}
                  <th>FALTAS</th>
                  <th>AC</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((item, index) => (
                  <tr 
                    key={index.toString()}
                    style={{
                      backgroundColor: item.possuiNee ? "#CEEDFE" : "none",
                    }}
                  >
                    <td>{item.numeroChamada}</td>
                    <td>
                      {item.fotoAluno ? (
                        <StudentImage
                          src={`data:image/png;base64,${item.fotoAluno}`}
                        />
                      ) : (
                        <StudentImage src={DefaultImage} />
                      )}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {item.possuiNee ? (<>
                        {item.nomeAluno}{" "}
                        <OverlayTrigger
                          key={item.nomeAluno}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${item.nomeAluno}`}>
                              Aluno com NEE
                            </Tooltip>
                          }
                        >
                          <MdAccessible color="#5c6377" size={22} />
                        </OverlayTrigger>
                      </>) : (<>
                        {item.nomeAluno}
                      </>)}
                    </td>
                    <td>
                      {item.statusAluno}
                      <LegendasStatus nomeAluno={item.nomeAluno} sigla={item.statusAluno}/>
                    </td>
                    {tableHeader.map((sub, idx) => (
                      <td key={idx.toString()}>
                        <TableInput
                          id={sub.disId}
                          onChange={(e) =>
                            handleChangeNote(
                              e,
                              item.matriculaAluno,
                              item.possuiNee
                            )
                          }
                          type="text"
                          defaultValue={
                            item.notas.find(
                              (itens) => itens.disciplinaId === sub.disId
                            )
                              ? item.notas.find(
                                  (itens) => itens.disciplinaId === sub.disId
                                ).nota
                              : ""
                          }
                          disabled={!item.permiteEditar || !isEditable || isForaDataLimite}
                          style={!isEditable || isForaDataLimite ? {cursor: "not-allowed"} : {}}
                          className="input-table"
                          permiteEditar={!item.permiteEditar}
                        />
                      </td>
                    ))}
                    <td> 
                      <TableInput
                        style={{ cursor: "not-allowed", color: "#979BB2" }}
                        disabled
                        defaultValue={item.qtdFaltas}
                      />
                    </td>
                    <td>
                      <TableInput
                        id="faltasCompensadas"
                        onChange={(e) =>
                          handleChangeNote(
                            e, 
                            item.matriculaAluno,
                            item.possuiNee,
                            item.qtdFaltas
                          )
                        }
                        disabled={!item.permiteEditar || !isEditable || isForaDataLimite}
                        style={!isEditable || isForaDataLimite ? {cursor: "not-allowed"} : {}}
                        permiteEditar={!item.permiteEditar}
                        defaultValue={item.faltasCompensadas}
                        type="number"
                        min={0}
                        max={item.qtdFaltas}
                        className="input-table"
                      />
                    </td>
                    <td>
                      {(!item.permiteEditar && (!isEditable || isForaDataLimite)) ? (<>
                        <SecondaryButton
                          disabled
                          style={{ height: 30, width: 85, cursor: 'not-allowed' }}
                        >
                          Avaliação
                        </SecondaryButton>
                      </>):(<>
                        <div style={{ display: 'inline-grid', justifyItems: 'center'}}>
                          <SecondaryButton
                            onClick={() => {
                              showAssessment(item.matriculaAluno);
                              setSubtitle(item.nomeAluno);
                            }}
                            disabled={!item.permiteEditar || !isEditable || isForaDataLimite}
                            style={!isEditable || isForaDataLimite ? {cursor: "not-allowed", height: 30, width: 85 } : { height: 30, width: 85 }}
                          >
                            Avaliação
                          </SecondaryButton>
                          {!item.possuiObs ? (<>
                            <span style={{ color: '#f85241', fontSize: '10px' }}>
                              Avaliação não preenchida
                            </span>
                          </>) : (<></>)}
                        </div>
                      </>)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </DataTable>
          ) : (<></>)}
        </Content>
        {filteredList.length > 0 ? (<>
          <FixedFooter>
            <ActionUPDiv
              style={{ backgroundColor: "transparent", border: "none" }}
            />
              <ActionButtonsDiv>
                {loadingBtn ? 
                  (<>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center"}}>
                      <LoadingButtons loading={loadingBtn}/>
                    </div>
                  </>)
                  :
                  (<>
                    <SecondaryButton 
                      style={{ marginRight: 10 }}
                      onClick={() => history.push("/home")} >
                      Voltar
                    </SecondaryButton>
                    <PrimaryButton
                      style={!isEditable || isForaDataLimite ? {cursor: "not-allowed", marginLeft: 10, marginRight: 10 } : {}}
                      onClick={() => handleSubmitList(false)}
                      disabled={!isEditable || isForaDataLimite}
                    >
                      Salvar notas
                    </PrimaryButton>
                    <PrimaryButton
                      style={!isEditable || isForaDataLimite ? {cursor: "not-allowed", marginLeft: 10 } : {marginLeft: 10 }}
                      onClick={() => handleSubmitList(true)}
                      disabled={!isEditable || isForaDataLimite}
                    >
                      Encerrar Faltas
                    </PrimaryButton>
                  </>) 
                }
              </ActionButtonsDiv>
            <ActionUPDiv onClick={() => window.scrollTo(0, 0)}>
              <MdArrowUpward color="#3E70E8" size={20} />
            </ActionUPDiv>
          </FixedFooter>
        </>) : (<></>)}
        <Modal
          show={showBncc}
          hide={() => {
            setShowBncc(false);
            setCounterAnnotation(0);
          }}
          size="lg"
          title="Avaliação do Desenvolvimento Infantil"
        >
          <SubTitleAnnotationModal>{subtitle}</SubTitleAnnotationModal>
          <ScrollBarAreaBncc>
              <PerfectScrollbar>
                <LabelTable>
                  Anotação
                </LabelTable>
                <ScrollBarArea>
                  <PerfectScrollbar>
                    <ModalTextArea
                      maxLength={4000}
                      style={{ height: textAreaHeight }}
                      value={objAvaliacao.comentarios}
                      name="comentarios"
                      onChange={(e) => {
                        setTextAreaHeight(e.target.scrollHeight);
                        setCounterAnnotation(e.target.value.length);
                        handleChangeExp(e);
                      }}
                    />
                  </PerfectScrollbar>
                </ScrollBarArea>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <CounterDiv>
                    {counterAnnotation}
                    /4000
                  </CounterDiv>
                </div>
              </PerfectScrollbar>
            </ScrollBarAreaBncc>
          <ActionButtonsDiv style={{ margin: "auto" }}>
            <SecondaryButton
              style={{ marginRight: 4 }}
              onClick={() => setShowBncc(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              style={{ marginLeft: 4 }}
              onClick={() => handleSubmit()}
            >
              Salvar
            </PrimaryButton>
          </ActionButtonsDiv>
        </Modal>
      </Container>
    </LoadingScreen>
  );
}

export default AvaliacaoInfantil;
