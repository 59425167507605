import styled from "styled-components";
import DatePicker from "react-datepicker";
import { MdEvent, MdComment, MdModeEdit, MdDelete } from "react-icons/md";
import SecondaryButton from "../../components/SecondaryButton";

export const Container = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  background-color: #f2f4f7;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  height: auto;

  @media (max-width: 800px) {
    width: 95%;
    align-items: center;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  padding: 25px;
  border-radius: 25px;
  background-color: #fff;

  @media (max-width: 800px) {
    background-color: transparent;
  }
`;

export const Name = styled.div`
  color: #3e70e8;
  font-size: 30px;
  font-weight: bold;
`;

export const DateString = styled.div`
  color: #5c6377;
  font-size: 14px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const MainCards = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    margin-top: 0px;
    flex-direction: column;
    width: 90%;
  }
`;

export const BiAndNotifications = styled.div`
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 64%;

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
  }
`;

export const BiFrame = styled.div`
  height: 365px;
  width: 100%;
  border-radius: 25px;
  background-color: #fff;
  padding: 10px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const NotificationsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  border-radius: 25px;
  height: 155px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0px;
    height: auto;
  }
`;

export const Notification = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 48%;
  border-radius: 25px;
  background-color: #fff;
  height: 100%;
  padding: 20px;

  @media (max-width: 800px) {
    width: 100%;

    &:nth-child(2) {
      margin-top: 15px;
    }
  }
`;

export const Annotations = styled.div`
  display: flex;
  flex-direction: column;
  height: 550px;
  width: 34%;
  border-radius: 25px;
  background-color: #fff;
  padding: 20px;
  padding-left: 25px;

  @media (max-width: 800px) {
    margin-top: 15px;
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const AnnotationsTitle = styled.div`
  color: #3e70e8;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
`;

export const AnnotationsDate = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #555864;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;

  input {
    cursor: pointer;
  }
`;

export const AnnotationsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  margin-top: 10px;
  width: 100%;
  padding-right: 5px;
`;

export const AnnotationsItem = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: 100%;
  padding: 5px;
  padding-right: 10px;
  align-items: center;

  &:nth-child(n + 2) {
    margin-top: 20px;
  }
`;

export const AnnotationsItemDate = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  min-width: 55px;
  border-radius: 50px;
  margin-right: 10px;
  background-color: #f2f4f7;
  color: #3e70e8;
  font-size: 24px;
  font-weight: bold;
`;

export const AnnotationsItemText = styled.div`
  display: flex;
  flex-direction: column;
  color: #555864;
  margin-top: 10px;
  padding-right: 10px;
  margin-right: 10px;
`;

export const AnnotationsIcons = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  height: 25px;
`;

export const AnnotationsButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const AnnotationsButton = styled(SecondaryButton)`
  height: 100%;
  width: 150px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  padding: 25px;
  border-radius: 25px;
  background-color: #fff;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const FooterTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #555864;
`;

export const FooterLink = styled.div`
  font-size: 18px;
  color: #3e70e8;
`;

export const CalendarIcon = styled(MdEvent)`
  height: auto;
  width: 30%;
`;

export const CommentIcon = styled(MdComment)`
  height: auto;
  width: 30%;
`;

export const NotificationDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 68%;
`;

export const NotificationInformationPeriod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

export const PeriodArrows = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 9%;
  cursor: pointer;
`;

export const NotificationInformationOccurrence = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 90%;
`;

export const OccurrenceArrow = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 9%;
  cursor: pointer;
`;

export const NotificationTitle = styled.div`
  font-size: 20px;
  color: #3e70e8;
  font-weight: bold;
`;

export const NotificationText = styled.div`
  font-size: 15px;
  color: #555864;
`;

export const NotificationTextDetail = styled.b`
  font-size: 17px;
  color: #555864;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTextArea = styled.textarea`
  border: 1px solid #cacddf;
  margin-top: 10px;
  width: 100%;
  resize: none;
  border-radius: 10px;
  padding: 10px;
`;

export const CounterDiv = styled.div`
  align-self: flex-end;
  margin-top: 5px;
  margin-right: 5px;
  color: #5c6377;
`;

export const DateInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const DateInputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateLabel = styled.div`
  color: #5c6377;
  font-size: 10px;
`;

export const DateInput = styled(DatePicker)`
  background-color: transparent;
  border: 1px solid #cacddf;
  border-radius: 5px;
  padding: 10px;
  width: 110px;
  font-size: 16px;
`;

export const MonthPicker = styled(DatePicker)`
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  color: #555864;
  font-size: 18px;
  font-weight: bold;
  width: 150px;
`;

export const IconCalendarModal = styled(MdEvent)`
  font-size: 24px;
  color: #cacddf;
  margin-bottom: -15px;
  margin-left: 5px;
`;

export const IconCalendarFilter = styled(MdEvent)`
  font-size: 24px;
  color: #cacddf;

  @media (max-width: 800px) {
    font-size: 26px;
  }
`;

export const IconEdit = styled(MdModeEdit)`
  font-size: 20px;
  color: #3e70e8;
  margin-right: 5px;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

export const IconDelete = styled(MdDelete)`
  font-size: 20px;
  color: #3e70e8;
  margin-right: 5px;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 24px;
  }
`;

export const Message = styled.div`
  font-size: 18px;
  color: #5c6377;
  margin-bottom: 20px;
  margin-top: -10px;
`;

export const BI = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
