import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { TextInput, Label, LabelList } from "./styles";

import Error from "../ErrorInput";

function InputTexto({
  required,
  name,
  label,
  readonly,
  placeholder,
  filter,
  hasValue,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue: (ref) => {
        return ref.value.toUpperCase();
      },
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {filter ? (
        <LabelList htmlfor={`input-${name}`}>
          {label} {required && `*`}
        </LabelList>
      ) : (
        <Label htmlfor={`input-${name}`}>
          {label} {required && `*`}
        </Label>
      )}

      <TextInput
        disabled={readonly}
        id={`input-${name}`}
        ref={inputRef}
        defaultValue={!hasValue ? defaultValue : hasValue}
        placeholder={placeholder}
        {...rest}
      />
      <Error> {error} </Error>
    </div>
  );
}

export default InputTexto;
