import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Router from "./Router";

import { AuthProvider } from "../contexts/AuthContext";

import Login from "../pages/Login";
import Home from "../pages/Home";
import ListaPresenca from "../pages/ListaPresenca";
import Ocorrencias from "../pages/Ocorrencias";
import AvaliacaoFundamental from "../pages/AvaliacaoFundamental";
import AvaliacaoInfantil from "../pages/AvaliacaoInfantil";
import Relatorios from "../pages/Relatorios";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthProvider>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>

          <Router path="/login" component={Login} />
          <Router path="/recuperacao/:usr?/:token?" component={Login} />
          <Router path="/home" component={Home} isPrivate />
          <Router path="/listaPresenca" component={ListaPresenca} isPrivate />
          <Router path="/ocorrencias" component={Ocorrencias} isPrivate />
          <Router path="/avaliacaoFundamental" component={AvaliacaoFundamental} isPrivate />
          <Router path="/avaliacaoInfantil" component={AvaliacaoInfantil} isPrivate />
          <Router path="/relatorios" component={Relatorios} isPrivate />
        </AuthProvider>
      </Switch>
    </BrowserRouter>
  );
}
