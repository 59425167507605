import React, { useState, useEffect, useContext } from "react";
import { MdComment, MdAccessible } from "react-icons/md";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  Font
} from "@react-pdf/renderer";

import PerfectScrollbar from "react-perfect-scrollbar";
import Title from "../../components/Title";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import DefaultImage from "../../assets/aluno_default.jpg";

import api from "../../services/api";
import Modal from "../../components/Modal";
import { AuthContext } from "../../contexts/AuthContext";
import { LogContext } from "../../contexts/LogContext";
import ReportHeader from "../../components/ReportHeader";

import {
  ScrollBarArea,
  Container,
  Content,
  TitleContainer,
  FilterContainer,
  FilterDiv,
  DataTable,
  StudentImage,
  DataTableHeader,
  DataTableBody,
  CounterDiv,
  ModalTextArea,
  NewButton,
  HistoryButton,
  Buttons,
  ModalList,
  ModalListDiv,
  SubTitleAnnotationModal,
  ButtonsDiv,
} from "./styles";

Font.register({
  family: "Montserrat",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
});

Font.register({
  family: "MontserratBold",
  src:
    "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
});

function Ocorrencias() {
  const { loggedUser } = useContext(AuthContext);
  const { sendLog } = useContext(LogContext);
  const { institution, id } = loggedUser;
  const institutions = JSON.parse(sessionStorage.getItem("dc:institutions"));
  const [studentsList, setStudentsList] = useState([]);
  const [editOccurenceHistory, setEditOccurenceHistory] = useState(false);
  const [editOccurence, setEditOccurence] = useState(false);
  const [counterAnnotation, setCounterAnnotation] = useState(0);
  const [textAreaHeight, setTextAreaHeight] = useState(100);
  const [classesOptions, setClassesOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [currentOcurrence, setCurrentOcurrence] = useState(false);
  const [ocurrences, setOcurrences] = useState(false);
  const [newOcurrence, setNewOcurrence] = useState("");
  const [pssId, setPssId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [readySingle, setReadySingle] = useState(false);
  const [readyOccurences, setReadyOccurences] = useState(false);
  const [currentOcurrenceDate, setCurrentOcurrenceDate] = useState("");
  const [currentOcurrenceProfessor, setCurrentOcurrenceProfessor] = useState(
    ""
  );

  useEffect(()=>{
    sendLog("/ocorrencias");
  },[]);
  
  useEffect(() => {
    setStudentsList([]);
    setFilteredList([]);
    if(id && selectedClass > 0 && institution){
      api.get(
        `/ocorrencia-diario/diario/lista-alunos-professor/${institution}/${selectedClass}/${id}`)
      .then((res) => {
        setStudentsList(res.data);
        setFilteredList(res.data);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data);
        }
      });
    }
  }, [id, institution, selectedClass]);

  useEffect(() => {
    setClassesOptions([]);
    api
      .get(`/lista-presenca/diario/filtro-turma-geral/${id}/${institution}`)
      .then((res) => {
        res.data.map((item) => {
          const OBJ = {
            value: String(item.turmaId),
            label: item.descricao,
          };
          return setClassesOptions((oldClassesOptions) => [
            ...oldClassesOptions,
            OBJ,
          ]);
        });
      })
      .catch(() => {
      });
  }, [id, institution]);

  function getOcurrences(psId){
    if (psId) {
      api
        .get(
          `ocorrencia-diario/diario/lista/${institution}/${selectedClass}/${psId}`
        )
        .then((res) => {
          setOcurrences(res.data);
          setReadyOccurences(true);
        })
        .catch(() => {
        });
    }
  }

  useEffect(() => {
    getOcurrences(pssId);
  }, [pssId]);

  function handleSubmitOcurrence() {
    if (newOcurrence.length > 1) {
      const json = {
        descricao: newOcurrence,
        tipoOcorrencia: 1,
        pss: pssId,
        user: id,
        tui: selectedClass,
        ins: institution,
      };
      api
        .post(`/ocorrencia-diario/diario/inserir`, json)
        .then((res) => {
          toast.success(res.data);
          setNewOcurrence("");
          setEditOccurence(false);
        })
        .catch((err) => {
          toast.error(err.response.data);
          setEditOccurence(false);
        });
    } else if(!editOccurenceHistory){
      toast.error("Digite uma ocorrência");
    }
    setReadySingle(false);
    setReadyOccurences(false);
  }

  const PDFSingleOccurence = (
    <Document>
      <Page
        style={{
          padding: 35,
          display: "flex",
          flexDirection: "column",
          fontFamily: "Montserrat",
        }}
        wrap
        size="A4"
      >
        <ReportHeader name={loggedUser.name} title="Ocorrências" />
        <Text
          style={{
            color: "#5C6377",
            fontSize: 10,
            marginTop: 11,
            fontFamily: "MontserratBold",
          }}
        >
          {studentName}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 15,
          }}
        >
          <Text style={{ fontSize: 10, color: "#5C6377" }}>
            ESCOLA:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
              {institutions[parseInt(institution, 10)]}
            </Text>
          </Text>
          <Text style={{ fontSize: 10, color: "#5C6377" }}>
            TURMA:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
              {classesOptions.find((item) => item.value === selectedClass) &&
                classesOptions.find((item) => item.value === selectedClass)
                  .label}
            </Text>
          </Text>
        </View>
        <View wrap style={{ marginTop: 15 }}>
          <View
            wrap={false}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#F2F4F7",
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 20,
              borderTopColor: "#CACDDF",
              borderTopWidth: 1,
              borderBottomColor: "#CACDDF",
              borderBottomWidth: 1,
            }}
          >
            <Text
              style={{
                color: "#5C6377",
                fontSize: 12,
                fontFamily: "MontserratBold",
              }}
            >
              {currentOcurrenceDate} - {currentOcurrenceProfessor}
            </Text>
            <View
              style={{
                width: "100%",
                display: "flex",
                marginTop: 5,
              }}
            >
              <Text style={{ color: "#5C6377", fontSize: 8 }}>
                {currentOcurrence}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const PDFOcurrences = (
    <Document>
      <Page
        style={{
          padding: 35,
          display: "flex",
          flexDirection: "column",
          fontFamily: "Montserrat",
        }}
        wrap
        size="A4"
      >
        <ReportHeader name={loggedUser.name} title="Ocorrências" />
        <Text
          style={{
            color: "#5C6377",
            fontSize: 10,
            marginTop: 11,
            fontFamily: "MontserratBold",
          }}
        >
          {studentName}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: 15,
          }}
        >
          <Text style={{ fontSize: 10, color: "#5C6377" }}>
            ESCOLA:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
              {institutions[parseInt(institution, 10)]}
            </Text>
          </Text>
          <Text style={{ fontSize: 10, color: "#5C6377" }}>
            TURMA:{" "}
            <Text style={{ fontFamily: "MontserratBold" }}>
              {classesOptions.find((item) => item.value === selectedClass) &&
                classesOptions.find((item) => item.value === selectedClass)
                  .label}
            </Text>
          </Text>
        </View>
        <View wrap style={{ marginTop: 15 }}>
          {ocurrences.length > 0 &&
            ocurrences.map((item, index) => (
              <>
                <View
                  wrap={false}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: index % 2 === 0 ? "#F2F4F7" : "",
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginTop: index === 0 ? 20 : 0,
                    borderTopColor: index === 0 ? "#CACDDF" : "",
                    borderTopWidth: index === 0 ? 1 : 0,
                    borderBottomColor: "#CACDDF",
                    borderBottomWidth: 1,
                  }}
                >
                  <Text
                    style={{
                      color: "#5C6377",
                      fontSize: 12,
                      fontFamily: "MontserratBold",
                    }}
                  >
                    {item.dataOcorrencia} - {item.nomeProfessor}
                  </Text>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: 5,
                    }}
                  >
                    <Text style={{ color: "#5C6377", fontSize: 8 }}>
                      {item.descricao}
                    </Text>
                  </View>
                </View>
              </>
            ))}
        </View>
      </Page>
    </Document>
  );

  return (
    <Container>
      <Content>
        <TitleContainer>
          <Title>Ocorrências</Title>
        </TitleContainer>
        <FilterContainer>
          <FilterDiv>
            <SelectInput
              name="class"
              label="Turma"
              type="list"
              placeholder="SELECIONAR TURMA"
              options={classesOptions}
              func={(e) => setSelectedClass(e)}
            />
          </FilterDiv>
          <FilterDiv>
            <TextInput
              name="nameFilter"
              label="Nome do aluno"
              placeholder="DIGITE O NOME"
              filter
              onChange={(e) => {
                setFilteredList(
                  studentsList.filter((item) =>
                    item.nome.includes(e.target.value.toUpperCase())
                  )
                );
              }}
            />
          </FilterDiv>
        </FilterContainer>
        <DataTable striped hover responsive>
          <thead>
            <tr>
              <DataTableHeader>ALUNO(A)</DataTableHeader>
              <th> </th>
              <th> </th>
              <DataTableHeader>TURMA</DataTableHeader>
              <th> </th>
              <DataTableHeader>ATUALIZADO EM</DataTableHeader>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {filteredList.map((item) => (
              <tr
                style={{ backgroundColor: item.possuiNee ? "#CEEDFE" : "none" }}
              >
                <td>
                  {item.pssFoto ? (
                    <StudentImage
                      src={`data:image/png;base64,${item.pssFoto}`}
                    />
                  ) : (
                    <StudentImage src={DefaultImage} />
                  )}
                </td>
                <DataTableBody>
                  {item.possuiNee ? (
                    <>
                      {item.nome}{" "}
                      <OverlayTrigger
                        key={item.nome}
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${item.nomeAluno}`}>
                            Aluno com NEE
                          </Tooltip>
                        }
                      >
                        <MdAccessible color="#5c6377" size={22} />
                      </OverlayTrigger>
                    </>
                  ) : (
                    <>{item.nome}</>
                  )}
                </DataTableBody>
                <td> </td>
                <DataTableBody>
                  {
                    classesOptions.find((itens) => itens.value === selectedClass)
                      .label
                  }
                </DataTableBody>
                <td> </td>
                <DataTableBody>{item.ultimaOcorrencia}</DataTableBody>
                <td>
                  <MdComment
                    style={{ cursor: "pointer" }}
                    color="#CACDDF"
                    size={22}
                    onClick={() => {
                      setPssId(item.id);
                      setStudentName(item.nome);
                      setEditOccurence(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </DataTable>
      </Content>
      <Modal
        show={editOccurence}
        hide={() => {
          setEditOccurence(false);
          setCurrentOcurrence("");
          setOcurrences([]);
          setPssId(0);
          setCounterAnnotation(0);
          setEditOccurenceHistory(false);
          setReadySingle(false);
          setReadyOccurences(false);
        }}
        title="Ocorrências"
        cancelText={!editOccurenceHistory ? "Cancelar" : ""}
        confirmText={!editOccurenceHistory ? "Salvar" : ""}
        onConfirm={() => handleSubmitOcurrence()}
        hideCancelButton={editOccurenceHistory}
        hideConfirmButton={editOccurenceHistory}
      >
        <SubTitleAnnotationModal>{studentName}</SubTitleAnnotationModal>
        <Buttons>
          {!editOccurenceHistory ? (
            <>
              <NewButton onClick={() => setEditOccurenceHistory(false)}>
                Novo
              </NewButton>
              <HistoryButton onClick={() => setEditOccurenceHistory(true)}>
                Histórico
              </HistoryButton>
            </>
          ) : (
            <>
              <HistoryButton onClick={() => setEditOccurenceHistory(false)}>
                Novo
              </HistoryButton>
              <NewButton onClick={() => setEditOccurenceHistory(true)}>
                Histórico
              </NewButton>
            </>
          )}
        </Buttons>
        {!editOccurenceHistory ? (
          <>
            <ScrollBarArea>
              <PerfectScrollbar>
                <ModalTextArea
                  maxLength={4000}
                  style={{ height: textAreaHeight }}
                  onChange={(e) => {
                    setTextAreaHeight(e.target.scrollHeight);
                    setCounterAnnotation(e.target.value.length);
                    setNewOcurrence(e.target.value);
                  }}
                />
              </PerfectScrollbar>
            </ScrollBarArea>
            <CounterDiv>{counterAnnotation}/4000</CounterDiv>
          </>
        ) : (
          <>
            <ModalListDiv>
              <PerfectScrollbar>
                <ModalList variant="flush">
                  {ocurrences.length > 0 &&
                    ocurrences.map((item) => (
                      <>
                        <ListGroup.Item
                          onClick={() => {
                            setCurrentOcurrence(item.descricao);
                            setCurrentOcurrenceDate(item.dataOcorrencia);
                            setCurrentOcurrenceProfessor(item.nomeProfessor);
                            setReadySingle(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item.dataOcorrencia}
                        </ListGroup.Item>
                      </>
                    ))}
                </ModalList>
              </PerfectScrollbar>
            </ModalListDiv>
            <ScrollBarArea style={{ height: 120 }}>
              <PerfectScrollbar>
                <ModalTextArea
                  value={currentOcurrence.length > 1 ? currentOcurrence : ""}
                  disabled
                  maxLength={4000}
                  style={{ height: textAreaHeight }}
                  onChange={(e) => {
                    setTextAreaHeight(e.target.scrollHeight);
                  }}
                />
              </PerfectScrollbar>
            </ScrollBarArea>
            <CounterDiv>
              {currentOcurrence.length > 1 ? currentOcurrence.length : ""}/4000
            </CounterDiv>
            <ButtonsDiv>
              {readySingle ? (
                <PDFDownloadLink
                  document={PDFSingleOccurence}
                  fileName="ocorrencia.pdf"
                  style={{ width: "40%" }}
                >
                  {({ loading }) =>
                    loading ? (
                      "Carregando..."
                    ) : (
                      <HistoryButton
                        style={{
                          height: 40,
                          width: "100%",
                          borderRadius: 25,
                        }}
                      >
                        Gerar PDF da atual
                      </HistoryButton>
                    )
                  }
                </PDFDownloadLink>
              ) : (
                <>
                  <HistoryButton
                    style={{
                      height: 40,
                      width: "40%",
                      opacity: 0.6,
                      borderRadius: 25,
                    }}
                    disabled
                  >
                    Gerar PDF da atual
                  </HistoryButton>
                </>
              )}
              {readyOccurences ? (
                <PDFDownloadLink
                  style={{ width: "40%" }}
                  document={PDFOcurrences}
                  fileName="ocorrencias.pdf"
                >
                  {({ loading }) =>
                    loading ? (
                      "Carregando..."
                    ) : (
                      <NewButton
                        style={{
                          height: 40,
                          width: "100%",
                          borderRadius: 25,
                        }}
                      >
                        Exportar todas
                      </NewButton>
                    )
                  }
                </PDFDownloadLink>
              ) : (
                <>
                  <NewButton
                    style={{
                      height: 40,
                      width: "40%",
                      opacity: 0.6,
                      borderRadius: 25,
                    }}
                    disabled
                  >
                    Exportar todas
                  </NewButton>
                </>
              )}
            </ButtonsDiv>
          </>
        )}
      </Modal>
    </Container>
  );
}

export default Ocorrencias;
