import React, { useEffect, useState, useContext, useRef } from "react";
import {
  MdArrowForward,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { toast } from "react-toastify";
import PerfectScrollbar from "react-perfect-scrollbar";
import { registerLocale } from "react-datepicker";
import Swal from "sweetalert2";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useHistory } from "react-router-dom";

import api from "../../services/api";
import Modal from "../../components/Modal";
import { AuthContext } from "../../contexts/AuthContext";

import {
  Container,
  Content,
  Header,
  Name,
  DateString,
  MainCards,
  BI,
  BiAndNotifications,
  BiFrame,
  NotificationsDiv,
  Notification,
  CalendarIcon,
  CommentIcon,
  NotificationDetail,
  NotificationInformationPeriod,
  PeriodArrows,
  NotificationInformationOccurrence,
  OccurrenceArrow,
  NotificationTitle,
  NotificationText,
  NotificationTextDetail,
  Annotations,
  AnnotationsTitle,
  AnnotationsDate,
  MonthPicker,
  AnnotationsList,
  AnnotationsItem,
  AnnotationsItemDate,
  AnnotationsItemText,
  AnnotationsIcons,
  AnnotationsButtonDiv,
  AnnotationsButton,
  Footer,
  FooterTitle,
  FooterLink,
  DateInputArea,
  DateInputWithLabel,
  Message,
  DateLabel,
  DateInput,
  ModalTextArea,
  CounterDiv,
  IconCalendarModal,
  IconCalendarFilter,
  IconEdit,
  IconDelete
} from "./styles";

registerLocale("pt-BR", ptBR);

function Home() {
  const AnnotationRef = useRef();
  const history = useHistory();
  const { loggedUser } = useContext(AuthContext);
  const { institution, id, name, institutionName, username } = loggedUser;
  const [deadLine, setDeadLine] = useState([]);
  const [currentDeadLine, setCurrentDeadLine] = useState(0);
  const [occurrence, setOccurrence] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState(new Date());
  const [annotationList, setAnnotationList] = useState([]);
  const [newAnnotation, setNewAnnotation] = useState("");
  const [currentAnnotation, setCurrentAnnotation] = useState("");
  const [currentAnnotationId, setCurrentAnnotationId] = useState(0);
  const [edit, setEdit] = useState(false);
  const [dateMessage, setDateMessage] = useState("");
  const biURL = `https://biexport.vitaebrasil.com.br/tbs/nivel_proficiencia.php?usr=${username}&esc=${institutionName}`;
  useEffect(() => {
    api
      .get(`/home/diario/data-lacamento-nota`)
      .then((res) => {
        setDeadLine(res.data);
      })
      .catch(() => {
      });
  }, []);

  useEffect(() => {
    api
      .get(`/home/diario/quantidade-ocorrencias/${id}/${institution}`)
      .then((res) => {
        setOccurrence(res.data.qtdOcorrencias);
      })
      .catch(() => {
      });
  }, [id, institution]);

  useEffect(() => {
    const formattedDate = format(filterDate, "dd-M-yyyy");
    api
      .get(`/anotacoes/diario/listar-completo/${id}?mesAno=${formattedDate}`)
      .then((res) => {
        setAnnotationList(res.data);
      })
      .catch(() => {
      });
  }, [id, filterDate]);

  useEffect(() => {
    const formattedDate = format(startDate, "dd-M-yyyy");
    setCurrentAnnotation("");
    setDateMessage("");
    setCounter(0);
    setNewAnnotation("");
    api
      .get(`/anotacoes/diario/buscar/${id}?dataAtc=${formattedDate}`)
      .then((res) => {
        setCurrentAnnotation(res.data.atcDescricao);
        setCurrentAnnotationId(res.data.atcId);
        setNewAnnotation(res.data.atcDescricao);
        setDateMessage(format(startDate, "dd/M/yyyy"));
        setCounter(res.data.atcDescricao.length);
        if (edit) {
          setShowModal(true);
        } else {
          AnnotationRef.current.value = res.data.atcDescricao;
        }
      })
      .catch(() => {
        if (!edit) {
          setCurrentAnnotationId(0);
        }
      });
  }, [id, startDate, edit]);

  useEffect(() => {
    if (showModal && !edit) {
      AnnotationRef.current.value = "";
    }
  }, [showModal, startDate, edit]);

  function changeDeadLine(action) {
    if (action === "forward") {
      if (currentDeadLine === deadLine.length - 1) {
        setCurrentDeadLine(0);
      } else {
        setCurrentDeadLine(currentDeadLine + 1);
      }
    }
    if (action === "backward") {
      if (currentDeadLine === 0) {
        setCurrentDeadLine(deadLine.length - 1);
      } else {
        setCurrentDeadLine(currentDeadLine - 1);
      }
    }
  }

  function insertAnnotation() {
    const formattedDate = format(startDate, "dd/M/yyyy 00:00:00");

    if (newAnnotation.length < 1) {
      toast.error("Digite uma anotação");
      return;
    }

    const json = {
      descricao: newAnnotation,
      dataAnotacao: formattedDate,
      usrId: id,
    };
    if (edit || (currentAnnotationId !== 0 && currentAnnotation.length > 1)) {
      api
        .put(`anotacoes/diario/alterar/${currentAnnotationId}`, json)
        .then((res) => {
          Swal.fire({
            text: res.data,
            icon: "success",
          });
          setFilterDate(new Date());
          setShowModal(false);
          setNewAnnotation("");
          setEdit(false);
        })
        .catch((err) => {
          Swal.fire({
            text: err.response.data,
            icon: "error",
          });
          setEdit(false);
        });
      setNewAnnotation("");
      return;
    }

    api
      .post(`/anotacoes/diario/inserir`, json)
      .then((res) => {
        Swal.fire({
          text: res.data,
          icon: "success",
        });
        setFilterDate(new Date());
        setShowModal(false);
        setNewAnnotation("");
        setEdit(false);
      })
      .catch((err) => {
        Swal.fire({
          text: err.response.data,
          icon: "error",
        });
        setEdit(false);
      });
    setNewAnnotation("");
  }

  return (
    <Container>
      <Content>
        <Header>
          <Name>Olá, {name}</Name>
          <DateString>
            {new Date().toLocaleDateString("pt-br", { dateStyle: "full" })}
          </DateString>
        </Header>
        <MainCards>
          <BiAndNotifications>
              <BiFrame>
                <BI src={biURL} />
              </BiFrame> 
            <NotificationsDiv>
              <Notification>
                <CalendarIcon color="#CACDDF" />
                <NotificationDetail>
                  <NotificationInformationPeriod>
                    <NotificationText>
                      Período de lançamento de notas do{" "}
                      {deadLine.length
                        ? deadLine[currentDeadLine].bimestre
                        : ""}{" "}
                    </NotificationText>
                    <NotificationTextDetail>
                      <PeriodArrows onClick={() => changeDeadLine("backward")}>
                        <MdKeyboardArrowLeft size={22} color="#CACDDF" />
                      </PeriodArrows>
                      {deadLine.length ? deadLine[currentDeadLine].tipo : ""}
                      <PeriodArrows onClick={() => changeDeadLine("forward")}>
                        <MdKeyboardArrowRight size={22} color="#CACDDF" />
                      </PeriodArrows>
                    </NotificationTextDetail>
                    <NotificationTitle
                      style={{ width: "max-content", alignSelf: "center" }}
                    >
                      {deadLine.length ? deadLine[currentDeadLine].dataFim : ""}
                    </NotificationTitle>
                  </NotificationInformationPeriod>
                </NotificationDetail>
              </Notification>
              <Notification
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/ocorrencias")}
              >
                <CommentIcon color="#CACDDF" />
                <NotificationDetail>
                  <NotificationInformationOccurrence>
                    <NotificationTitle>Ocorrências</NotificationTitle>
                    <NotificationText style={{ fontSize: 18 }}>
                      Você tem
                      <NotificationTextDetail style={{ display: "inline" }}>
                        {" "}
                        {occurrence}{" "}
                      </NotificationTextDetail>
                      ocorrências de alunos.
                    </NotificationText>
                  </NotificationInformationOccurrence>
                  <OccurrenceArrow>
                    <MdArrowForward size={22} color="#CACDDF" />
                  </OccurrenceArrow>
                </NotificationDetail>
              </Notification>
            </NotificationsDiv>
          </BiAndNotifications>
          <Annotations>
            <AnnotationsTitle>Anotações</AnnotationsTitle>
            <AnnotationsDate>
              <MonthPicker
                selected={filterDate}
                locale="pt-BR"
                onChange={(date) => setFilterDate(date)}
                dateFormat="MMMM yyyy"
                showMonthYearPicker
              />
              <IconCalendarFilter
                size={24}
                color="#CACDDF"
                style={{ marginRight: 5 }}
              />
            </AnnotationsDate>
            <AnnotationsList>
              <PerfectScrollbar>
                {annotationList.length > 0 ? (
                  <>
                    {annotationList.map((item) => {
                      return (
                        <AnnotationsItem>
                          <AnnotationsItemDate>
                            {item.atcDataAnotacao.substr(0, 2)}
                          </AnnotationsItemDate>
                          <AnnotationsItemText>
                            {item.atcDescricao}
                            <AnnotationsIcons>
                              <IconEdit
                                onClick={() => {
                                  const dd = item.atcDataAnotacao.substr(0, 2);
                                  const M = item.atcDataAnotacao.substr(3, 2);
                                  const yyyy = item.atcDataAnotacao.substr(6);
                                  setStartDate(parseISO(`${yyyy}-${M}-${dd}`));
                                  setEdit(!edit);
                                }}
                              />
                              <IconDelete
                                onClick={() => {
                                  const dd = item.atcDataAnotacao.substr(0, 2);
                                  const M = item.atcDataAnotacao.substr(3, 2);
                                  const yyyy = item.atcDataAnotacao.substr(6);
                                  Swal.fire({
                                    text: `Deseja realmente excluir a anotação do dia ${dd}/${M}/${yyyy}?`,
                                    icon: "warning",
                                    showCancelButton: true,
                                    cancelButtonText: "Cancelar",
                                    showConfirmButton: true,
                                    confirmButtonText: "Excluir",
                                    reverseButtons: true,
                                    preConfirm: () => {
                                      api
                                        .delete(
                                          `/anotacoes/diario/deletar/${item.atcId}/${id}`
                                        )
                                        .then((res) => {
                                          Swal.fire({
                                            text: res.data,
                                            icon: "success",
                                          });
                                          setFilterDate(new Date());
                                        })
                                        .catch((err) => {
                                          Swal.fire({
                                            text: err.response.data,
                                            icon: "error",
                                          });
                                        });
                                    },
                                  });
                                }}
                              />
                            </AnnotationsIcons>
                          </AnnotationsItemText>
                        </AnnotationsItem>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <NotificationText style={{ fontSize: 18 }}>
                      Nenhuma anotação encontrada.
                    </NotificationText>
                  </>
                )}
              </PerfectScrollbar>
            </AnnotationsList>
            <AnnotationsButtonDiv>
              <AnnotationsButton
                onClick={() => {
                  setShowModal(true);
                  setStartDate(new Date());
                  setNewAnnotation("");
                  setCurrentAnnotation("");
                }}
              >
                Nova anotação
              </AnnotationsButton>
            </AnnotationsButtonDiv>
          </Annotations>
        </MainCards>
        <Footer>
          <FooterTitle>Acesso rápido</FooterTitle>
          <FooterLink
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/listaPresenca")}
          >
            Lista de presença <MdArrowForward size={22} color="#CACDDF" />
          </FooterLink>
          <FooterLink
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/avaliacaoFundamental")}
          >
            Avaliação fundamental <MdArrowForward size={22} color="#CACDDF" />
          </FooterLink>
          <FooterLink
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/avaliacaoInfantil")}
          >
            Avaliação infantil <MdArrowForward size={22} color="#CACDDF" />
          </FooterLink>
          <FooterLink
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/ocorrencias")}
          >
            Ocorrências <MdArrowForward size={22} color="#CACDDF" />
          </FooterLink>
        </Footer>
      </Content>
      <Modal
        show={showModal}
        hide={() => {
          setShowModal(false);
          setNewAnnotation("");
          setCurrentAnnotation("");
          setEdit(false);
        }}
        title="Anotação"
        cancelText="Cancelar"
        confirmText="Salvar"
        onConfirm={() => insertAnnotation()}
      >
        {dateMessage.length > 2 ? (
          <Message>{`Você está editando o dia ${dateMessage}.`}</Message>
        ) : (
          <></>
        )}

        <DateInputArea>
          <DateInputWithLabel>
            <DateLabel>Data</DateLabel>
            <DateInput
              selected={startDate}
              locale="pt-BR"
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              shouldCloseOnSelect
            />
          </DateInputWithLabel>
          <IconCalendarModal />
        </DateInputArea>
        <ModalTextArea
          maxLength={200}
          placeholder="Escreva aqui..."
          rows={8}
          defaultValue={currentAnnotation}
          ref={AnnotationRef}
          onChange={(e) => {
            setCounter(e.target.value.length);
            setNewAnnotation(e.target.value);
          }}
        />
        <CounterDiv>{counter}/200</CounterDiv>
      </Modal>
    </Container>
  );
}

export default Home;
