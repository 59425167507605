import styled from "styled-components";

export const TextInput = styled.input`
  background-color: transparent !important;
  color: #555864 !important;
  font-size: 14px;
  border: 1px solid #cacddf;
  width: 100%;
  text-transform: uppercase;
  height: 50px;
  border-radius: 5px;
  padding-left: 15px;

  &:hover,
  &:active {
    border: 1px solid #444651 !important;
  }
  &::-webkit-input-placeholder {
    color: #979bb2;
    font-size: 14px;
  }
`;

export const Label = styled.label`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555864;
  font-weight: bold;
`;

export const LabelList = styled.label`
  text-align: left;
  font-size: 13px;
  letter-spacing: 0;
  color: #555864;
  margin: 2px;
  margin-top: -10px;
`;
