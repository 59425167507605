import styled from "styled-components";
import { Form } from "@unform/web";
import { Table } from "react-bootstrap";

export const Container = styled.div`
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  background-color: #fff;
`;

export const Content = styled.div`
  width: 1100px;
  margin-bottom: 110px;

  @media (max-width: 800px) {
    width: 98%;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export const FilterContainer = styled(Form)`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FilterDiv = styled.div`
  width: 350px;
`;

export const Subtitle = styled.div`
  color: #5c6377;
  font-weight: bold;
  font-size: 24px;
  width: 100%;
  margin-top: 30px;
`;

export const FilterDataDiv = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FilterData = styled.div`
  color: #5c6377;
  font-size: 16px;
`;

export const PeriodAlert = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: #5c6377;
  font-size: 14px;
`;

export const TableLegend = styled.div`
  margin-top: 8px;
  color: #5c6377;
  font-size: 12px;
`;

export const DataTable = styled(Table)`
  margin-top: 20px;
  color: #5c6377;
`;

export const StudentImage = styled.img`
  height: 75px;
  width: 65px;
  border-radius: 10px;
  object-fit: cover;
`;

export const TableInput = styled.input`
  height: 50px;
  width: 50px;
  border: 1px solid #cacddf;
  outline: none;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: ${({ permiteEditar }) => (permiteEditar ? 'not-allowed': '')};
`;

export const FixedFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  position: fixed;
  height: 80px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -5px 5px #0000000d;
`;

export const ActionButtonsDiv = styled.div`
  display: flex;
  background-color: #fff;
  width: 350px;
`;

export const ActionUPDiv = styled.div`
  height: 50px;
  width: 50px;
  justify-self: flex-end;
  background-color: #fff;
  margin-right: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #3e70e8;
  cursor: pointer;
`;

export const SubTitleAnnotationModal = styled.div`
  color: #5c6377;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
`;

export const ScrollBarArea = styled.div`
  border: 1px solid #ccc;
  height: 235px;
  overflow-x: hidden;
  border: 1px solid #cacddf;
  border-radius: 10px;
  margin-top: 10px;
  padding: 2px;
`;

export const ModalTextArea = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  padding: 8px;
`;

export const CounterDiv = styled.div`
  align-self: flex-end;
  margin-top: 5px;
  margin-right: 5px;
  color: #5c6377;
`;

export const NEETypesDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
