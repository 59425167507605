import { createGlobalStyle } from "styled-components";
import './globalFont.css'

export default createGlobalStyle`
  *{
    margin: 0;
    padding:0;
    outline:0;
    box-sizing: border-box;
  }
  *:focus{
    outline: 0;
  }
  html, body, #root{
    height: 100%;
  }
  body{
    -webkit-font-smoothing: antialiased;
  }
  body, input, button{
    font: 14px 'Montserrat', sans-serif;
    background-color: #f2f4f7;
  }
  a{
    text-decoration: none !important;
    cursor: pointer;
  }
  ul{
    list-style: none;
  }
  button{
    cursor: pointer;
  }
  button:focus{
    outline: none;
  }
`;
